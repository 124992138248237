import React from "react";
import Capture1 from "./img/capture-1.png";
import Capture2 from "./img/capture-2.png";
import Capture3 from "./img/capture-3.png";
import Chris from "./img/chris-wixon.png";
import Derek from "./img/derek-jow.jpg";
import Jake from "./img/jacob-kail.jpg";
import Ben from "./img/ben-nussbaum.png";
import Steve from "./img/steve.jpg";

export class Home extends React.Component {

    render() {
        return (
            <div>
                <header className="masthead text-white text-center">
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-9 mx-auto">
                                <h1 className="mb-5">Archimedes Medical</h1>
                                <h4 className="mb-5">Our mission: to enhance the clinician’s ability to access, consume and communicate medical information.
                                </h4>
                            </div>
                            {/*
                            <div className="col-md-10 col-lg-8 col-xl-7 mx-auto">
                                <form>
                                    <div className="form-row">
                                        <div className="col-12 col-md-9 mb-2 mb-md-0">
                                            <input type="text" className="form-control form-control-lg" placeholder="Search for your patient here...">
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <button type="submit" className="btn btn-block btn-lg btn-primary">Search</button>
                                        </div>
                                    </div>
                                </form>
                            </div>*/}
                        </div>
                    </div>
                </header>

                <section className="features-icons bg-light text-center">
                    <div className="container">
                        <h3>Goal</h3>
                        <p className="text-justify"><span className="font-weight-bold">Christopher L Wixon, MD</span> is a practicing surgeon who holds the sincere belief that improving the quality
                            and efficiency of clinical information has the potential to have a profound effect on healthcare delivery, costs and overall outcomes.
                            Archimedes Medical has a vision and commitment to find a better way to address an inconsistent, data dependent environment that is badly in need of a productivity
                            tool. <br/></p>

                        <h4><em>"I founded Archimedes Medical because I was tired of spending more time documenting patient encounters in the electronic health record than I was actually spending with
                            my patients!
                            Electronic documentation was requiring an incremental 3-5 minutes per patient and produced and additional 2 hours of work at the end of a very long day. It felt like a
                            death by a thousand cuts."</em></h4>
                        {/*<div className="row">
                            <div className="col-lg-4">
                                <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                                    <div className="features-icons-icon d-flex">
                                        <div className="m-auto text-primary">
                                            <svg width="5em" height="5em" viewBox="0 0 16 16" className="bi bi-eye-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                                <path fill-rule="evenodd" d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <h3>Visualize</h3>
                                    <p className="lead mb-0">View the entire patient's history in a concise graphical representation.</p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                                    <div className="features-icons-icon d-flex">
                                        <div className="m-auto text-primary">
                                            <svg width="5em" height="5em" viewBox="0 0 16 16" className="bi bi-bar-chart-line-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="4" height="5" x="1" y="10" rx="1"/>
                                                <rect width="4" height="9" x="6" y="6" rx="1"/>
                                                <rect width="4" height="14" x="11" y="1" rx="1"/>
                                                <path fill-rule="evenodd" d="M0 14.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5z"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <h3>Predict</h3>
                                    <p className="lead mb-0">Discuss preventative measures to protect against future risks.</p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="features-icons-item mx-auto mb-0 mb-lg-3">
                                    <div className="features-icons-icon d-flex">
                                        <div className="m-auto text-primary">
                                            <svg width="5em" height="5em" viewBox="0 0 16 16" className="bi bi-pencil-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <h3>Assign</h3>
                                    <p className="lead mb-0">Document procedures, diagnoses, and more with Code Catcher.</p>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </section>

                <section>
                    <div className="bg-secondary py-2"></div>
                    <div className="row">
                        <div className="col-6 bg-dark text-center text-white">
                            <div className="my-3 p-3">
                                <h2 className="display-5">The Problem</h2>
                                <p className="lead text-justify">Currently available systems fail to meet the performance needs of a medical domain that
                                    is extensive and complex. At the crux of the problem is the post-acquisition data processing methods
                                    inherent in most systems. The resultant non-contextual search and processing latency issues produce
                                    inefficiency, inaccuracy, and low provider satisfaction. The failure of current systems to efficiently
                                    transform complex medical thoughts into an electronic format creates a real barrier to modernization of
                                    our health system.</p>
                            </div>
                        </div>
                        <div className="col-6 bg-light text-center">
                            <div className="my-3 p-3">
                                <h2 className="display-5">The Technology</h2>
                                <p className="lead text-justify">We live in a connected world. In the classNameic description of the “Web of documents,”
                                    Archimedes Medical is building a technology stack to support a “Web of medical data.”
                                    The platform of technologies provide improved “accessibility” and “consumability” of structured data
                                    elements. Unlike lexical methods of data entry (typing or dictation), our dynamic system offers a
                                    hypertext-based method that is intuitive and relevant to the patient’s ailment. The system allows the
                                    physician to become untethered from the desktop computer, enabling technology to compliment, rather than
                                    distract the physician from the patient. <br/><br/>
                                    <span className="font-weight-bold">The system creates value for the medical industry by providing
                    accessibility, usefulness and efficiency.  It is precisely what Google did for the internet.</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-secondary py-2"></div>
                </section>

                <section className="call-to-action text-white text-center">
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="col-xl-9 mx-auto">
                            <h1 className="mb-4">Archimedes Medical is at the forefront of innovation</h1>
                        </div>
                    </div>
                </section>

                {/* <section className="showcase">
                    <div className="bg-secondary py-2"></div>
                    <div className="container-fluid p-0 bg-dark">
                        <div className="row no-gutters">

                            <div className="col-lg-6 order-lg-2 text-white showcase-img border"
                                 style={{backgroundImage: "url(" + Capture1 + ")"}}></div>

                            <div className="col-lg-6 order-lg-1 my-auto showcase-text text-white">
                                <h2>Visualize</h2>
                                <p className="lead mb-0">Each patient's medical history will be abstracted into easy to read
                                    disease concepts and plotted on a network graph. This makes it extremely easy to see past,
                                    present, and future disease states for each patient.</p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-lg-6 text-white showcase-img border"
                                 style={{backgroundImage: "url(" + Capture2 + ")"}}></div>
                            <div className="col-lg-6 my-auto showcase-text text-white">
                                <h2>Predict</h2>
                                <p className="lead mb-0">Each patient's medical history comes equipped with fast, modern, and
                                    powerful algorithms for predicting future disease states. Plan the best course of action to
                                    prepare for future disease!</p>
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-lg-6 order-lg-2 text-white showcase-img border"
                                 style={{backgroundImage: "url(" + Capture3 + ")"}}></div>
                            <div className="col-lg-6 order-lg-1 my-auto showcase-text text-white">
                                <h2>Assign</h2>
                                <p className="lead mb-0">Modify a patient's medical record using the Code Catcher tool. You can assign
                                    ICD-10 codes, CPT codes, and more for each patient encounter.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="testimonials text-center bg-light">
                    <h2 className="mb-5">The Team</h2>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="testimonial-item mx-auto mb-5 mb-lg-0">
                                <img className="img-fluid rounded-circle mb-3" src={Chris} alt="Chris"/>
                                <h5>Dr. Christopher Wixon</h5>
                                <p className="font-weight-light mb-0 px-3 text-justify">Christopher L Wixon, MD is a practicing vascular surgeon who holds
                                    the sincere belief that improving the quality and efficiency of clinical information has the potential
                                    to have a profound effect on healthcare delivery, costs, and outcome. With an undergraduate degree in physics,
                                    Chris seeks an understanding of the fundamental forces behind our observations. A self-proclaimed
                                    medical cartographer, his vision is to map the corpus of medical ailments using a knowledge graph
                                    to inform point-of-service medical applications and machine learning workloads.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="testimonial-item mx-auto mb-5 mb-lg-0">
                                <img className="img-fluid rounded-circle mb-3" src={Jake} alt="Jake"/>
                                <h5>Jacob Kail</h5>
                                <p className="font-weight-light mb-0 px-3 text-justify">Jacob Kail is a Software Engineer at Atom Rain.
                                    He graduated from the College of Wooster in 2017 and has been with the company since then. Jacob has
                                    been working with Archimedes Medical since 2019. His main role has been to manage and handle data
                                    behind the mobile and web applications.</p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="testimonial-item mx-auto mb-5 mb-lg-0">
                                <img className="img-fluid rounded-circle mb-3" src={Ben} alt="Ben"/>
                                <h5>Benjamin Nussbaum</h5>
                                <p className="font-weight-light mb-0 px-3 text-justify">Ben Nussbaum has spent over 20 years architecting
                                    and implementing globally distributed, mission critical software solutions. He is a recognized
                                    Industry SME on the use of Connected Data Architectures, Graph Databases, Artificial Intelligence
                                    (i.e. ML, NLP) and other technologies to advance the areas of intelligence gathering, knowledge
                                    fusion and analytics. Ben consults on large scale ONgDB, big data and AI programs for global enterprises
                                    including Sony, ForgeRock and Toyota, and the United States Government for DoD, AF, USTRANSCOM and DHS.
                                    Ben is regularly invited to speak at National Big Data, Graph, AI and Analytics conferences as well
                                    as guest lecture at universities.</p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="testimonial-item mx-auto mb-5 mb-lg-0">
                                <img className="img-fluid rounded-circle mb-3" src={Derek} alt="Derek"/>
                                <h5>Derek Jow</h5>
                                <p className="font-weight-light mb-0 px-3 text-justify">Derek Jow is a master student at Stanford University pursuing a degree
                                    in Biomedical Informatics. He is interested in using machine learning to improve quality of care. As an intern
                                    at Archimedes Medical, he is currently working on the production of the Patient Journey tool to provide
                                    high quality visualizations of medical records.</p>
                            </div>
                        </div>
                    </div>
                    <h2 className="mb-5 mt-2">Advisors</h2>
                    <div className="row">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                            <div className="testimonial-item mx-auto mb-5 mb-lg-0">
                                <img className="img-fluid rounded-circle mb-3" src={Steve} alt="Steve Dunn"/>
                                <h5>Steve Dunn</h5>
                                <p className="font-weight-light mb-0 px-3 text-justify">As an advisor to Archimedes Medical, Steve is well-versed
                                    in a variety of innovation and development approaches inside and outside of large organizations and
                                    brings a clear perspective and experience in the deployment of digital technologies in rapid-growth
                                    environments. A former exec at BlackRock, Bank of America and Barclays, Steve is an investor and
                                    startup advisor at Cardinal Ventures (Stanford).</p>
                            </div>
                        </div>
                        <div className="col-lg-4"></div>
                    </div>
                </section> 

                <section className="call-to-action text-white text-center">
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-9 mx-auto">
                                <h2 className="mb-4">Ready to get started? Sign up now!</h2>
                            </div>
                            <div className="col-md-10 col-lg-8 col-xl-7 mx-auto">
                                <form>
                                    <div className="form-row">
                                        <!--
                                        <div className="col-12 col-md-9 mb-2 mb-md-0">
                                            <input type="email" className="form-control form-control-lg" placeholder="Enter your email...">
                                        </div>-->
                                        <div className="col-12">
                                            <a href="mailto:info@codecatcher.co" className="btn btn-block btn-lg btn-primary" target="_top" title="info@codecatcher.co">Contact Us!</a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            */}

            </div>
        );
    }

}