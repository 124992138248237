import React from "react";

export class FilterComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filters: {},
            preselected: {},
            callback: null
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            filters: nextProps.filters,
            preselected: nextProps.selected_filters,
            callback: nextProps.callback
        });
    }

    render() {

        let filters = Object.keys(this.state.filters);
        let elements = [];
        for (let i = 0; i < filters.length; i++) {
            let title = filters[i];
            let checkboxes = this.state.filters[title].map((label) => (<div key={title + "_" + label}><label>{label} <input type="checkbox" checked={this.state.preselected[title][label]}
                                                                                                  onChange={(event) => this.state.callback(event, title, label)}/></label></div>));
            let element = (
                <div key={title + i}>
                    <h5>{title}</h5>
                    <div className="m-3">
                        {checkboxes}
                    </div>
                </div>
            );
            elements.push(element)
        }

        //console.log(this.state.filters);
        //console.log(elements);

        return (
            <div className="filter_selection">
                <div className="row">
                    <div className="col">Filter Options <a className="card-link" data-toggle="collapse" href="#collapseFiltersOptions"><i className="fa fa-chevron-down"></i></a></div>
                </div>
                <div id="collapseFiltersOptions" className="collapse">
                    <div className="card pl-3">
                        {elements}
                    </div>
                </div>
            </div>
        );
    }
}