// NarrativeCompare.js
import React from "react";
import NarrativePane from "./NarrativePane"; // Ensure the path is correct
import SimilaritySlider from "./SimilaritySlider"; // Import the new component
import './narrative-compare.css'; // Create appropriate CSS for styling
import packageJson from '../package.json';
import { Spinner } from 'react-bootstrap'; // Add this import

export class NarrativeCompare extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            accessKey: localStorage.getItem('accessKey') || '',
            isAccessKeyInputVisible: false,
            normalizeEntities: true,
            // normalizeDocVec: true,
            similarityScore: null,
            error: null,
            isLoading: false,
            isWeightingPanelVisible: true,
            entityWeights: {
                DISORDER: 1,
                DRUG: 1,
                FINDING: 1,
                PROCEDURE: 1,
                SITUATION: 1,
                TEST: 1
            }
        };

        this.handleAccessKeyChange = this.handleAccessKeyChange.bind(this);
        this.toggleAccessKeyInputVisibility = this.toggleAccessKeyInputVisibility.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.toggleWeightingPanel = this.toggleWeightingPanel.bind(this);
        this.handleWeightChange = this.handleWeightChange.bind(this);
        this.leftPaneRef = React.createRef();
        this.rightPaneRef = React.createRef();
    }

    handleAccessKeyChange(event) {
        const accessKey = event.target.value;
        this.setState({ accessKey });
        localStorage.setItem('accessKey', accessKey);  // Save to localStorage
    }

    toggleAccessKeyInputVisibility() {
        this.setState(prevState => ({ isAccessKeyInputVisible: !prevState.isAccessKeyInputVisible }));
    }

    toggleWeightingPanel() {
        this.setState(prevState => ({ isWeightingPanelVisible: !prevState.isWeightingPanelVisible }));
    }

    handleWeightChange(entity, value) {
        this.setState(prevState => ({
            entityWeights: {
                ...prevState.entityWeights,
                [entity]: value
            }
        }));
    }

    handleSearch = async () => {
        this.setState({ similarityScore: null, error: null, isLoading: true });

        const leftPaneText = this.leftPaneRef.current && this.leftPaneRef.current.state.input_text;
        const rightPaneText = this.rightPaneRef.current && this.rightPaneRef.current.state.input_text;

        if (!leftPaneText || !rightPaneText) {
            this.setState({ error: "Please ensure both panes have narrative text loaded before comparing.", isLoading: false });
            return;
        }

        try {
            const [leftResponse, rightResponse] = await Promise.all([
                this.leftPaneRef.current.onPhraseChange(),
                this.rightPaneRef.current.onPhraseChange()
            ]);

            const doc1Data = leftResponse ? this.extractEntitiesAndLabels(leftResponse, leftPaneText) : { entities: [leftPaneText], labels: [], negatives: [false] };
            const doc2Data = rightResponse ? this.extractEntitiesAndLabels(rightResponse, rightPaneText) : { entities: [rightPaneText], labels: [], negatives: [false] };

            const payload = {
                doc1: {
                    entities: doc1Data.entities,
                    labels: doc1Data.labels,
                    negatives: doc1Data.negatives
                },
                doc2: {
                    entities: doc2Data.entities,
                    labels: doc2Data.labels,
                    negatives: doc2Data.negatives
                },
                entityWeights: this.state.entityWeights,
                normalizeEntities: this.state.normalizeEntities,
                // normalizeDocVec: this.state.normalizeDocVec
            };

            console.log("payload", payload);
            
            const response = await fetch(
                `${packageJson.pythonEnv.HOST_API}/1.0/journey/query/compareDocs`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Key': this.state.accessKey
                    },
                    body: JSON.stringify(payload)
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            if (data && data.similarity_score !== undefined) {
                this.setState({ similarityScore: data.similarity_score, isLoading: false });
            }
        } catch (error) {
            console.error("Error comparing documents:", error);
            this.setState({ error: "Failed to compare documents. Please try again.", isLoading: false });
        }
    }

    extractEntitiesAndLabels(response, originalText) {
        if (!response) return { entities: [], labels: [], negatives: [] };

        // Group entities by their context ranges
        const contextGroups = [];
        
        console.log("\n=== Processing Entities ===");
        // Sort entities by their starting position
        const sortedResponse = Object.values(response).sort((a, b) => a.context_start - b.context_start);
        
        sortedResponse.forEach(item => {
            const contextRange = `${item.context_start}-${item.context_end}`;
            let group = contextGroups.find(g => 
                (item.context_start >= g.start && item.context_start < g.end) ||
                (item.context_end >= g.start && item.context_end < g.end)
            );
            
            if (!group) {
                group = {
                    start: item.context_start,
                    end: item.context_end,
                    entities: [],
                    contextPhrase: originalText.substring(item.context_start, item.context_end).trim()
                };
                contextGroups.push(group);
            }
            
            const nnPhrase = item.results && item.results.length > 0 ? item.results[0].phrase : item.phrase;
            group.entities.push({
                originalPhrase: item.phrase,
                nnPhrase: nnPhrase,
                label: item.label,
                weight: this.state.entityWeights[item.label] || 0
            });
            
            console.log(`\nFound entity in context ${contextRange}:`);
            console.log(`Original phrase: "${item.phrase}"`);
            console.log(`1st NN phrase: "${nnPhrase}"`);
            console.log(`Label: ${item.label} (weight: ${this.state.entityWeights[item.label]})`);
        });

        const entities = [];
        const labels = [];
        const negatives = [];

        // Process each context group
        contextGroups.forEach((group, idx) => {
            console.log(`\n=== Processing Context Group ${idx + 1} ===`);
            console.log(`Original context: "${group.contextPhrase}"`);
            console.log("Entities in group:", group.entities.map(e => e.originalPhrase).join(", "));

            // Sort entities by weight to determine the winning label
            const sortedEntities = [...group.entities].sort((a, b) => b.weight - a.weight);
            const winningLabel = sortedEntities[0].label;
            
            console.log(`Winning label: ${winningLabel} (highest weight: ${sortedEntities[0].weight})`);

            // Replace all entity occurrences with their NN phrases
            let modifiedContext = group.contextPhrase;
            group.entities.forEach(entity => {
                modifiedContext = modifiedContext.replace(
                    entity.originalPhrase,
                    entity.nnPhrase
                );
            });

            console.log(`Modified context: "${modifiedContext}"`);

            // Only add if this context isn't already included
            if (!entities.includes(modifiedContext)) {
                entities.push(modifiedContext);
                labels.push(winningLabel);
                negatives.push(false);
            }
        });

        console.log("\n=== Final Output ===");
        console.log("Total unique contexts:", entities.length);
        entities.forEach((entity, i) => {
            console.log(`\nContext ${i + 1}:`);
            console.log(`Text: "${entity}"`);
            console.log(`Label: ${labels[i]}`);
        });

        return { entities, labels, negatives };
    }

    render() {
        return (
            <div className="narrative-compare-container">
                <div className="container">
                    <h1>Narrative Compare Tool</h1>
                    <hr />

                    {/* Access Key Section */}
                    <div className="access-key-section">
                        <label htmlFor="accessKey">
                            <h5>
                                Access Key &nbsp;
                                <button onClick={this.toggleAccessKeyInputVisibility}>
                                    {this.state.isAccessKeyInputVisible ? '-' : '+'}
                                </button>
                            </h5>
                        </label>
                        <input
                            type="password"
                            className="form-control"
                            id="accessKey"
                            placeholder="Enter Access Key"
                            value={this.state.accessKey}
                            onChange={this.handleAccessKeyChange}
                            style={{
                                width: "300px",
                                display: this.state.isAccessKeyInputVisible ? 'block' : 'none',
                            }}
                        />
                    </div>
                    <br />

                    {/* Entity Weighting Panel */}
                    <div className="weighting-panel-section">
                        <button onClick={this.toggleWeightingPanel} className="btn btn-secondary mb-2">
                            {this.state.isWeightingPanelVisible ? 'Hide' : 'Show'} Entity Weighting
                        </button>
                        {this.state.isWeightingPanelVisible && (
                            <div className="weighting-panel">
                                <div className="row">
                                    {Object.entries(this.state.entityWeights).map(([entity, weight]) => (
                                        <div key={entity} className="col-md-6 mb-3">
                                            <div className="entity-weight-container">
                                                <label htmlFor={`weight-${entity}`} className="entity-label">{entity}</label>
                                                <div className="slider-container">
                                                    <input
                                                        type="range"
                                                        className="form-range"
                                                        id={`weight-${entity}`}
                                                        min="0"
                                                        max="1"
                                                        step="0.1"
                                                        value={weight}
                                                        onChange={(e) => this.handleWeightChange(entity, parseFloat(e.target.value))}
                                                    />
                                                    <span className="weight-value">{weight.toFixed(1)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Top-Level Search Button */}
                    <div className="search-button-section text-center">
                        <button type="button" className="btn btn-primary" onClick={this.handleSearch}>
                            <i className="fa fa-search"></i> Compare Document Similarity
                        </button>
                        <br/>
                        {this.state.isLoading && (
                            <Spinner animation="border" role="status" className="mt-2">
                                <span className="visually-hidden"></span>
                            </Spinner>
                        )}
                        {!this.state.isLoading && this.state.similarityScore !== null && (
                            <div className="mt-4">
                                <SimilaritySlider score={this.state.similarityScore} />
                            </div>
                        )}
                        {this.state.error && (
                            <div className="alert alert-danger mt-2" role="alert">
                                {this.state.error}
                            </div>
                        )}
                    </div>
                    <br />

                    {/* Two Narrative Panes Side by Side */}
                    <div className="row">
                        <div className="col-md-6">
                            <NarrativePane
                                ref={this.leftPaneRef}
                                accessKey={this.state.accessKey}
                                select_API_host={packageJson.pythonEnv.HOST_API}
                                paneId="left"
                            />
                        </div>
                        <div className="col-md-6">
                            <NarrativePane
                                ref={this.rightPaneRef}
                                accessKey={this.state.accessKey}
                                select_API_host={packageJson.pythonEnv.HOST_API}
                                paneId="right"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NarrativeCompare;