import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {Header} from "./Header.js";
import {Footer} from "./Footer.js";
import {Home} from "./Home.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import 'font-awesome/css/font-awesome.min.css';
import {Dashboard} from "./Dashboard.js"
import {Cohort} from "./cohort";
import {Physician} from "./physician";
import {Documentation} from "./documentation";
import {Vocab} from "./Vocab";
import {Icd} from "./icd";  
import {NarrativeCompare} from "./NarrativeCompare";
import {DigitalTwin} from "./DigitalTwin";
import packageJson from '../package.json';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter
} from "react-router-dom";

class BaseComponent extends React.Component {

    constructor(props) {
        super(props);

        let profile;
        if (localStorage["login"]) {
            profile = JSON.parse(localStorage["login"]);
        } else {
            profile = null;
        }

        this.state = {
            profile: profile,
            valid_session: true  // Override graphgrid authentication
        };

        // this.validateLogin = this.validateLogin.bind(this);
    }

    validateLogin(access_token, callback) {
        let xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = function () {
            if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
                callback(JSON.parse(xmlHttp.response));
            } else if (xmlHttp.readyState === 4) {
                console.log("Error on validating login");
            }
        };

        let url = new URL("https://medic-prod-api.graphgrid.com/1.0/security/oauth/check_token");
        url.searchParams.append("token", access_token);

        xmlHttp.open("POST", url.toString(), true);
        xmlHttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xmlHttp.setRequestHeader("Authorization", "Basic MjEzYWE5NWYwMjUyNjA0OTc0MGUyYjlhMWQ0ZWQzNDk6OGExYmUyYTQ5ZTYwMjIwYWVlMjIyMWRjYWQ0OWUxMDY=");

        xmlHttp.send(null);
    }

    componentDidMount() {
        this.updatePageTitle();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.updatePageTitle();
        }
    }

    updatePageTitle() {
        const { pathname } = this.props.location;
        const titleAppend = packageJson.env.TITLE_APPEND || '';
        let pageTitle = '';

        switch (pathname) {
            case '/dashboard':
                pageTitle = 'Dashboard';
                break;
            case '/cohort':
                pageTitle = 'Cohort';
                break;
            case '/physician':
                pageTitle = 'Physician Search';
                break;
            case '/vocab':
                pageTitle = 'Semantic Search';
                break;
            case '/icd':
                pageTitle = 'Health Concept Extractor';
                break;
            case '/narrative-compare':
                pageTitle = 'Narrative Compare';
                break;
            case '/digital-twin':
                pageTitle = 'Digital Twin';
                break;
            case '/docs':
                pageTitle = 'API';
                break;
            default:
                pageTitle = 'Home';
        }

        document.title = `${pageTitle} - Archimedes Medical${titleAppend}`;
    }

    render() {

        let router;
        if (this.state.valid_session) {
            router = (
                <Switch>
                    <Route path="/dashboard">
                        <Dashboard></Dashboard>
                    </Route>
                    <Route path="/cohort">
                        <Cohort></Cohort>
                    </Route>
                    <Route path="/physician">
                        <Physician></Physician>
                    </Route>
                    <Route path="/vocab">
                        <Vocab></Vocab>
                    </Route>
                    <Route path="/icd">
                        <Icd></Icd>
                    </Route>
                    <Route path="/narrative-compare">
                        <NarrativeCompare></NarrativeCompare>
                    </Route>
                    <Route path="/digital-twin">
                        <DigitalTwin></DigitalTwin>
                    </Route>
                    <Route path="/docs">
                        <Documentation></Documentation>
                    </Route>
                    <Route path="/">
                        <Home></Home>
                    </Route>
                </Switch>
            );
        } else {
            let loginMessage = (
                <div className="container my-3">
                    <h3>You must login to view this page</h3>
                </div>
            );
            router = (
                <Switch>
                    <Route path="/dashboard">
                        {loginMessage}
                    </Route>
                    <Route path="/cohort">
                        {loginMessage}
                    </Route>
                    <Route path="/physician">
                        {loginMessage}
                    </Route>
                    <Route path="/cpt">
                        {loginMessage}
                    </Route>
                    <Route path="/vocab">
                        {loginMessage}
                    </Route>
                    <Route path="/icd">
                        {loginMessage}
                    </Route>
                    <Route path="/narrative-compare">
                        {loginMessage}
                    </Route>
                    <Route path="/api">
                        {loginMessage}
                    </Route>
                    <Route path="/">
                        <Home></Home>
                    </Route>
                </Switch>
            );
        }

        return (
            <div id="global-container">
                <Header profile={this.state.profile}/>
                <div className="content-wrap">
                    {router}
                </div>
                <Footer/>
            </div>
        );
    }
}

const Base = withRouter(BaseComponent);

ReactDOM.render(
  <React.StrictMode>
      <Router>
          <Base />
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
