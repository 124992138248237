import React from "react";
import { NavLink } from "react-router-dom";
// import logo from "./img/favicon.ico";
// import logo from "./img/logo.png";
import logo from "./img/logo-white.jpg";

// import {Login} from '@graphgrid/graphgrid-security';
// import '@graphgrid/graphgrid-security/dist/components/Login/login.css'
// import '@graphgrid/graphgrid-security/dist/components/Input/input.css'
import packageJson from '../package.json';
import $ from "jquery";

export class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            profile: props.profile
        };

        // this.onSuccess = this.onSuccess.bind(this);
        // this.signout = this.signout.bind(this);
    }

    // signout() {

    //     localStorage.clear();
    //     this.setState({profile: null});

    //     $('#logoutModal').modal('hide');
    //     window.location.reload();
    // }

    // onSuccess(response) {
    //     console.log("Login Success");

    //     localStorage["login"] = JSON.stringify(response);
    //     this.setState({profile: response});

    //     $('#loginModal').modal('hide');

    //     let promise = new Promise((resolve, reject) => {
    //         resolve(response);
    //     });
    //     window.location.reload();
    //     return promise;
    // }

    render() {

        // let login = (
        //     <button type="button" className="btn btn-secondary" data-toggle="modal" data-target="#loginModal">
        //         Login
        //     </button>
        // );

        // let profileName = "";
        // if (this.state.profile) {
        //     login = (
        //         <button type="button" className="btn btn-secondary" data-toggle="modal" data-target="#logoutModal">
        //             Logout
        //         </button>
        //     );
        //     profileName = this.state.profile.results.name;
        // }

        return (
            <nav className="navbar navbar-light static-top navbar-expand-lg">
                <div className="container">
                    <img title="Archimedes Medical" src={logo} width="46" height="46" alt="Archimedes Medical"/>
                    <NavLink to="/" className="navbar-brand">&nbsp;Archimedes Medical</NavLink>
                    <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                            data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                            aria-label="Toggle navigation">Menu &nbsp;<i className="fa fa-bars"></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <NavLink exact to="/" className="nav-link" activeClassName="active">Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/vocab" className="nav-link" activeClassName="active">Semantic Search</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/icd" className="nav-link" activeClassName="active">Health Concept Extractor</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/narrative-compare" className="nav-link" activeClassName="active">Narrative Compare</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/digital-twin" className="nav-link" activeClassName="active">Digital Twin</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/docs" className="nav-link" activeClassName="active">API</NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link" href="https://codecatcher.co/" target="_blank" rel="noopener noreferrer">Code Catcher</a>
                            </li> */}
                            {/* <li className="nav-item">
                                {login}
                                <div className="modal fade" id="loginModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel">Login to Archimedes Medical</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <Login
                                                    invalidEmailMessage="Invalid Email"
                                                    invalidPasswordMessage="Invalid Password"
                                                    loginSuccessCb={this.onSuccess}
                                                    securityHost={packageJson.env.SEC_HOST}
                                                    oauthId={packageJson.env.CLIENT_ID}
                                                    oauthSecret={packageJson.env.CLIENT_SECRET}
                                                    accessTokenKey={'access_token'}
                                                    refreshTokenKey={'refresh_token'}
                                                />
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="logoutModalLabel" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="logoutModalLabel">Logout of Archimedes Medical</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                You are signed in as {profileName}.
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.signout}>Signout</button>
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}