// NarrativePane.js
import React from "react";
import './react-smart-data-table.css';
import SmartDataTable from 'react-smart-data-table';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import packageJson from '../package.json';
import './icd.css';

// DropdownComponent as a separate component within NarrativePane.js
class DropdownComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            original_codes: props.codes,
            current_page: 0,
            customAnnotation: ""
        };

        this.per_page = 1;

        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSaveCustomAnnotation = this.handleSaveCustomAnnotation.bind(this);
    }

    prev() {
        // Disable pagination for now
        // this.setState({
        //     current_page: Math.max(this.state.current_page - 1, 0)
        // });
    }

    next() {
        // Disable pagination for now
        // this.setState({
        //     current_page: Math.min(this.state.current_page + 1, (this.state.original_codes.length + this.per_page - 1) / this.per_page - 1)
        // });
    }

    handleInputChange(event) {
        this.setState({customAnnotation: event.target.value});
    }

    handleSaveCustomAnnotation() {
        let customEntry = {
            phrase: this.state.customAnnotation,
            vocab: "Custom",
            id: "Custom"
        };
        this.props.callback(customEntry, this.props.original_text);
        this.setState({ customAnnotation: "" }); // Clear input after saving
    }

    render() {
        let current_codes = [];
        if (this.state.original_codes) {
            current_codes = this.state.original_codes.slice(this.state.current_page * this.per_page, (this.state.current_page + 1) * this.per_page);
        }

        let ctrl = this;

        let decoration = "underline";
        let negative_el = "No";
        if (this.props.negative) {
            negative_el = "Yes";
            decoration = "line-through";
        }

        return (
            <span style={{color: this.props.color, textDecoration: decoration}} className="dropdown">
                {this.props.text}
                <div className="dropdown-content">
                    <div className="p-1" style={{color: "black"}}>
                        <h5>{this.props.text}</h5>
                        <p>Is Negated? {negative_el}</p>
                        <p>Want more information? <a href={this.props.stat_pearls_url} target="_blank" rel="noopener noreferrer">{this.props.stat_pearls_name}</a></p>
                        {/* <button className="btn btn-info" onClick={this.plot}>View Scatter Plot</button> */}
                    </div>
                    <hr/>
                    <div className="row text-center">
                        <div className="col-4">
                            <button className="btn btn-info" onClick={this.prev}>&lt;</button>
                        </div>
                        <div className="col-4">
                            <button className="btn btn-danger" onClick={() => ctrl.props.removeCallback(ctrl.props.index)}>Remove</button>
                        </div>
                        <div className="col-4">
                            <button className="btn btn-info" onClick={this.next}>&gt;</button>
                        </div>
                    </div>
                    <hr/>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                        {current_codes.map((val, idx) =>
                            <li style={{ color: val.displayColor, cursor: 'pointer' }}  
                                key={`${val.source}-${val.id}-${val.phrase}-${idx}`} 
                                onClick={() => ctrl.props.callback(val, this.props.original_text)}>
                                <p><b>Phrase:</b> {val.phrase}</p>
                                <p><b>Id:</b> {val.id} ({val.vocab})</p>
                            </li>
                        )}
                    </ul>
                    <div>
                        <input
                            type="text"
                            placeholder="Custom Annotation"
                            value={this.state.customAnnotation}
                            onChange={this.handleInputChange}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    this.handleSaveCustomAnnotation();
                                }
                            }}
                        />
                        <button onClick={this.handleSaveCustomAnnotation}>Save</button>
                    </div>
                </div>
            </span>
        );
    }
}

class NarrativePane extends React.Component {

    constructor(props) {
        super(props);

        this.ref = React.createRef();

        this.state = {
            // select_API: "Development",
            select_API_host: packageJson.pythonEnv.HOST_API,
            table: {
                title: "Selected Codes",
                headers: ["phrase", "vocab", "term", "id", "remove"],
                headerMap: {
                    'phrase': {
                        text: "Query",
                        sortable: true
                    },
                    'vocab': {
                        text: "Vocabulary",
                        sortable: true
                    },
                    'id': {
                        text: "Term Id",
                        sortable: false,
                        transform: (value, index, row) => (<a href={"https://www.icd10data.com/search?s=" + value} target="_blank" rel="noopener noreferrer">{value}</a>)
                    },
                    'term': {
                        text: "Term Name",
                        sortable: true
                    },
                    'remove': {
                        text: "Remove",
                        transform: (value, index, row) => (<button className="btn btn-danger" onClick={() => this.removeEntryFromTable(index)}>X</button>)
                    },
                },
                perPageItemCount: 10,
                data: []
            },
            filters: {
                //cpt: false,
                // icd10cm: true,
                //icd10pcs: false,
                // snomed: false,
                //riak: false
            },
            taxonomy: "all",  // umls, snomed
            alert_invalid: false,
            alert_error: false,
            alert_message: "",
            alert_no_results: false,
            loading: false,
            interactive_text: "",
            dropdown_phrases: {},
            dropdown_colorMap: {},
            input_text: props.initialText || "",
            interactive_index_map: {},
            saving_annotations_loading: false,
            // show_plot: false,
            // plot_title: "PCA of Nearest Neighbors"
            // selectedTaxonomy: "all",
            selectedTaxonomy: "snomed",
            findBestMatch: true,
            showDownloadButton: false,
            response: {},
            entityVisibility: {
                procedure: true,
                body_structure: true,
                disorder: true,
                occupation: true,
                finding: true,
                therapy: true,
                person: true,
                location: true,
                event: true,
                religion: true,
                situation: true,
                drug: true,
                racial_group: true
            },
            useNER: true,
            isInteractiveTextExpanded: true,
            readOnly: props.readOnly || false,
            usePrePopulatedEntities: Boolean(props.initialEntities)
        };

        this.httpRequest = this.httpRequest.bind(this);
        this.onPhraseChange = this.onPhraseChange.bind(this);
        this.addEntryToTable = this.addEntryToTable.bind(this);
        this.removeEntryFromTable = this.removeEntryFromTable.bind(this);
        this.saveAnnotations = this.saveAnnotations.bind(this);
        this.viewAnnotations = this.viewAnnotations.bind(this);
        this.onChooseTaxonomy = this.onChooseTaxonomy.bind(this);
        this.onToggleFindBestMatch = this.onToggleFindBestMatch.bind(this);
        this.jsonToCSV = this.jsonToCSV.bind(this);
        this.downloadCSV = this.downloadCSV.bind(this);
        this.onChooseForMe = this.onChooseForMe.bind(this);
        this.handleTextareaChange = this.handleTextareaChange.bind(this); // New method for textarea
        this.toggleEntityVisibility = this.toggleEntityVisibility.bind(this);
        this.toggleUseNER = this.toggleUseNER.bind(this);
        this.toggleInteractiveText = this.toggleInteractiveText.bind(this);

        this.colorMap = {
            "icd10cm": "#FF0000",
            "cpt": "#00FF00",
            "rxnorm": "#0000FF",
            "rucc": "#A020F0"
        };

        // Removed this.narrative as we are using state.input_text
        // this.narrative = "";
        this.intervalId = 0;

        // Map of entity labels to colors
        this.LABEL_COLORS = {
            'procedure': 'blue',
            'body_structure': '#FFBF00',
            'disorder': 'red',
            'occupation': 'red',
            'finding': 'red',
            'therapy': 'blue',
            'person': '#FFBF00',
            'location': '#FFBF00',
            'event': 'red',
            'religion': 'red',
            'situation': 'red',
            'drug': 'green',
            'racial_group': 'red'
        };
    }

    componentDidMount() {
        //$('[data-toggle="tooltip"]').tooltip();
        if (this.props.initialText && this.props.initialEntities) {
            this.updateInteractiveTextVariables(this.props.initialEntities);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.initialText !== prevProps.initialText || 
            this.props.initialEntities !== prevProps.initialEntities) {
            this.setState({ 
                input_text: this.props.initialText || "",
                usePrePopulatedEntities: Boolean(this.props.initialEntities)
            });
            if (this.props.initialEntities) {
                this.updateInteractiveTextVariables(this.props.initialEntities);
            }
        }
    }

    resetTables() {
        this.setState({
            table: {
                ...this.state.table,
                data: []
            },
            dropdown_phrases: {},
            dropdown_colorMap: {}
        });
    }

    removeNerIndex(index) {
        let index_map = { ...this.state.interactive_index_map };
        if (index_map.hasOwnProperty(index)) {
            delete index_map[index];
            this.setState({
                interactive_index_map: index_map
            });
        }
    }

    httpRequest(method, url, params, callback) {
        const accessKey = this.props.accessKey || '';

        let xmlHttp = new XMLHttpRequest();
        let ctrl = this;

        ctrl.setState({
            alert_message: "",
        })
        
        xmlHttp.onreadystatechange = function () {
            if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
                ctrl.setState({loading: false});
                callback(JSON.parse(xmlHttp.response));
            } else if (xmlHttp.readyState === 4) {
                if (xmlHttp.status === 400) {
                    // Handle no entity results
                    callback({});
                    ctrl.setState({
                        alert_invalid: false,
                        alert_error: false,
                        alert_no_results: true
                    });
                } else {
                    let response = {};
                    try {
                        response = JSON.parse(xmlHttp.response);
                    } catch (e) {
                        response.error = 'Unknown error occurred';
                    }
                    const errorMessage = response.error || 'Unknown error occurred';
                    ctrl.setState({
                        alert_invalid: false,
                        alert_error: true,
                        alert_no_results: false,
                        alert_message: errorMessage
                    });
                }
            
                ctrl.setState({loading: false, saving_annotations_loading: false});
            }
        };
        
        xmlHttp.open(method, url, true);
        
        if (accessKey) {
            xmlHttp.setRequestHeader("Access-Key", accessKey);
        }
    
        if (method === "POST") {
            xmlHttp.setRequestHeader("Content-Type", "application/json");
            xmlHttp.send(JSON.stringify(params));
        } else {
            xmlHttp.send();
        }
    }

    getPhrasesFromInput() {
        // Updated to use state.input_text instead of this.narrative
        let input = this.state.input_text;
        let parts = input.split("@");
        if (parts.length === 1) {
            return [input];
        } else {
            let terms = [];
            for (let i = 1; i < parts.length; i += 2) {
                terms.push(parts[i]);
            }
            return terms;
        }
    }

    onSearch() {
        this.onPhraseChange();
    }

    onChooseTaxonomy(event) {
        this.setState({ selectedTaxonomy: event.target.value }, this.onPhraseChange);
    }

    onToggleFindBestMatch() {
        this.setState({ findBestMatch: !this.state.findBestMatch }, this.onPhraseChange);
    }

    onChooseForMe() {
        this.setState({
            alert_invalid: false,
            alert_error: false,
            alert_no_results: false,
            loading: true,
        });
    
        this.httpRequest("GET", `${this.state.select_API_host}/1.0/random_narrative`, null, (data) => {
            // Update state.input_text instead of this.narrative and ref.current.value
            this.setState({ input_text: data.narrative, loading: false });
        });
    }

    onPhraseChange = () => {
        return new Promise((resolve, reject) => {
            if (this.state.usePrePopulatedEntities && this.props.initialEntities) {
                resolve(this.props.initialEntities);
                return;
            }

            if (this.state.input_text.length > 0) {
                if (!this.state.useNER) {
                    resolve(null);
                    return;
                }

                this.resetTables();
                this.setState({
                    dropdown_phrases: {},
                    dropdown_colorMap: {},
                    alert_invalid: false,
                    alert_error: false,
                    alert_no_results: false,
                    loading: true,
                    input_text: this.state.input_text, // Already in state
                    interactive_index_map: {},
                    showDownloadButton: false,
                    timeTaken: "",
                    entityCount: ""
                });

                let params = {
                    "phrase": this.state.input_text, // Updated to use state.input_text
                    "filters": this.state.filters,
                    "taxonomy": this.state.selectedTaxonomy,
                    "chunk_phrase": true,
                    "save_narrative": true,
                    "max_neighbors": 5,
                    "find_best_match": this.state.findBestMatch,
                };

                const startTime = new Date().getTime();
                this.httpRequest("POST", `${this.state.select_API_host}/1.0/journey/query/phrase`, params, (response) => {
                    const endTime = new Date().getTime();

                    // if (response && Object.keys(response).length >= 1) {
                    if (response) {
                        if (Object.keys(response).length >= 1) {
                                this.setState({
                                    response: response,
                                showDownloadButton: true,
                                timeTaken: `Prediction time: ${((endTime - startTime) / 1000).toFixed(2)} seconds`,
                                entityCount: `# Entities found: ${Object.keys(response).length}`
                            }, () => {
                                this.updateInteractiveTextVariables(response);
                                resolve(response);  // Resolve the promise with the response
                            });
                        } else {
                            // No entities in response
                            this.setState({
                                alert_invalid: false,
                                alert_error: false,
                                alert_no_results: true,
                                showDownloadButton: false
                            }, () => {
                                resolve(null);  // Resolve the promise with null if no results
                            });
                        }
                    } else {
                        this.setState({
                            alert_invalid: false,
                            alert_error: false,
                            alert_no_results: true,
                            showDownloadButton: false
                        }, () => {
                            resolve(null);  // Resolve the promise with null if no results
                        });
                    }
                });
            } else {
                resolve(null);  // Resolve immediately if there's no input
            }
        });
    }

    updateInteractiveTextVariables(response) {
        let keys = Object.keys(response);
        let index_map = {};

        // get index of each key phrase in the input
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let codes = [];
            let results = response[key]["results"];
            let start = response[key]["start"];
            let end = response[key]["end"];
            let phrase = response[key]["phrase"];
            let negative = response[key]["negative"];
            let surl = response[key]["stat_pearls"]["id"];
            let sname = response[key]["stat_pearls"]["phrase"];
            let label = response[key]["label"].toLowerCase(); // Convert to lowercase here

            // Assign display color based on entity label
            for (let j = 0; j < results.length; j++) {
                let result = results[j];
                result.displayColor = this.LABEL_COLORS[label] || '#212529';
            }

            if (results === undefined || results.length === 0) {
                codes.push({
                    phrase: "Terminology code NOT IDENTIFIED",
                });
            } else {
                for (let j = 0; j < Math.min(results.length, 100); j++) {
                    let item = {
                        source: results[j]["source"],
                        vocab: results[j]["vocab"],
                        id: results[j]["id"],
                        phrase: results[j]["phrase"],
                        query: results[j]["source"] === "Ace" ? phrase : key,
                        distance: results[j]["semantic-idf"],
                        displayColor: results[j]["displayColor"]
                    };
                    if ("specific" in results[j]) {  // Include specific field if it exists
                        item["specific"] = results[j]["specific"];
                    }
                    if ("hcc" in results[j]) {  // Include hcc field if it exists
                        item["hcc"] = results[j]["hcc"];
                    }
                    codes.push(item);
                }
            }

            if (codes.length === 0) {
                codes.push("None Found");
            }

            if (index_map.hasOwnProperty(start)) {
                if (index_map[start]["end"] < end) {
                    index_map[index_map[start]["end"] + 1] = {
                        "codes": codes,
                        "end": end,
                        "negative": negative,
                        "stat_pearls_url": surl,
                        "stat_pearls_name": sname,
                        "label": label
                    };
                } else {
                    index_map[end + 1] = {
                        "codes": index_map[start]["codes"],
                        "end": index_map[start]["end"],
                        "negative": index_map[start]["negative"],
                        "stat_pearls_url": surl,
                        "stat_pearls_name": sname,
                        "label": label
                    };
                    index_map[start] = {
                        "codes": codes,
                        "end": end,
                        "negative": negative,
                        "stat_pearls_url": surl,
                        "stat_pearls_name": sname,
                        "label": label
                    };
                }
            } else {
                index_map[start] = {
                    "codes": codes,
                    "end": end,
                    "negative": negative,
                    "stat_pearls_url": surl,
                    "stat_pearls_name": sname,
                    "label": label
                };
            }
        }

        this.setState({
            interactive_index_map: index_map
        });
    }

    createInteractiveTextDisplay() {

        let input = this.state.input_text;
        let index_map = this.state.interactive_index_map;

        let buffer = "";
        let elements = [];
        for (let i = 0; i < input.length; i++) {
            if (index_map.hasOwnProperty(i)) {
                if (!this.state.entityVisibility[index_map[i]["label"]]) {
                    buffer += input.substring(i, index_map[i]["end"]);
                    i += (index_map[i]["end"] - i) - 1;
                    continue;
                }

                if (buffer !== "") {
                    elements.push((
                        <span key={`buffer-${i}`}>{buffer}</span>
                    ));
                    buffer = "";
                }

                let color;
                if (index_map[i]["codes"].length > 0) {
                    // Assign color based on entity label
                    color = this.LABEL_COLORS[index_map[i]["label"]] || '#212529';
                } else {
                    color = '#212529'; // Default dark gray
                }

                let original_text = input.substring(i, index_map[i]["end"]);
                if (!this.state.dropdown_phrases.hasOwnProperty(original_text)) {
                    this.state.dropdown_phrases[original_text] = original_text;
                }

                // Override the color if the user has selected an entry in the dropdown
                if (this.state.dropdown_colorMap.hasOwnProperty(original_text)) {
                    color = this.state.dropdown_colorMap[original_text];
                } else {
                    // Store the color in the dropdown color map
                    this.state.dropdown_colorMap[original_text] = color;
                }

                
                let dropdown = (
                    <DropdownComponent 
                        text={this.state.dropdown_phrases[original_text]} 
                        codes={index_map[i]["codes"]} 
                        color={color} 
                        negative={index_map[i]["negative"]}
                        key={`dropdown-${i}`} 
                        index={i} 
                        callback={this.addEntryToTable} 
                        original_text={original_text} 
                        removeCallback={this.removeNerIndex}
                        stat_pearls_url={index_map[i]["stat_pearls_url"]} 
                        stat_pearls_name={index_map[i]["stat_pearls_name"]}
                    />
                );

                elements.push(dropdown);
                i += (index_map[i]["end"] - i) - 1;
            } else {

                if (input[i] === "\n") {
                    if (buffer !== "") {
                        elements.push((
                            <span key={`buffer-${i}`}>{buffer}</span>
                        ));
                        elements.push((<br key={`br-${i}`} />));
                        buffer = "";
                    }
                } else {
                    buffer += input[i];
                }
            }
        }

        // flush buffer
        if (buffer !== "") {
            elements.push((
                <span key={`buffer-end`}>{buffer}</span>
            ));
            buffer = ""
        }

        return elements;
    }

    addEntryToTable(entry, dropdown_index) {

        let table = { ...this.state.table };
        table.data.push({
            "phrase": dropdown_index,
            "vocab": entry.vocab,
            "id": entry.id,
            "term": entry.phrase,
            "remove": "x"
        });

        let updated_phrases = { ...this.state.dropdown_phrases };
        if (dropdown_index) {
            updated_phrases[dropdown_index] = entry.phrase;
        }

        // Update the display color for the phrase shown in the interactive text
        let updated_colorMap = { ...this.state.dropdown_colorMap };

        if (entry.id === "Custom") {
            // Always show Custom Annotations as Blue
            updated_colorMap[dropdown_index] = "blue";
        } else {
            if (dropdown_index && entry.displayColor) {
                updated_colorMap[dropdown_index] = entry.displayColor;
            }
        }

        this.setState({
            table: table,
            dropdown_phrases: updated_phrases,
            dropdown_colorMap: updated_colorMap
        });
    }

    removeEntryFromTable(index) {
        let table = { ...this.state.table };
        table.data.splice(index, 1);
        this.setState({
            table: table
        });
    }

    saveAnnotations() {

        if (this.state.table.data.length > 0) {

            this.setState({ saving_annotations_loading: true });

            let annotations = [];
            for (let i = 0; i < this.state.table.data.length; i++) {
                annotations.push( {
                   "query":  this.state.table.data[i].phrase,
                   "vocab":  this.state.table.data[i].vocab,
                   "term":  this.state.table.data[i].term,
                   "id":  this.state.table.data[i].id,
                });
            }

            let params = {
                "annotations": annotations
            };

            let ctrl = this;
            this.httpRequest("POST", `${this.state.select_API_host}/1.0/journey/saveAnnotations`, params, function (response) {
                alert("Saving success!");
                ctrl.setState({saving_annotations_loading: false});
            });
        } else {
            alert("There are no annotations to save!");
        }

    }

    viewAnnotations() {
        const accessKey = this.props.accessKey || '';
    
        fetch(`${this.state.select_API_host}/1.0/journey/annotations`, {
            method: 'GET',
            headers: {
                'Access-Key': accessKey
            }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return response.blob();
        })
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'annotations.csv'; // specify the name of the file to be downloaded
    
            document.body.appendChild(a);
            a.click();
    
            window.URL.revokeObjectURL(url);
        })
        .catch(error => {
            console.error('There was an error downloading the annotations:', error);
            this.setState({
                alert_invalid: false,
                alert_error: true,
                alert_no_results: false,
                alert_message: error.toString()
            });
        });
    }

    jsonToCSV(json) {
        let csvRows = [];
        let headers = ['"NER"'];
        let maxResults = 0;
    
        // Determine the maximum number of results and prepare headers
        for (const key in json) {
            const results = json[key].results;
            maxResults = Math.max(maxResults, results.length);
        }
        for (let i = 1; i <= maxResults; i++) {
            headers.push(`"NN${i}_code"`, `"NN${i}_phrase"`);
        }
        csvRows.push(headers.join(','));
    
        // Convert each top-level object to a CSV row
        for (const key in json) {
            const ner = json[key].phrase;
            const results = json[key].results;
            let row = [`"${ner}"`];
    
            for (let i = 0; i < maxResults; i++) {
                if (i < results.length) {
                    row.push(`"${results[i].id}"`, `"${results[i].phrase}"`);
                } else {
                    row.push('', ''); // Fill in blanks if this row has fewer results
                }
            }
            csvRows.push(row.join(','));
        }
    
        return csvRows.join('\n');
    }

    downloadCSV() {
        const csvString = this.jsonToCSV(this.state.response); // Assuming the response is stored in state
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'narrative_capture_export.csv';
        document.body.appendChild(a); // Append to body to ensure visibility in the document
        a.click();
        document.body.removeChild(a); // Clean up
    }

    handleAccessKeyChange(event) {
        const accessKey = event.target.value;
        this.setState({ accessKey });
        localStorage.setItem('accessKey', accessKey);  // Save to localStorage
    }

    toggleAccessKeyInputVisibility = () => {
        this.setState(prevState => ({ isAccessKeyInputVisible: !prevState.isAccessKeyInputVisible }));
    }

    // New method to handle textarea changes
    handleTextareaChange(event) {
        this.setState({ input_text: event.target.value });
    }

    toggleEntityVisibility(entityType) {
        this.setState(prevState => ({
            entityVisibility: {
                ...prevState.entityVisibility,
                [entityType]: !prevState.entityVisibility[entityType]
            }
        }));
    }

    toggleUseNER() {
        this.setState(prevState => ({
            useNER: !prevState.useNER
        }));
    }

    toggleInteractiveText() {
        this.setState(prevState => ({
            isInteractiveTextExpanded: !prevState.isInteractiveTextExpanded
        }));
    }

    render() {

        let alertInvalid = (<span></span>);
        if (this.state.alert_invalid) {
            alertInvalid = (
                <div className="alert alert-warning alert-dismissible">
                    <strong>Warning!</strong> Phrase is invalid! Please check again.
                    <button type="button" className="close" onClick={() => this.setState({alert_invalid: false})}>&times;</button>
                </div>
            );
        }

        let alertError = (<span></span>);
        if (this.state.alert_error) {
            alertError = (
                <div className="alert alert-danger alert-dismissible">
                    <strong>Error!</strong> {this.state.alert_message}
                    <button type="button" className="close" onClick={() => this.setState({alert_error: false})}>&times;</button>
                </div>
            );
        }

        let alertNoResults = (<span></span>);
        if (this.state.alert_no_results) {
            alertNoResults = (
                <div className="alert alert-warning alert-dismissible">
                    <strong>Warning!</strong> There are no codes found for this input.
                    <button type="button" className="close" onClick={() => this.setState({alert_no_results: false})}>&times;</button>
                </div>
            );
        }

        let searchButton;
        let chooseForMe;
        let chooseTaxonomy;
        let toggleFindBestMatch;
        if (this.state.loading) {
            searchButton = (
                <button type="button" className="btn btn-primary" disabled><i className="fa fa-search"></i></button>
            );
            chooseForMe = (
                <button type="button" className="btn btn-primary" disabled>Choose For Me</button>
            );
            chooseTaxonomy = (
                <div>
                    <input
                        type="radio"
                        id="all_taxonomies"
                        name={`taxonomy-${this.props.paneId}`}
                        value="all"
                        checked={this.state.selectedTaxonomy === "all"}
                        onChange={this.onChooseTaxonomy}
                        disabled
                    />
                    <label htmlFor="all_taxonomies">&nbsp; All Taxonomies</label>
                    <br />
                    <input
                        type="radio"
                        id="snomed"
                        name={`taxonomy-${this.props.paneId}`}
                        value="snomed"
                        checked={this.state.selectedTaxonomy === "snomed"}
                        onChange={this.onChooseTaxonomy}
                        disabled
                    />
                    <label htmlFor="snomed">&nbsp; SNOMED</label>
                    <br />
                    <input
                        type="radio"
                        id="umls"
                        name={`taxonomy-${this.props.paneId}`}
                        value="umls"
                        checked={this.state.selectedTaxonomy === "umls"}
                        onChange={this.onChooseTaxonomy}
                        disabled
                    />
                    <label htmlFor="umls">&nbsp; UMLS</label>
                </div>
            )
            toggleFindBestMatch = (
                <div>
                    <input
                        type="checkbox"
                        id={`find_best_match-${this.props.paneId}`}
                        checked={this.state.findBestMatch}
                        onChange={this.onToggleFindBestMatch}
                        disabled
                    />
                    <label htmlFor={`find_best_match-${this.props.paneId}`}> &nbsp; Match Substring</label>
                </div>
            );
        } else {
            searchButton = (
                <button type="button" className="btn btn-primary" onClick={this.onPhraseChange}><i className="fa fa-search"></i></button>
            );
            chooseForMe = (
                <button type="button" className="btn btn-primary" onClick={this.onChooseForMe}>Choose For Me</button>
            );
            chooseTaxonomy = (
                <div>
                    <input
                        type="radio"
                        id="all_taxonomies"
                        name={`taxonomy-${this.props.paneId}`}
                        value="all"
                        checked={this.state.selectedTaxonomy === "all"}
                        onChange={this.onChooseTaxonomy}
                    />
                    <label htmlFor="all_taxonomies">&nbsp; All Taxonomies</label>
                    <br />
                    <input
                        type="radio"
                        id="snomed"
                        name={`taxonomy-${this.props.paneId}`}
                        value="snomed"
                        checked={this.state.selectedTaxonomy === "snomed"}
                        onChange={this.onChooseTaxonomy}
                    />
                    <label htmlFor="snomed">&nbsp; SNOMED</label>
                    <br />
                    <input
                        type="radio"
                        id="umls"
                        name={`taxonomy-${this.props.paneId}`}
                        value="umls"
                        checked={this.state.selectedTaxonomy === "umls"}
                        onChange={this.onChooseTaxonomy}
                    />
                    <label htmlFor="umls">&nbsp; UMLS</label>
                </div>
            )
            // Checkbox that triggers "findBestMatch" boolean
            toggleFindBestMatch = (
                <div>
                    <input
                        type="checkbox"
                        id={`find_best_match-${this.props.paneId}`}
                        checked={this.state.findBestMatch}
                        onChange={this.onToggleFindBestMatch}
                    />
                    <label htmlFor={`find_best_match-${this.props.paneId}`}> &nbsp; Match Substring</label>
                </div>
            );

        }

        let loadingWidget = (<span></span>);
        if (this.state.loading) {
            loadingWidget = (<i className="fa fa-spinner fa-spin"></i>);
        }

        let savingLoadingWidget = (<span></span>);
        if (this.state.saving_annotations_loading) {
            savingLoadingWidget = (<i className="fa fa-spinner fa-spin"></i>);
        }

        return (
            <div className="narrative-pane my-3">
                <div className="container">
                    {/* <h1>Health Concept Extractor Tool</h1>
                    <hr/> */}

                    {alertInvalid}
                    {alertError}
                    {alertNoResults}

                    {/* Access Key is managed by parent, no need to include here */}

                    <h3 htmlFor="cpt_code_input" style={{marginBottom: 4}}>Clinical Narrative &nbsp; {loadingWidget}</h3>
                    <div className="row">
                        <div className="col-10">
                            <textarea
                                id={`cpt_code_input-${this.props.paneId}`}
                                style={{ width: "100%" }}
                                rows="10"
                                ref={this.ref}
                                onChange={this.handleTextareaChange}
                                value={this.state.input_text}
                                readOnly={this.state.readOnly}
                            />
                        </div>
                        {!this.state.readOnly && (
                            <div className="col-2">
                                <div className="form-check mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`use-ner-${this.props.paneId}`}
                                        checked={this.state.useNER}
                                        onChange={this.toggleUseNER}
                                    />
                                    <label className="form-check-label" htmlFor={`use-ner-${this.props.paneId}`}>
                                        NER
                                    </label>
                                </div>
                                {searchButton}
                                <br/><br/>
                                {chooseForMe}
                            </div>
                        )}
                    </div>
                    <hr/>

                    <div>
                        <h3 style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            Interactive Text
                            <button 
                                className="btn btn-sm btn-outline-secondary" 
                                onClick={this.toggleInteractiveText}
                                style={{ padding: '0px 8px' }}
                            >
                                {this.state.isInteractiveTextExpanded ? '−' : '+'}
                            </button>
                        </h3>
                        <div style={{ 
                            display: this.state.isInteractiveTextExpanded ? 'block' : 'none',
                            whiteSpace: 'pre-wrap', 
                            border: '1px solid #ccc', 
                            padding: '10px', 
                            borderRadius: '5px' 
                        }}>
                            {this.createInteractiveTextDisplay()}
                        </div>

                        <div>
                            <p>{this.state.timeTaken}</p>
                            <p>{this.state.entityCount}</p>
                        </div>
                    </div>

                    <div style={{marginTop: 10}}>
                        <button
                            id={`downloadBtn-${this.props.paneId}`}
                            style={{display: this.state.showDownloadButton ? 'block' : 'none'}}
                            className="btn btn-info"
                            onClick={this.downloadCSV}>Export Synonyms to CSV</button>
                    </div>

                    <hr/>

                    <h3>Entity Phrases by Label</h3> 

                    <div className="row">
                        {(() => {
                            // Get unique entity types present in the current document
                            const entityTypes = new Set();
                            Object.values(this.state.interactive_index_map).forEach(value => {
                                if (value.label) {
                                    entityTypes.add(value.label.toLowerCase());
                                }
                            });

                            // Convert to array and sort alphabetically
                            const sortedEntityTypes = Array.from(entityTypes).sort();

                            // Split into three columns
                            const columns = [[], [], []];
                            sortedEntityTypes.forEach((entityType, index) => {
                                columns[index % 3].push(entityType);
                            });

                            return columns.map((columnTypes, colIndex) => (
                                <div className="col-4" key={`col-${colIndex}`}>
                                    {columnTypes.map(entityType => {
                                        const capitalizedType = entityType.charAt(0).toUpperCase() + entityType.slice(1);
                                        return (
                                            <div key={entityType}>
                                                <div style={{display: 'flex', alignItems: 'center', gap: '8px', marginTop: "10px"}}>
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.entityVisibility[entityType]}
                                                        onChange={() => this.toggleEntityVisibility(entityType)}
                                                    />
                                                    <h5 style={{textDecoration: "underline", fontWeight: "bold", margin: 0}}>{capitalizedType}</h5>
                                                </div>
                                                <hr/>
                                                <div className="label-table">
                                                    {(() => {
                                                        if (!this.state.entityVisibility[entityType]) return null;
                                                        const added = new Set();
                                                        return Object.entries(this.state.interactive_index_map).map(([key, value], index) => {
                                                            if (value.label.toLowerCase() !== entityType) {
                                                                return null;
                                                            }

                                                            let start = key;
                                                            let original_text = this.state.input_text.substring(start, value.end);
                                                            if (!this.state.dropdown_phrases.hasOwnProperty(original_text)) {
                                                                this.state.dropdown_phrases[original_text] = original_text;
                                                            }

                                                            if (!added.has(this.state.dropdown_phrases[original_text].toLowerCase())) {
                                                                added.add(this.state.dropdown_phrases[original_text].toLowerCase());
                                                                let color = "#212529"; // Default color
                                                                let phrase_codes = value.codes;

                                                                if (this.state.dropdown_colorMap.hasOwnProperty(original_text)) {
                                                                    color = this.state.dropdown_colorMap[original_text];
                                                                }

                                                                return (
                                                                    <div key={`${entityType}-${index}`}>
                                                                        <DropdownComponent
                                                                            text={this.state.dropdown_phrases[original_text]}
                                                                            codes={phrase_codes}
                                                                            color={color}
                                                                            index={parseInt(key)}
                                                                            negative={value.negative}
                                                                            callback={this.addEntryToTable}
                                                                            original_text={original_text}
                                                                            removeCallback={this.removeNerIndex}
                                                                            stat_pearls_url={value.stat_pearls_url}
                                                                            stat_pearls_name={value.stat_pearls_name}
                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                            return null;
                                                        });
                                                    })()}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ));
                        })()}
                    </div>

                    <hr/>

                    <h3>Selected Codes</h3>
                    <div className="table" id={`table-sortable-${this.props.paneId}`}>
                        <SmartDataTable
                            name={this.state.table.title}
                            orderedHeaders={this.state.table.headers}
                            headers={this.state.table.headerMap}
                            data={this.state.table.data}
                            perPage={this.state.table.perPageItemCount}
                            sortable={true}
                        />
                    </div>
                    <button className="btn btn-info" onClick={this.saveAnnotations}>Save Annotations {savingLoadingWidget}</button>
                    <button className="btn btn-success" onClick={this.viewAnnotations} style={{marginLeft: 5}}>View Annotations</button>
                </div>
            </div>
        );
    }
}

export default NarrativePane;
