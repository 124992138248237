import React from "react";
// import './react-smart-data-table.css';
import './semantic-ui-react-table.css'
import SmartDataTable from 'react-smart-data-table';
import $ from "jquery";
import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
// import all_vocab_codes from "./json/all_vocab_phrase_data.json";
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import packageJson from '../package.json';
import {FilterComponent} from "./filters";
import { Pagination } from 'semantic-ui-react';
import CopyIcon from './CopyIcon';
import { Modal, Button, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';


class CustomPaginator extends React.Component {
    render() {
        const { activePage, totalPages, onPageChange } = this.props;
        return (
            <Pagination
                activePage={activePage}
                totalPages={totalPages}
                onPageChange={onPageChange}
            />
        );
    }
}


export class Vocab extends React.Component {

    constructor(props) {
        super(props);

        this.ref = React.createRef();

        this.state = {
            // select_API: "Development",
            select_API_host: packageJson.pythonEnv.HOST_API,
            phraseLabel: "",
            table: {
                title: "Vocab Results",
                // headers: ["phrase", "id", "vocab", "distance", "tf-idf", "average", "search"],
                // Exclude the distances from the headers
                headers: ["phrase", "label", "id", "similarity", "search"],
                headerMap: {
                    'phrase': {
                        text: "Phrase",
                        transform: (value, index, row) => (<span dangerouslySetInnerHTML={{__html: value}}></span>),
                        sortable: false
                        },
                    'label': {
                        text: "Label",
                        sortable: false
                    },
                    // 'distance': {text: "Semantic Distance", sortable: true},
                    'id': {
                        text: "Code",
                        transform: (value, index, row) => <CopyIcon textToCopy={value} />,
                        sortable: false
                    },
                    'similarity': {text: "Similarity", sortable: true},
                    'search': {
                        text: "Search",
                        transform: (value, index, row) => (<button className="btn btn-info" onClick={() => this.knnResultSearch(value)}>
                                <FontAwesomeIcon icon={faSearch} />
                            </button>
                        ),
                        sortable: false
                    },
                    'crosswalk': {
                        text: "Crosswalk",
                        transform: (value, index, row) => (
                            <button className="btn btn-secondary" onClick={() => this.onCrosswalkClicked(row)}>
                                <FontAwesomeIcon icon={faExpandArrowsAlt} />
                            </button>
                        ),
                        sortable: false
                    }
                    // "tf-idf": {text: "TF-IDF Distance", sortable: true},
                    // "average": {text: "Semantic TF-IDF Distance", sortable: true}
                },
                perPageItemCount: 10,
                pagination: true,
                data: [],
                fullData: [], // Store all data here
            },
            activePage: 1,

            typeahead_options: [],
            filters: {
                cpt: false,
                icd10cm: true,
                snomed: false,
                riak: false,
                rxnorm: false,
                nucc: false,
                umls: false,
            },
            typeahead_min_length: 1000,
            alert_invalid: false,
            alert_error: false,
            alert_message: "",
            alert_no_results: false,
            loading: false,
            result_data: [],
            use_autocomplete: false,
            selected_ordering: "semantic",
            use_highlighting: false,
            shared_filters: {},
            applied_filters: {},
            // umls: false,
            accessKey: localStorage.getItem('accessKey') || '',
            isAccessKeyInputVisible: false,
            findBestMatch: true,
            // Crosswalk modal vars
            showCrosswalkModal: false,
            crossWalkStartTime: 0,
            crossWalkProcessingTime: 0,
            modalCode: '',
            modalVocab: '',
            modalData: [],
        };

        this.typeaheadDebounce = AwesomeDebouncePromise(
            (query) => {
                this.setState({typeahead_min_length: query.length});
            },
            1000
        );

        this.httpRequest = this.httpRequest.bind(this);
        // this.onChooseAPI = this.onChooseAPI.bind(this);
        this.onPhraseChange = this.onPhraseChange.bind(this);
        this.updateTable = this.updateTable.bind(this);
        this.onVocabChange = this.onVocabChange.bind(this);
        this.onCptChange = this.onCptChange.bind(this);
        this.onIcdCmChange = this.onIcdCmChange.bind(this);
        // this.onIcdPcsChange = this.onIcdPcsChange.bind(this);
        this.onSnomedChange = this.onSnomedChange.bind(this);
        // this.onSnomedCentroidsChange = this.onSnomedCentroidsChange.bind(this);
        this.onRiakChange = this.onRiakChange.bind(this);
        this.onRxnormChange = this.onRxnormChange.bind(this);
        this.onNuccChange = this.onNuccChange.bind(this);
        this.onUmlsChange = this.onUmlsChange.bind(this);
        this.knnResultSearch = this.knnResultSearch.bind(this);
        this.toggleAutocomplete = this.toggleAutocomplete.bind(this);
        this.resultOrderingChange = this.resultOrderingChange.bind(this);
        this.toggleHighlighting = this.toggleHighlighting.bind(this);
        this.filterCallback = this.filterCallback.bind(this);
        this.shouldUseAppliedFilters = this.shouldUseAppliedFilters.bind(this);
        this.onToggleFindBestMatch = this.onToggleFindBestMatch.bind(this);
        this.handleAccessKeyChange = this.handleAccessKeyChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.updateDataForPage = this.updateDataForPage.bind(this);
        this.onCrosswalkClicked = this.onCrosswalkClicked.bind(this);
        this.handleCloseCrosswalkModal = this.handleCloseCrosswalkModal.bind(this);
    }

    // // Disabled by Skim 2023-08-11 - Only use new model API going forward
    // onChooseAPI(e) {
    //     this.setState({select_API: e.target.value});
    //     if (e.target.value == "Production") {
    //         this.setState({select_API_host: packageJson.pythonEnv.HOST_API1, umls: false}, this.onPhraseChange);
            
    //     } else if (e.target.value == "Development")  {
    //         this.setState({select_API_host: packageJson.pythonEnv.HOST_API2, umls: false}, this.onPhraseChange);
    //     }
    // }

    handleAccessKeyChange(event) {
        const accessKey = event.target.value;
        this.setState({ accessKey });
        localStorage.setItem('accessKey', accessKey);  // Save to localStorage
    }

    toggleAccessKeyInputVisibility = () => {
        this.setState(prevState => ({isAccessKeyInputVisible: !prevState.isAccessKeyInputVisible}));
    }

    shouldUseAppliedFilters() {
        let af = this.state.applied_filters;
        for (const i of Object.keys(af)) {
            for (const j of Object.keys(af[i])) {
                if (af[i][j]) {
                    return true;
                }
            }
        }
        return false;
    }

    filterCallback (event, category, label) {
        let af = this.state.applied_filters;
        af[category][label] = event.target.checked;

        this.setState({
            applied_filters: af
        });

        this.onVocabChange();
    }

    toggleHighlighting (event) {
        this.setState({use_highlighting: event.target.checked});
    }

    toggleAutocomplete(event) {
        this.setState({use_autocomplete: event.target.checked});
    }

    resultOrderingChange(event) {
        this.setState({selected_ordering: event.target.value});
    }

    knnResultSearch(value) {
        this.ref.current.getInput().value = value;
        this.ref.current.setState({initialItem: value, text: value});
        this.onPhraseChange();
    }

    httpRequest(method, url, params, callback) {
        let accessKey = document.getElementById("accessKey").value;

        let xmlHttp = new XMLHttpRequest();
        let ctrl = this;

        ctrl.setState({
            alert_message: "",
        })
        
        xmlHttp.onreadystatechange = function () {
            if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
                ctrl.setState({loading: false});
                callback(JSON.parse(xmlHttp.response));
            } else if (xmlHttp.readyState === 4) {
                if (xmlHttp.status === 400) {
                    ctrl.setState({
                        alert_invalid: false,
                        alert_error: false,
                        alert_no_results: true
                    });
                } else {
                    const response = JSON.parse(xmlHttp.response);
                    const errorMessage = response.error || 'Unknown error occurred';
                    ctrl.setState({
                        alert_invalid: false,
                        alert_error: true,
                        alert_no_results: false,
                        alert_message: errorMessage
                    });
                }
            
                ctrl.setState({loading: false, saving_annotations_loading: false});
            }
        };
        
        xmlHttp.open(method, url, true);
        
        if (accessKey) {
            xmlHttp.setRequestHeader("Access-Key", accessKey);
        }
    
        if (method === "POST") {
            xmlHttp.setRequestHeader("Content-Type", "application/json");
            xmlHttp.send(JSON.stringify(params));
        } else {
            xmlHttp.send();
        }
    }

    componentDidMount() {

        this.onVocabChange();

        const urlParams = new URLSearchParams(window.location.search);
        let cpt = urlParams.get("cpt");
        if (cpt) {
            this.ref.current.getInput().value = cpt;
            this.onPhraseChange();
        }

        $('[data-toggle="tooltip"]').tooltip();

    }

    onVocabChange() {

        // let ctrl = this;
        // let f = function (val) {
        //     return ctrl.state.filters[val["vocab"]] && val["phrase"];
        // };

        // let updatedList = all_vocab_codes.filter(f).map((val) => val["phrase"]);

        // this.setState({
        //     typeahead_options: updatedList
        // });

        if (this.state.result_data.length > 0) {
            this.updateTable(this.state.result_data);
        }
    }

    onCptChange(event) {
        let filters = this.state.filters;
        filters.cpt = event.target.checked;
        this.setState({filters: filters});

        this.onVocabChange();
    }

    onIcdCmChange(event) {
        let filters = this.state.filters;
        filters.icd10cm = event.target.checked;
        this.setState({filters: filters});

        this.onVocabChange();
    }

    // onIcdPcsChange(event) {
    //     let filters = this.state.filters;
    //     filters.icd10pcs = event.target.checked;
    //     this.setState({filters: filters});
        
    //     this.onVocabChange();
    // }

    // onIcdPcsChange(event) {
    //     let filters = this.state.filters;
    //     filters.icd10pcs = event.target.checked;
    //     this.setState({filters: filters});

    //     this.onVocabChange();
    // }

    onSnomedChange(event) {
        let filters = this.state.filters;
        filters.snomed = event.target.checked;
        this.setState({filters: filters});

        this.onVocabChange();
    }

    // onSnomedCentroidsChange(event) {
    //     let filters = this.state.filters;
    //     filters.snomed_centroids = event.target.checked;
    //     this.setState({filters: filters});
    
    //     this.onVocabChange();
    // }

    onRiakChange(event) {
        let filters = this.state.filters;
        filters.riak = event.target.checked;
        this.setState({filters: filters});

        this.onVocabChange();
    }
    
    onRxnormChange(event) {
        let filters = this.state.filters;
        filters.rxnorm = event.target.checked;
        this.setState({filters: filters});

        this.onVocabChange();
    }

    onNuccChange(event) {
        let filters = this.state.filters;
        filters.nucc = event.target.checked;
        this.setState({filters: filters});

        this.onVocabChange();
    }

    onUmlsChange(event) {
        let filters = this.state.filters;
        filters.umls = event.target.checked;
        this.setState({filters: filters});

        this.onVocabChange();
    }

    onToggleFindBestMatch() {
        this.setState({ findBestMatch: !this.state.findBestMatch }, this.onPhraseChange);
    }

    onPhraseChange() {
        // Reset the time taken label
        document.getElementById("time_taken").innerHTML = "";

        let params = {
            "phrase": this.ref.current.getInput().value,
            // "filters": this.state.umls ? {"umls": true} : this.state.filters,
            "filters": this.state.filters,
            "max_neighbors": 140,
            "find_best_match": this.state.findBestMatch,
        };

        // // Make sure to show UMLS results if the UMLS filter is selected
        // if (this.state.umls) {
        //     this.state.filters["umls"] = true;
        // } else {
        //     this.state.filters["umls"] = false;
        // }

        /*if (this.state.typeahead_options.indexOf(params["phrase"]) === -1) {
            this.setState({
                alert_invalid: true,
                alert_error: false,
                alert_no_results: false
            });
            return;
        } else {*/
            this.setState({
                alert_invalid: false,
                alert_error: false,
                alert_no_results: false
            });
        //}

        this.setState({loading: true});

        this.selectedNode = params["phrase"];

        let ctrl = this;
        // Record the time taken
        const startTime = new Date().getTime();
        this.httpRequest("POST", this.state.select_API_host + "/1.0/journey/query/phrase", params, function (response) {
            const endTime = new Date().getTime();

            if (response && Object.keys(response).length >= 1) {
                const searchPhrase = Object.keys(response)[0];
                const main = response[searchPhrase];
                
                let applied_filters = {};
                for (const [key, value] of Object.entries(main.shared_filters)) {
                    applied_filters[key] = {};
                    for (const label of value) {
                        if (main.selected_filters[key] && main.selected_filters[key].includes(label)) {
                            applied_filters[key][label] = true;
                        } else {
                            applied_filters[key][label] = false;
                        }
                    }
                }

                ctrl.setState({
                    shared_filters: main.shared_filters,
                    applied_filters: applied_filters,
                    phraseLabel: main.label || "" // Set the phrase label from the response
                });

                ctrl.updateTable(main.results);

                // Update the #time_taken
                document.getElementById("time_taken").innerHTML = "Search time: " + ((endTime - startTime)/1000).toFixed(2) + " seconds";
            } else {
                ctrl.setState({
                    alert_invalid: false,
                    alert_error: true,
                    alert_no_results: false
                });
            }
        });

    }

    onCrosswalkClicked(row) {
        const { id, vocab, search } = row;

        // console.log(row);
    
        const params = {
            code: id,
            vocab: vocab,
            phrase: search,
        };

        // console.log(params);
    
        this.setState({ loading: true, crossWalkStartTime: Date.now() });
    
        let ctrl = this;
        this.httpRequest("POST", this.state.select_API_host + "/1.0/crosswalk", params, function (response) {
            const elapsedTime = Date.now() - ctrl.state.crossWalkStartTime;

            ctrl.setState({ 
                loading: false, 
                crossWalkProcessingTime: elapsedTime, 
                modalData: Object.keys(response).map(key => {
                    const item = response[key][0]; // Assuming response[key] is an array with at least one object
                    return {
                        vocab: key,
                        ...item,
                        similarity: ((1 - parseFloat(item.distance)) * 100).toFixed(2)
                    };
                }),
                showCrosswalkModal: true,
                modalCode: id,
                modalVocab: vocab,
            });
        });
    }

    handleCloseCrosswalkModal() {
        this.setState({ showCrosswalkModal: false });
    }
    
    renderCrosswalkModal() {
        return (
            <Modal className="crosswalk-modal" show={this.state.showCrosswalkModal} onHide={this.handleCloseCrosswalkModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Crosswalk Results</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Code: {this.state.modalCode}</h5>
                    <h5>Vocab: {this.state.modalVocab}</h5>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Vocab</th>
                                <th>ID</th>
                                <th>Phrase</th>
                                <th>HCC</th>
                                <th>Specific</th>
                                <th>Similarity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.modalData
                                .filter(item => parseFloat(item.similarity) > 0)
                                .map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.vocab}</td>
                                        <td>{item.id}</td>
                                        <td>{item.phrase}</td>
                                        <td>{item.hcc}</td>
                                        <td>{item.specific}</td>
                                        <td>{item.similarity}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                    <div>
                        <strong>Processing Time:</strong> {this.state.crossWalkProcessingTime / 1000} seconds
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleCloseCrosswalkModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
    

    onPageChange(e, { activePage }) {
        this.setState({ activePage }, () => this.updateDataForPage());
    }

    updateDataForPage() {
        const { fullData, perPageItemCount } = this.state.table;
        const { activePage } = this.state;
        const start = (activePage - 1) * perPageItemCount;
        const end = start + perPageItemCount;
        const paginatedData = fullData.slice(start, end);

        // Update table data with paginated data
        this.setState(prevState => ({
            table: {
                ...prevState.table,
                data: paginatedData,
            },
        }));
    }

    updateTable(results) {

        let data = [];
        let input = this.ref.current.getInput().value.split(" ");
        let skipWords = ["with", "of", "in", "by", "the", "a", "an"];
        let use_filters = this.shouldUseAppliedFilters();
        for (let i = 0; i < results.length; i++) {
            if (this.state.filters[results[i].vocab]) {

                if (use_filters) {
                    if (!results[i].filters) {
                        continue;
                    } else {
                        let skip = false;
                        for (const category of Object.keys(this.state.applied_filters)) {
                            for (const label of Object.keys(this.state.applied_filters[category])) {
                                if (this.state.applied_filters[category][label]) {
                                    if (results[i].filters[category]) {
                                        if (!results[i].filters[category].includes(label)) {
                                            skip = true;
                                            break;
                                        }
                                    } else {
                                        skip = true;
                                        break;
                                    }
                                }
                            }
                            if (skip) {
                                break;
                            }
                        }
                        if (skip) {
                            continue;
                        }
                    }
                }

                let phrase = "";
                if (this.state.use_highlighting) {
                    let output = results[i].phrase.split(" ");
                    for (let j = 0; j < output.length; j++) {
                        let match = false;
                        if (skipWords.indexOf(output[j]) === -1) {
                            for (let k = 0; k < input.length; k++) {
                                if (output[j].toLowerCase() === input[k].toLowerCase()) {
                                    match = true;
                                    break;
                                }
                            }
                        }
                        if (match) {
                            phrase += `<span class='highlight'>${output[j]}</span> `
                        } else {
                            phrase += output[j] + " "
                        }
                    }
                } else {
                    phrase = results[i].phrase;
                }

                // If results[i].specific == "Y" then set the phrase color to green
                if (results[i].specific === "Y") {
                    phrase = `<span style="color: green;">${phrase}</span>`
                } else {
                    // Else set it to red
                    phrase = `<span style="color: red;">${phrase}</span>`
                }

                let semantic = results[i].distance.toFixed(5);
                let tf = results[i]["tf-idf"].toFixed(5);
                let averaged = (results[i].distance * results[i]["tf-idf"]).toFixed(5);

                data.push({
                    "phrase": phrase,
                    "id": results[i].id,
                    //"id": results[i].source,
                    "vocab": results[i].vocab,
                    "label": results[i].label,
                    "distance": parseFloat(semantic),
                    "similarity": parseFloat(((1 - results[i].distance) * 100).toFixed(2)),
                    "search": results[i].phrase,
                    "crosswalk": true  // Placeholder, the actual content is rendered via the transform in headerMap
                    // "tf-idf": parseFloat(tf),
                    // "average": parseFloat(averaged)
                });
            }
        }

        if (this.state.selected_ordering === "semantic") {
            // Sort by similarity in descending order (higher similarity first)
            data.sort((a, b) => (a.similarity < b.similarity) ? 1 : -1);
        } else if (this.state.selected_ordering === "tf-idf") {
            data.sort((a, b) => (a["tf-idf"] > b["tf-idf"]) ? 1 : -1);
        } else {
            data.sort((a, b) => (a.average > b.average) ? 1 : -1);
        }

        // For each datum in data remove the "distance" key - this will hide the column
        for (let i = 0; i < data.length; i++) {
            delete data[i].distance;
        }

        // **Limit the data to the first 10 items**
        data = data.slice(0, 10);

        let fullData = data;
        
        let table = this.state.table;
        table.data = data;
        this.setState({
            table: {
                ...this.state.table,
                fullData, // Store all data
            },
            activePage: 1, // Reset to the first page
            result_data: results,
        }, () => this.updateDataForPage());
    }

    render() {
        const totalPages = Math.ceil(this.state.table.fullData.length / this.state.table.perPageItemCount);

        let alertInvalid = (<span></span>);
        if (this.state.alert_invalid) {
            alertInvalid = (
                <div className="alert alert-warning alert-dismissible">
                    <strong>Warning!</strong> Phrase is invalid! Please check again.
                    <button type="button" className="close" onClick={() => this.setState({alert_invalid: false})}>&times;</button>
                </div>
            );
        }

        let alertError = (<span></span>);
        if (this.state.alert_error) {
            alertError = (
                <div className="alert alert-danger alert-dismissible">
                    <strong>Error!</strong> {this.state.alert_message}
                    <button type="button" className="close" onClick={() => this.setState({alert_error: false})}>&times;</button>
                </div>
            );
        }

        let alertNoResults = (<span></span>);
        if (this.state.alert_no_results) {
            alertError = (
                <div className="alert alert-warning alert-dismissible">
                    <strong>Warning!</strong> There are no phrases found for this input.
                    <button type="button" className="close" onClick={() => this.setState({alert_no_results: false})}>&times;</button>
                </div>
            );
        }

        let enterAccessKey = (
            <div className="form-group">
                <label htmlFor="accessKey">
                    <h5>
                        Access Key &nbsp;
                        <button onClick={this.toggleAccessKeyInputVisibility}>
                            {this.state.isAccessKeyInputVisible ? '-' : '+'}
                        </button>
                    </h5>
                </label>
                <input 
                    type="password"
                    className="form-control" 
                    id="accessKey" 
                    placeholder="Enter Access Key" 
                    value={this.state.accessKey}
                    onChange={this.handleAccessKeyChange}
                    style={{ 
                        width: "300px",
                        display: this.state.isAccessKeyInputVisible ? 'block' : 'none',
                        // visibility: this.state.isAccessKeyInputVisible ? 'visible' : 'hidden'
                    }} 
                />
            </div>
        );        

        // let chooseAPI;
        // if (this.state.loading) {
        //     chooseAPI = (
        //         <select disabled>
        //             <option value="Production">Production</option>
        //             <option value="Development">Development</option>
        //         </select>
        //     );
        // } else {
        //     chooseAPI = (
        //         <select value={this.state.select_API} onChange={this.onChooseAPI}>
        //                 <option value="Production">Production</option>
        //                 <option value="Development">Development</option>
        //         </select>
        //     );
        // }

        let searchButton;
        if (this.state.loading) {
            searchButton = (
                <button type="button" className="btn btn-primary" disabled><i className="fa fa-search"></i></button>
            );
        } else {
            searchButton = (
                <button type="button" className="btn btn-primary" onClick={this.onPhraseChange}><i className="fa fa-search"></i></button>
            );
        }

        let toggleFindBestMatch;
        if (this.state.loading) {
            toggleFindBestMatch = (
                <div>
                    <input
                        type="checkbox"
                        id="find_best_match"
                        checked={this.state.findBestMatch}
                        onChange={this.onToggleFindBestMatch}
                        disabled
                    />
                    <label htmlFor="find_best_match">&nbsp; Show Alternative Name</label>
                </div>
            );
        } else {
            toggleFindBestMatch = (
                <div>
                    <input
                        type="checkbox"
                        id="find_best_match"
                        checked={this.state.findBestMatch}
                        onChange={this.onToggleFindBestMatch}
                    />
                    <label htmlFor="find_best_match">&nbsp; Show Alternative Name</label>
                </div>
            );
        }


        let loadingWidget = (<span></span>);
        if (this.state.loading) {
            loadingWidget = (<i className="fa fa-spinner fa-spin"></i>);
        }

        let searchBar;
        if (this.state.use_autocomplete) {
            searchBar = (
                <Typeahead id="cpt_code_input"
                    ref={this.ref}
                    options={this.state.typeahead_options}
                    minLength={this.state.typeahead_min_length}
                    onInputChange={(query) => {
                        if (this.state.typeahead_min_length !== 1000) {
                            this.setState({typeahead_min_length: 1000});
                        }
                        this.typeaheadDebounce(query);
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            this.onPhraseChange(event.target.value);
                        }
                    }}
                />
            );
        } else {
            searchBar = (
                <Typeahead id="cpt_code_input" ref={this.ref} options={[]} minLength={1000} onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        this.onPhraseChange(event.target.value);
                    }
                }}/>
            );
        }

        return (
            <div className="container my-3">

                {this.renderCrosswalkModal()}

                <h1>Semantic Search</h1>

                {/* ADDED BY SKIMAI: CHOOSE API SERVER */}
                {/* <h3>Choose API</h3>
                <div className="row" style={{paddingLeft: 15}}>
                    {chooseAPI}
                </div>
                <br/> */}
                <div className="row" style={{paddingLeft: 15}}>
                    {enterAccessKey}
                </div>
                <br/>

                {alertInvalid}
                {alertError}
                {alertNoResults}

                <h5 htmlFor="cpt_code_input" style={{marginBottom: 4}}>Phrase:&nbsp; {loadingWidget}</h5>
                <div className="row">
                    <div className="col-6">
                        {searchBar}
                    </div>
                    <div className="col-1">
                        {searchButton}
                    </div>
                    <div className="col-5" style={{marginTop: "-25px", zIndex: "9998"}}>
                        <div>
                            <h5>Vocabularies</h5>
                            <div className="m-3">
                                <label>
                                    <input 
                                        type="radio" 
                                        name="vocab" 
                                        checked={this.state.filters.cpt} 
                                        onChange={() => this.setState({
                                            filters: {
                                                cpt: true,
                                                icd10cm: false,
                                                snomed: false,
                                                nucc: false,
                                                rxnorm: false,
                                                umls: false,
                                                riak: false
                                            }
                                        }, () => {
                                            this.onVocabChange();
                                            this.onPhraseChange();
                                        })}
                                    /> CPT
                                </label><br/>
                                <label>
                                    <input 
                                        type="radio" 
                                        name="vocab" 
                                        checked={this.state.filters.snomed} 
                                        onChange={() => this.setState({
                                            filters: {
                                                cpt: false,
                                                icd10cm: false,
                                                snomed: true,
                                                nucc: false,
                                                rxnorm: false,
                                                umls: false,
                                                riak: false
                                            }
                                        }, () => {
                                            this.onVocabChange();
                                            this.onPhraseChange();
                                        })}
                                    /> SNOMED
                                </label><br/>
                                <label>
                                    <input 
                                        type="radio" 
                                        name="vocab" 
                                        checked={this.state.filters.icd10cm} 
                                        onChange={() => this.setState({
                                            filters: {
                                                cpt: false,
                                                icd10cm: true,
                                                snomed: false,
                                                nucc: false,
                                                rxnorm: false,
                                                umls: false,
                                                riak: false
                                            }
                                        }, () => {
                                            this.onVocabChange();
                                            this.onPhraseChange();
                                        })}
                                    /> ICD10CM
                                </label><br/>
                                <label>
                                    <input 
                                        type="radio" 
                                        name="vocab" 
                                        checked={this.state.filters.nucc} 
                                        onChange={() => this.setState({
                                            filters: {
                                                cpt: false,
                                                icd10cm: false,
                                                snomed: false,
                                                nucc: true,
                                                rxnorm: false,
                                                umls: false,
                                                riak: false
                                            }
                                        }, () => {
                                            this.onVocabChange();
                                            this.onPhraseChange();
                                        })}
                                    /> NUCC
                                </label><br/>
                                <label>
                                    <input 
                                        type="radio" 
                                        name="vocab" 
                                        checked={this.state.filters.rxnorm} 
                                        onChange={() => this.setState({
                                            filters: {
                                                cpt: false,
                                                icd10cm: false,
                                                snomed: false,
                                                nucc: false,
                                                rxnorm: true,
                                                umls: false,
                                                riak: false
                                            }
                                        }, () => {
                                            this.onVocabChange();
                                            this.onPhraseChange();
                                        })}
                                    /> RXNORM
                                </label><br/>
                                <label>
                                    <input 
                                        type="radio" 
                                        name="vocab" 
                                        checked={this.state.filters.umls} 
                                        onChange={() => this.setState({
                                            filters: {
                                                cpt: false,
                                                icd10cm: false,
                                                snomed: false,
                                                nucc: false,
                                                rxnorm: false,
                                                umls: true,
                                                riak: false
                                            }
                                        }, () => {
                                            this.onVocabChange();
                                            this.onPhraseChange();
                                        })}
                                    /> UMLS
                                </label>
                            </div>
                        </div>
                        <br/>
                        {toggleFindBestMatch}
                    </div>
                </div>

                <div className="vocab_selection" style={{marginTop: "-175px"}}>
                    <div className="row">
                        <div className="col">Advanced Search Options <a className="card-link" data-toggle="collapse" href="#collapseOptions"><i className="fa fa-chevron-down"></i></a></div>
                    </div>

                    <div id="collapseOptions" className="collapse">
                        <div className="card pl-3">
                            {/* {!this.state.umls && (
                            // <div>
                            //     <h5>Vocabularies</h5>
                            //     <div className="m-3">
                            //     <label>CPT <input type="checkbox" checked={this.state.filters.cpt} onChange={this.onCptChange}/></label><br/>
                            //     <label>SNOMED <input type="checkbox" checked={this.state.filters.snomed} onChange={this.onSnomedChange}/></label><br/>
                            //     <label>ICD10CM <input type="checkbox" checked={this.state.filters.icd10cm} onChange={this.onIcdCmChange}/></label><br/>
                            //     <label>ICD10PCS <input type="checkbox" checked={this.state.filters.icd10pcs} onChange={this.onIcdPcsChange}/></label><br/>
                            //     <label>NUCC <input type="checkbox" checked={this.state.filters.nucc} onChange={this.onNuccChange}/></label><br/>
                            //     <label>RXNORM <input type="checkbox" checked={this.state.filters.rxnorm} onChange={this.onRxnormChange}/></label><br/>
                            //     <label>UMLS <input type="checkbox" checked={this.state.filters.umls} onChange={this.onUmlsChange}/></label>
                            //     </div>
                            // </div>
                            )} */}
                            
                            {/* <h5>Autocomplete in Search Bar</h5>
                            <div className="m-3">
                                <label>Enabled <input type="checkbox" checked={this.state.use_autocomplete} onChange={this.toggleAutocomplete}/></label>
                            </div> */}
                            
                            {/* <h5>Result Ordering</h5>
                            <div className="m-3 onChange={this.resultOrderingChange}>
                                <label>Semantic Only <input type="radio" name="ordering" value="semantic" defaultChecked={this.state.selected_ordering === "semantic"}/></label><br/>
                                <label>TF-IDF Only <input type="radio" name="ordering" value="tf-idf" defaultChecked={this.state.selected_ordering === "tf-idf"}/></label><br/>
                                <label>Semantic TF-IDF <input type="radio" name="ordering" value="average" defaultChecked={this.state.selected_ordering === "average"}/></label>
                            </div> */}
                            <h5>Syntax Highlighting</h5>
                            <div className="m-3">
                                <label>Enabled <input type="checkbox" checked={this.state.use_highlighting} onChange={this.toggleHighlighting}/></label>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <FilterComponent filters={this.state.shared_filters} selected_filters={this.state.applied_filters} callback={this.filterCallback}/> */}
                <br/><br/>

                <div style={{marginTop: "60px"}}>
                    <h5>Results</h5>
                    {this.state.phraseLabel && (
                        <h6 style={{marginBottom: "20px"}}>Phrase Label: <span style={{fontWeight: "normal"}}>{this.state.phraseLabel}</span></h6>
                    )}
                    <div className="table" id="table-sortable">
                        <SmartDataTable
                            name={this.state.table.title}
                            orderedHeaders={this.state.table.headers}
                            headers={this.state.table.headerMap}
                            data={this.state.table.data}
                            perPage={this.state.table.perPageItemCount}
                            sortable={true}
                            paginator={(props) => (
                                <CustomPaginator
                                    {...props}
                                    activePage={this.state.activePage}
                                    totalPages={totalPages}
                                    onPageChange={this.onPageChange}
                                />
                            )}
                        />
                    </div>
                </div>
                <div id="time_taken"></div>
            </div>
        );
    }
}
