// import React from 'react';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
// import { FaCopy } from 'react-icons/fa';
// import Tooltip from '@material-ui/core/Tooltip';

// const CopyIcon = ({ textToCopy }) => {
//     return (
//         <div style={{ display: 'flex', alignItems: 'center' }}>
//             <span style={{ marginRight: '5px' }}>{textToCopy}</span>
//             <CopyToClipboard text={textToCopy}>
//                 <Tooltip title="Copy" placement="top">
//                     <FaCopy style={{ cursor: 'pointer' }} />
//                 </Tooltip>
//             </CopyToClipboard>
//         </div>
//     );
// };

// export default CopyIcon;


// import React from 'react';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
// import { FaCopy } from 'react-icons/fa';
// import Tooltip from '@material-ui/core/Tooltip';

// const CopyIcon = ({ textToCopy }) => {
//     const handleCopy = (text, result) => {
//         if (result) {
//             console.log(`Copied: ${text}`);
//         } else {
//             console.error('Copy failed');
//         }
//     };

//     return (
//         <div style={{ display: 'flex', alignItems: 'center' }}>
//             <span style={{ marginRight: '5px' }}>{textToCopy}</span>
//             <CopyToClipboard text={textToCopy} onCopy={handleCopy}>
//                 <Tooltip title="Copy" placement="top">
//                     <div style={{ display: 'inline-flex', cursor: 'pointer' }}>
//                         <FaCopy />
//                     </div>
//                 </Tooltip>
//             </CopyToClipboard>
//         </div>
//     );
// };

// export default CopyIcon;


import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';
import Tooltip from '@material-ui/core/Tooltip';

const CopyIcon = ({ textToCopy }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <CopyToClipboard text={textToCopy} onCopy={() => console.log(`Copied: ${textToCopy}`)}>
                <Tooltip title="Copy" placement="top">
                    <div style={{ display: 'inline-flex', cursor: 'pointer' }}>
                        <FaCopy />
                    </div>
                </Tooltip>
            </CopyToClipboard>
            <span style={{ marginLeft: '5px' }}>{textToCopy}</span>
        </div>
    );
};

export default CopyIcon;
