// import Rainbow from "rainbowvis.js/rainbowvis";
import React from "react";
import './react-smart-data-table.css';
import SmartDataTable from 'react-smart-data-table';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import packageJson from '../package.json';
import './icd.css';
// import narratives from "./json/sample_narratives.json";
// import * as d3 from "d3";

class DropdownComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            original_codes: props.codes,
            current_page: 0,
            customAnnotation: ""
        };

        this.per_page = 5;

        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
        // this.plot = this.plot.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSaveCustomAnnotation = this.handleSaveCustomAnnotation.bind(this);
    }

    prev() {
        // Disable pagination for now
        // this.setState({
        //     current_page: Math.max(this.state.current_page - 1, 0)
        // });
    }

    next() {
        // Disable pagination for now
        // this.setState({
        //     current_page: Math.min(this.state.current_page + 1, (this.state.original_codes.length + this.per_page - 1) / this.per_page - 1)
        // });
    }

    // plot() {
    //     if (typeof this.props.plotCallback === "function") {
    //         this.props.plotCallback(this.state.original_codes, this.props.text);
    //     }
    // }

    handleInputChange(event) {
        this.setState({customAnnotation: event.target.value});
    }

    handleSaveCustomAnnotation() {
        let customEntry = {
            phrase: this.state.customAnnotation,
            vocab: "Custom",
            id: "Custom"
        };
        this.props.callback(customEntry, this.props.original_text);
    }

    render() {

        let current_codes = [];
        if (this.state.original_codes) {
            current_codes = this.state.original_codes.slice(this.state.current_page * this.per_page, (this.state.current_page + 1) * this.per_page);
        }

        let ctrl = this;

        let decoration = "underline";
        let negative_el = "No";
        if (this.props.negative) {
            negative_el = "Yes";
            decoration = "line-through";
        }

        return (
            <span style={{color: this.props.color, textDecoration: decoration}} className="dropdown">{this.props.text}
                <div className="dropdown-content">
                    <div className="p-1" style={{color: "black"}}>
                        <h5>{this.props.text}</h5>
                        <p>Is Negated? {negative_el}</p>
                        <p>Want more information? <a href={this.props.stat_pearls_url} target="_blank">{this.props.stat_pearls_name}</a></p>
                        {/* <button className="btn btn-info" onClick={this.plot}>View Scatter Plot</button> */}
                    </div>
                    <hr/>
                    <div className="row text-center">
                        <div className="col-4">
                            <button className="btn btn-info" onClick={this.prev}>&lt;</button>
                        </div>
                        <div className="col-4">
                            <button className="btn btn-danger" onClick={() => ctrl.props.removeCallback(ctrl.props.index)}>Remove</button>
                        </div>
                        <div className="col-4">
                            <button className="btn btn-info" onClick={this.next}>&gt;</button>
                        </div>
                    </div>
                    <hr/>
                    {current_codes.map((val) =>
                        <li style={{ color: val.displayColor }}  key={val.source + val.id + val.phrase + Math.random()} onClick={() => ctrl.props.callback(val, ctrl.props.original_text)}>
                            <p><b>Phrase:</b> {val.phrase}</p>
                            <p><b>Id:</b> {val.id} ({val.vocab})</p>
                        </li>
                    )}
                    <div>
                        <input
                            type="text"
                            placeholder="Custom Annotation"
                            value={this.state.customAnnotation}
                            onChange={this.handleInputChange}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    this.handleSaveCustomAnnotation();
                                }
                            }}
                        />
                        <button onClick={this.handleSaveCustomAnnotation}>Save</button>
                    </div>

                </div>
            </span>
        );
    }
}

export class Icd extends React.Component {

    constructor(props) {
        super(props);

        this.ref = React.createRef();

        this.state = {
            // select_API: "Development",
            select_API_host: packageJson.pythonEnv.HOST_API,
            table: {
                title: "Selected Codes",
                headers: ["phrase", "vocab", "term", "id", "remove"],
                headerMap: {
                    'phrase': {
                        text: "Query",
                        sortable: true
                    },
                    'vocab': {
                        text: "Vocabulary",
                        sortable: true
                    },
                    'id': {
                        text: "Term Id",
                        sortable: false,
                        transform: (value, index, row) => (<a href={"https://www.icd10data.com/search?s=" + value} target="_blank" rel="noopener noreferrer">{value}</a>)
                    },
                    'term': {
                        text: "Term Name",
                        sortable: true
                    },
                    'remove': {
                        text: "Remove",
                        transform: (value, index, row) => (<button className="btn btn-danger" onClick={() => this.removeEntryFromTable(index)}>X</button>)
                    },
                },
                perPageItemCount: 10,
                data: []
            },
            // findings_table: {
            //     findings_data: [],
            //     findings_codes: [],
            // },
            // disorders_table: {
            //     disorders_data: [],
            //     disorders_codes: [],
            // },
            // procedures_table: {
            //     procedures_data: [],
            //     procedures_codes: [],
            // },
            // drugs_table: {
            //     drugs_data: [],
            //     drugs_codes: [],
            // },
            // situations_table: {
            //     situations_data: [],
            //     situations_codes: [],
            // },
            // events_table: {
            //     events_data: [],
            //     events_codes: [],
            // },
            // specialties_table: {
            //     specialties_data: [],
            //     specialties_codes: [],
            // },
            // tests_table: {
            //     tests_data: [],
            //     tests_codes: [],
            // },
            filters: {
                //cpt: false,
                // icd10cm: true,
                //icd10pcs: false,
                // snomed: false,
                //riak: false
            },
            taxonomy: "all",  // umls, snomed
            alert_invalid: false,
            alert_error: false,
            alert_message: "",
            alert_no_results: false,
            loading: false,
            interactive_text: "",
            dropdown_phrases: {},
            dropdown_colorMap: {},
            input_text: "",
            interactive_index_map: {},
            saving_annotations_loading: false,
            // show_plot: false,
            // plot_title: "PCA of Nearest Neighbors"
            selectedTaxonomy: "all",
            findBestMatch: true,
            accessKey: localStorage.getItem('accessKey') || '',
            isAccessKeyInputVisible: false,
            showDownloadButton: false,
            response: {},
            entityVisibility: {
                procedure: true,
                body_structure: true,
                disorder: true,
                occupation: true,
                finding: true,
                therapy: true,
                person: true,
                location: true,
                event: true,
                religion: true,
                situation: true,
                drug: true,
                racial_group: true
            },
            isInteractiveTextExpanded: true,
        };

        this.httpRequest = this.httpRequest.bind(this);
        this.onPhraseChange = this.onPhraseChange.bind(this);
        this.addEntryToTable = this.addEntryToTable.bind(this);
        this.getPhrasesFromInput = this.getPhrasesFromInput.bind(this);
        this.updateInteractiveTextVariables = this.updateInteractiveTextVariables.bind(this);
        this.createInteractiveTextDisplay = this.createInteractiveTextDisplay.bind(this);
        this.removeEntryFromTable = this.removeEntryFromTable.bind(this);
        // this.onChooseAPI = this.onChooseAPI.bind(this);
        this.onChooseForMe = this.onChooseForMe.bind(this);
        this.resetTables = this.resetTables.bind(this);
        this.removeNerIndex = this.removeNerIndex.bind(this);
        this.saveAnnotations = this.saveAnnotations.bind(this);
        this.viewAnnotations = this.viewAnnotations.bind(this);
        this.animateTypingNarrative = this.animateTypingNarrative.bind(this);
        // this.drawScatterPlot = this.drawScatterPlot.bind(this);
        // this.initiateDrawScatterPlot = this.initiateDrawScatterPlot.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onChooseTaxonomy = this.onChooseTaxonomy.bind(this);
        this.onToggleFindBestMatch = this.onToggleFindBestMatch.bind(this);
        this.handleAccessKeyChange = this.handleAccessKeyChange.bind(this);
        
        this.jsonToCSV = this.jsonToCSV.bind(this);
        this.downloadCSV = this.downloadCSV.bind(this);
        this.toggleEntityVisibility = this.toggleEntityVisibility.bind(this);
        this.toggleInteractiveText = this.toggleInteractiveText.bind(this);

        this.colorMap = {
            "icd10cm": "#FF0000",
            "cpt": "#00FF00",
            "rxnorm": "#0000FF",
            "rucc": "#A020F0"
        };

        // Map of entity labels to colors
        this.LABEL_COLORS = {
            'procedure': 'blue',
            'body_structure': '#FFBF00',
            'disorder': 'red',
            'occupation': 'red',
            'finding': 'red',
            'therapy': 'blue',
            'person': '#FFBF00',
            'location': '#FFBF00',
            'event': 'red',
            'religion': 'red',
            'situation': 'red',
            'drug': 'green',
            'racial_group': 'red'
        };

        this.narrative = "";
        this.intervalId = 0;

        this.resetTables();
    }

    handleAccessKeyChange(event) {
        const accessKey = event.target.value;
        this.setState({ accessKey });
        localStorage.setItem('accessKey', accessKey);  // Save to localStorage
    }

    toggleAccessKeyInputVisibility = () => {
        this.setState(prevState => ({isAccessKeyInputVisible: !prevState.isAccessKeyInputVisible}));
    }

    resetTables() {
        // let names = ["findings", "disorders", "situations", "procedures", "drugs", "events", "tests", "specialties"];

        // for (let i = 0; i < names.length; i++) {
        //     let name = names[i];
        //     this.state[name + "_table"][name + "_data"] = [{"entity": ""}]
        // }
        // this.state.table.data = [];

        /*
        for (let i = 0; i < names.length; i++) {
           let name = names[i];
           let key = name + "_table";
           let helper = this.state[key];
           helper[name + "_data"] = [{"entity": ""}];
           this.setState({key: helper});
       }
       let table = this.state.table;
       table.data = [];
       this.setState({"table": table});
        */
    }

    removeNerIndex(index) {
        let index_map = this.state.interactive_index_map;
        if (index_map.hasOwnProperty(index)) {
            delete index_map[index];
        }
        this.setState({
            interactive_index_map: index_map
        });
    }

    httpRequest(method, url, params, callback) {
        const accessKey = document.getElementById("accessKey").value;

        let xmlHttp = new XMLHttpRequest();
        let ctrl = this;

        ctrl.setState({
            alert_message: "",
        })
        
        xmlHttp.onreadystatechange = function () {
            if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
                ctrl.setState({loading: false});
                callback(JSON.parse(xmlHttp.response));
            } else if (xmlHttp.readyState === 4) {
                if (xmlHttp.status === 400) {
                    ctrl.setState({
                        alert_invalid: false,
                        alert_error: false,
                        alert_no_results: true
                    });
                } else {
                    const response = JSON.parse(xmlHttp.response);
                    const errorMessage = response.error || 'Unknown error occurred';
                    ctrl.setState({
                        alert_invalid: false,
                        alert_error: true,
                        alert_no_results: false,
                        alert_message: errorMessage
                    });
                }
            
                ctrl.setState({loading: false, saving_annotations_loading: false});
            }
        };
        
        xmlHttp.open(method, url, true);
        
        if (accessKey) {
            xmlHttp.setRequestHeader("Access-Key", accessKey);
        }
    
        if (method === "POST") {
            xmlHttp.setRequestHeader("Content-Type", "application/json");
            xmlHttp.send(JSON.stringify(params));
        } else {
            xmlHttp.send();
        }
    }
    
    

    componentDidMount() {
        //$('[data-toggle="tooltip"]').tooltip();
    }

    getPhrasesFromInput() {
        this.narrative = this.ref.current.value;
        let parts = this.narrative.split("@");
        if (parts.length === 1) {
            return [this.narrative];
        } else {
            let terms = [];
            for (let i = 1; i < parts.length; i += 2) {
                terms.push(parts[i]);
            }
            return terms;
        }
    }

    // // Disabled by Skim 2023-08-11 - Only use new model API going forward
    // onChooseAPI(e) {
    //     this.setState({select_API: e.target.value});
    //     if (e.target.value == "Production") {
    //         this.setState({select_API_host: packageJson.pythonEnv.HOST_API1}, this.onPhraseChange);
    //     } 
    //     else if (e.target.value == "Development")  {
    //         this.setState({select_API_host: packageJson.pythonEnv.HOST_API2}, this.onPhraseChange);
    //     } 
    //     // else if (e.target.value == "Development - MNR") {
    //     //     this.setState({select_API_host: packageJson.pythonEnv.HOST_API3}, this.onPhraseChange);
    //     // }
    // }

    onSearch() {
        this.narrative = this.ref.current.value;
        this.onPhraseChange();
    }

    onChooseTaxonomy(event) {
        this.setState({ selectedTaxonomy: event.target.value }, this.onPhraseChange);
        // console.log(event.target.value);
    }

    onToggleFindBestMatch() {
        this.setState({ findBestMatch: !this.state.findBestMatch }, this.onPhraseChange);
    }


    // // OLD NARRATIVE LOADING FUNCTION THAT LOADS FROM LOCAL JSON FILE
    // onChooseForMe() {
    //     let index = parseInt(Math.random() * narratives.length);
    //     let narrative = narratives[index];
    //     this.narrative = narrative;
    //     this.ref.current.value = "";
    //     this.onPhraseChange();

    //     // // Uncomment to animate typing
    //     // let delay = 20000 / narrative.length;  
    //     // this.intervalId = setInterval(this.animateTypingNarrative, delay);

    //     // Set the narrative text directly without animating
    //     this.ref.current.value = this.narrative;
    // }

    onChooseForMe() {
        this.setState({
            alert_invalid: false,
            alert_error: false,
            alert_no_results: false,
            loading: true,
        });
    
        this.httpRequest("GET", this.state.select_API_host + "1.0/random_narrative", null, (data) => {
            this.narrative = data.narrative;
            this.ref.current.value = "";
            // this.onPhraseChange();
    
            // Uncomment to animate typing
            // let delay = 20000 / this.narrative.length;
            // this.intervalId = setInterval(this.animateTypingNarrative, delay);
    
            // Set the narrative text directly without animating
            this.ref.current.value = this.narrative;
        });
    }

    animateTypingNarrative() {
        if (this.ref.current.value.length < this.narrative.length) {
            this.ref.current.value += this.narrative[this.ref.current.value.length];
        } else {
            clearInterval(this.intervalId);
        }
    }

    onPhraseChange() {
        if (this.ref.current.value.length > 0) {  // Only query the API if we have narrative text
            this.resetTables();
            this.state.dropdown_phrases = {};
            this.state.dropdown_colorMap = {};
            // Reset the time taken label
            document.getElementById("time_taken").innerHTML = "";

            // Setup the API call parameters
            let params = {
                "phrase": this.narrative,
                "filters": this.state.filters,
                "taxonomy": this.state.selectedTaxonomy,
                "chunk_phrase": true,
                "save_narrative": true,  // Save the narrative to SQLite database on the server side
                "max_neighbors": 5,
                "find_best_match": this.state.findBestMatch,
            };

            this.setState({
                alert_invalid: false,
                alert_error: false,
                alert_no_results: false,
                loading: true,
                input_text: this.narrative,
                interactive_index_map: {},
                // show_plot: false,
                // plot_title: "PCA of Nearest Neighbors"
            });

            let ctrl = this;
            // Record the time taken
            const startTime = new Date().getTime();
            this.httpRequest("POST", this.state.select_API_host + "/1.0/journey/query/phrase", params, (response) => {
                const endTime = new Date().getTime();

                if (ctrl.ref.current.value.length < ctrl.narrative.length) {
                    clearInterval(ctrl.intervalId);
                    ctrl.ref.current.value = ctrl.narrative;
                }

                if (response && Object.keys(response).length >= 1) {
                    this.state.response = response;
                    ctrl.updateInteractiveTextVariables(response);
                    ctrl.setState({ showDownloadButton: true });
                    // Update the #time_taken
                    document.getElementById("time_taken").innerHTML = "Prediction time: " + ((endTime - startTime)/1000).toFixed(2) + " seconds";
                    // Count the # of entities returned in the response and update in the #entity_count label
                    const entityCount = Object.keys(response).length;
                    document.getElementById("entity_count").innerHTML = "# Entities found: " + entityCount;
                    
                } else {
                    ctrl.setState({
                        alert_invalid: false,
                        alert_error: false,
                        alert_no_results: true,
                        showDownloadButton: false // Ensure button is hidden if there are no results
                    });
                }
            });
        }
    }

    updateInteractiveTextVariables(response) {
        let keys = Object.keys(response);
        let index_map = {};

        // get index of each key phrase in the input
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let codes = [];
            let results = response[key]["results"];
            let start = response[key]["start"];
            let end = response[key]["end"];
            let phrase = response[key]["phrase"];
            let negative = response[key]["negative"];
            let surl = response[key]["stat_pearls"]["id"];
            let sname = response[key]["stat_pearls"]["phrase"];
            let label = response[key]["label"].toLowerCase(); // Convert to lowercase here

            // Assign display color based on entity label
            for (let j = 0; j < results.length; j++) {
                let result = results[j];
                result.displayColor = this.LABEL_COLORS[label] || '#212529';
            }

            if (results === undefined || results.length === 0) {
                codes.push({
                    phrase: "Terminology code NOT IDENTIFIED",
                });
            } else {
                for (let j = 0; j < Math.min(results.length, 100); j++) {
                    let item = {
                        source: results[j]["source"],
                        vocab: results[j]["vocab"],
                        id: results[j]["id"],
                        phrase: results[j]["phrase"],
                        query: results[j]["source"] === "Ace" ? phrase : key,
                        distance: results[j]["semantic-idf"],
                        displayColor: results[j]["displayColor"]
                    };
                    if ("specific" in results[j]) {  // Include specific field if it exists
                        item["specific"] = results[j]["specific"];
                    }
                    if ("hcc" in results[j]) {  // Include hcc field if it exists
                        item["hcc"] = results[j]["hcc"];
                    }
                    codes.push(item);
                }
            }

            if (codes.length === 0) {
                codes.push("None Found");
            }

            if (index_map.hasOwnProperty(start)) {
                if (index_map[start]["end"] < end) {
                    index_map[index_map[start]["end"] + 1] = {
                        "codes": codes,
                        "end": end,
                        "negative": negative,
                        "stat_pearls_url": surl,
                        "stat_pearls_name": sname,
                        "label": label
                    };
                } else {
                    index_map[end + 1] = {
                        "codes": index_map[start]["codes"],
                        "end": index_map[start]["end"],
                        "negative": index_map[start]["negative"],
                        "stat_pearls_url": surl,
                        "stat_pearls_name": sname,
                        "label": label
                    };
                    index_map[start] = {
                        "codes": codes,
                        "end": end,
                        "negative": negative,
                        "stat_pearls_url": surl,
                        "stat_pearls_name": sname,
                        "label": label
                    };
                }
            } else {
                index_map[start] = {
                    "codes": codes,
                    "end": end,
                    "negative": negative,
                    "stat_pearls_url": surl,
                    "stat_pearls_name": sname,
                    "label": label
                };
            }
        }

        this.setState({
            interactive_index_map: index_map
        });
    }

    createInteractiveTextDisplay() {

        let input = this.state.input_text;
        let index_map = this.state.interactive_index_map;

        let buffer = "";
        let elements = [];
        for (let i = 0; i < input.length; i++) {
            if (index_map.hasOwnProperty(i)) {
                if (!this.state.entityVisibility[index_map[i]["label"]]) {
                    buffer += input.substring(i, index_map[i]["end"]);
                    i += (index_map[i]["end"] - i) - 1;
                    continue;
                }

                if (buffer !== "") {
                    elements.push((
                        <span key={i - 1}>{buffer}</span>
                    ));
                    buffer = "";
                }

                let color;
                if (index_map[i]["codes"].length > 0) {
                    // Assign color based on entity label
                    color = this.LABEL_COLORS[index_map[i]["label"]] || '#212529';
                } else {
                    color = '#212529'; // Default dark gray
                }

                let original_text = input.substring(i, index_map[i]["end"]);
                if (!this.state.dropdown_phrases.hasOwnProperty(original_text)) {
                    this.state.dropdown_phrases[original_text] = original_text;
                }

                // Override the color if the user has selected an entry in the dropdown
                if (this.state.dropdown_colorMap.hasOwnProperty(original_text)) {
                    color = this.state.dropdown_colorMap[original_text];
                } else {
                    // Store the color in the dropdown color map
                    this.state.dropdown_colorMap[original_text] = color;
                }

                
                let dropdown = (
                    <DropdownComponent text={this.state.dropdown_phrases[original_text]} codes={index_map[i]["codes"]} color={color} negative={index_map[i]["negative"]}
                                       key={i} index={i} callback={this.addEntryToTable} original_text={original_text} removeCallback={this.removeNerIndex}
                                       stat_pearls_url={index_map[i]["stat_pearls_url"]} stat_pearls_name={index_map[i]["stat_pearls_name"]}></DropdownComponent>
                );
                // let dropdown = (
                //     <DropdownComponent text={this.state.dropdown_phrases[original_text]} codes={index_map[i]["codes"]} color={color} negative={index_map[i]["negative"]}
                //                        key={i} index={i} callback={this.addEntryToTable} original_text={original_text} removeCallback={this.removeNerIndex}></DropdownComponent>
                // );
                //let dropdown = this.state.dropdown_phrases[original_text];
                elements.push(dropdown);
                i += (index_map[i]["end"] - i) - 1;
            } else {

                if (input[i] === "\n") {
                    if (buffer !== "") {
                        elements.push((
                            <span key={i - 1}>{buffer}</span>
                        ));
                        elements.push((<br key={i - 1 + "br"}/>));
                        buffer = "";
                    }
                } else {
                    buffer += input[i];
                }
            }
        }

        // flush buffer
        if (buffer !== "") {
            elements.push((
                <span key={input.length}>{buffer}</span>
            ));
            buffer = ""
        }

        return elements;
    }

    addEntryToTable(entry, dropdown_index) {

        let table = this.state.table;
        table.data.push({
            "phrase": dropdown_index,
            "vocab": entry.vocab,
            "id": entry.id,
            "term": entry.phrase,
            "remove": "x"
        });

        let updated_phrases = this.state.dropdown_phrases;
        if (dropdown_index) {
            updated_phrases[dropdown_index] = entry.phrase;
        }

        // Update the display color for the phrase shown in the interactive text
        let updated_colorMap = this.state.dropdown_colorMap;

        if (entry.id === "Custom") {
            // Always show Custom Annotations as Blue
            updated_colorMap[dropdown_index] = "blue";
        } else {
            if (dropdown_index && entry.displayColor) {
                updated_colorMap[dropdown_index] = entry.displayColor;
            }
        }

        this.setState({
            table: table,
            dropdown_phrases: updated_phrases,
            dropdown_colorMap: updated_colorMap
        });
    }

    removeEntryFromTable(index) {
        let table = this.state.table;
        table.data.splice(index, 1);
        this.setState({
            table: table
        });
    }

    saveAnnotations() {

        if (this.state.table.data.length > 0) {

            this.state.saving_annotations_loading = true;

            let annotations = [];
            for (let i = 0; i < this.state.table.data.length; i++) {
                annotations.push( {
                   "query":  this.state.table.data[i].phrase,
                   "vocab":  this.state.table.data[i].vocab,
                   "term":  this.state.table.data[i].term,
                   "id":  this.state.table.data[i].id,
                });
            }

            let params = {
                "annotations": annotations
            };

            let ctrl = this;
            this.httpRequest("POST", this.state.select_API_host + "/1.0/journey/saveAnnotations", params, function (response) {
                alert("Saving success!");
                ctrl.setState({saving_annotations_loading: false});
            });
        } else {
            alert("There are no annotations to save!");
        }

    }

    // viewAnnotations() {
    //     // Open a new tab that navigates to this.state.select_API_host + "/1.0/journey/annotations"
    //     window.open(this.state.select_API_host + "/1.0/journey/annotations", "_blank");
    // }

    viewAnnotations() {
        const accessKey = document.getElementById("accessKey").value;
    
        fetch(this.state.select_API_host + "/1.0/journey/annotations", {
            method: 'GET',
            headers: {
                'Access-Key': accessKey
            }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return response.blob();
        })
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'annotations.csv'; // specify the name of the file to be downloaded
    
            document.body.appendChild(a);
            a.click();
    
            window.URL.revokeObjectURL(url);
        })
        .catch(error => {
            console.error('There was an error downloading the annotations:', error);
            this.setState({
                alert_invalid: false,
                alert_error: true,
                alert_no_results: false,
                alert_message: error.toString()
            });
        });
    }

    jsonToCSV(json) {
        let csvRows = [];
        let headers = ['"NER"'];
        let maxResults = 0;
    
        // Determine the maximum number of results and prepare headers
        for (const key in json) {
            const results = json[key].results;
            maxResults = Math.max(maxResults, results.length);
        }
        for (let i = 1; i <= maxResults; i++) {
            headers.push(`"NN${i}_code"`, `"NN${i}_phrase"`);
        }
        csvRows.push(headers.join(','));
    
        // Convert each top-level object to a CSV row
        for (const key in json) {
            const ner = json[key].phrase;
            const results = json[key].results;
            let row = [`"${ner}"`];
    
            for (let i = 0; i < maxResults; i++) {
                if (i < results.length) {
                    row.push(`"${results[i].id}"`, `"${results[i].phrase}"`);
                } else {
                    row.push('', ''); // Fill in blanks if this row has fewer results
                }
            }
            csvRows.push(row.join(','));
        }
    
        return csvRows.join('\n');
    }


    downloadCSV() {
        const csvString = this.jsonToCSV(this.state.response); // Assuming the response is stored in state
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'narrative_capture_export.csv';
        document.body.appendChild(a); // Append to body to ensure visibility in the document
        a.click();
        document.body.removeChild(a); // Clean up
    }

    toggleEntityVisibility(entityType) {
        this.setState(prevState => ({
            entityVisibility: {
                ...prevState.entityVisibility,
                [entityType]: !prevState.entityVisibility[entityType]
            }
        }));
    }
    

    // initiateDrawScatterPlot(codes, center_code) {

    //     this.setState({
    //         show_plot: true,
    //         plot_title: "Nearest Neighbors of " + center_code
    //     });

    //     let input = [center_code];
    //     let distances = [0];
    //     for (let i = 0; i < codes.length; i++) {
    //         input.push(codes[i].phrase);
    //         distances.push(codes[i].distance);
    //     }

    //     let params = {
    //         "neighbors": input,
    //         "lookup": true,
    //         "distances": distances,
    //         "polar": true
    //     };

    //     let ctrl = this;
    //     this.httpRequest(packageJson.pythonEnv.HOST_API1 + "/1.0/journey/query/pca", params, function (response) {
    //         if (response && response.result) {
    //             let data = [{
    //                 "phrase": center_code,
    //                 "id": "TBD",
    //                 "pc1": response.result[0][0],
    //                 "pc2": response.result[0][1]
    //             }];
    //             for (let i = 1; i < response.result.length; i++) {
    //                 data.push({
    //                     "phrase": codes[i-1].phrase,
    //                     "id": codes[i-1].id,
    //                     "pc1": response.result[i][0],
    //                     "pc2": response.result[i][1]
    //                 })
    //             }

    //             ctrl.drawScatterPlot(data);
    //         }
    //     });

    // }

    // drawScatterPlot(data) {

    //     let margin = {top: 20, right: 20, bottom: 40, left: 60};
    //     const height = 400 - margin.top - margin.bottom;
    //     const width = 460 - margin.left - margin.right;

    //     let myRainbow = new Rainbow();
    //     myRainbow.setNumberRange(1, data.length);
    //     myRainbow.setSpectrum("red", "yellow");

    //     let domain_min = 99;
    //     let range_min = 99;
    //     let domain_max = -99;
    //     let range_max = -99;
    //     let window_offset = 0.01;
    //     for (let i = 0; i < data.length; i++) {
    //         domain_min = Math.min(domain_min, data[i]["pc1"]);
    //         domain_max = Math.max(domain_max, data[i]["pc1"]);
    //         range_min = Math.min(range_min, data[i]["pc2"]);
    //         range_max = Math.max(range_max, data[i]["pc2"]);

    //         data[i]["color"] = "#" + myRainbow.colorAt(i);

    //         if (i === 0) {
    //             data[i]["radius"] = 7;
    //         } else {
    //             data[i]["radius"] = 5;
    //         }
    //     }

    //     let el = document.getElementById("plot");
    //     while (el.childElementCount > 0) {
    //         el.removeChild(el.childNodes[0]);
    //     }

    //     // append the svg object to the body of the page
    //     var svg = d3.select("#plot")
    //         .append("svg")
    //         .attr("width", width + margin.left + margin.right)
    //         .attr("height", height + margin.top + margin.bottom)
    //         .append("g")
    //         .attr("transform",
    //             "translate(" + margin.left + "," + margin.top + ")");

    //     // Add X axis
    //     var x = d3.scaleLinear()
    //         .domain([domain_min - window_offset, domain_max + window_offset])
    //         .range([0, width]);
    //     svg.append("g")
    //         .attr("transform", "translate(0," + height + ")")
    //         .call(d3.axisBottom(x).ticks(5));

    //     svg.append("text")
    //         .attr("text-anchor", "end")
    //         .attr("x", width)
    //         .attr("y", height + margin.top + 10)
    //         .text("Principal Component 1");

    //     // Add Y axis
    //     var y = d3.scaleLinear()
    //         .domain([range_min - window_offset, range_max + window_offset])
    //         .range([height, 0]);
    //     svg.append("g")
    //         .call(d3.axisLeft(y).ticks(5));
    //     svg.append("text")
    //         .attr("text-anchor", "end")
    //         .attr("x", -margin.top)
    //         .attr("y", -margin.left + 20)
    //         .attr("transform", "rotate(-90)")
    //         .text("Principal Component 2");

    //     var tooltip = d3.select("#plot")
    //         .append("div")
    //         .style("display", "none")
    //         .style("opacity", 1)
    //         .attr("class", "tooltip")
    //         .style("background-color", "white")
    //         .style("border", "solid")
    //         .style("border-width", "1px")
    //         .style("border-radius", "5px")
    //         .style("padding", "10px");


    //     // A function that change this tooltip when the user hover a point.
    //     // Its opacity is set to 1: we can now see it. Plus it set the text and position of tooltip depending on the datapoint (d)
    //     var mouseover = function (d) {
    //         tooltip.style("display", "block");

    //         /*if (d.physician.npi == this.selectedNpi) {
    //             d3.select(this).attr("r", 20);
    //         } else {
    //             d3.select(this).attr("r", 10);
    //         }*/
    //         d3.select(this).attr("r", 10);

    //     };

    //     var mousemove = function (d) {

    //         let template = `(${d.pc1}, ${d.pc2})<br>
    //                         Phrase: ${d.phrase}<br>
    //                         Id: ${d.id}`;

    //         tooltip
    //             .html(template)
    //             .style("left", (d3.mouse(this)[0] + 620) + "px") // It is important to put the +90: other wise the tooltip is exactly where the point is an it creates a weird effect
    //             .style("top", (d3.mouse(this)[1] + 500) + "px")
    //     };

    //     // A function that change this tooltip when the leaves a point: just need to set opacity to 0 again
    //     var mouseleave = function (d) {

    //         tooltip.style("display", "none");

    //         /*if (d.physician.npi == this.selectedNpi) {
    //             d3.select(this)
    //                 .transition()
    //                 .duration(200)
    //                 .attr("r", 15);
    //         } else {
    //             d3.select(this)
    //                 .transition()
    //                 .duration(200)
    //                 .attr("r", 5);
    //         }*/
    //         d3.select(this)
    //             .transition()
    //             .duration(200)
    //             .attr("r", d.radius);

    //     };

    //     // Add dots
    //     svg.append('g')
    //         .selectAll("dot")
    //         .data(data)
    //         .enter()
    //         .append("circle")
    //         .attr("cx", function (d) {
    //             return x(d.pc1);
    //         })
    //         .attr("cy", function (d) {
    //             return y(d.pc2);
    //         })
    //         .attr("r", function (d) {
    //             return d.radius
    //         })
    //         .style("fill", function (d) {
    //             return d.color
    //         })
    //         .style("opacity", 1)
    //         .style("stroke-width", 1)
    //         .style("stroke", "#000000")
    //         .attr("id", function (d) {
    //             return d.id + "_node";
    //         })
    //         .on("mouseover", mouseover)
    //         .on("mousemove", mousemove)
    //         .on("mouseleave", mouseleave);

    // }

    toggleInteractiveText() {
        this.setState(prevState => ({
            isInteractiveTextExpanded: !prevState.isInteractiveTextExpanded
        }));
    }

    render() {

        let alertInvalid = (<span></span>);
        if (this.state.alert_invalid) {
            alertInvalid = (
                <div className="alert alert-warning alert-dismissible">
                    <strong>Warning!</strong> Phrase is invalid! Please check again.
                    <button type="button" className="close" onClick={() => this.setState({alert_invalid: false})}>&times;</button>
                </div>
            );
        }

        let alertError = (<span></span>);
        if (this.state.alert_error) {
            alertError = (
                <div className="alert alert-danger alert-dismissible">
                    <strong>Error!</strong> {this.state.alert_message}
                    <button type="button" className="close" onClick={() => this.setState({alert_error: false})}>&times;</button>
                </div>
            );
        }

        let alertNoResults = (<span></span>);
        if (this.state.alert_no_results) {
            alertError = (
                <div className="alert alert-warning alert-dismissible">
                    <strong>Warning!</strong> There are no codes found for this input.
                    <button type="button" className="close" onClick={() => this.setState({alert_no_results: false})}>&times;</button>
                </div>
            );
        }

        let enterAccessKey = (
            <div className="form-group">
                <label htmlFor="accessKey">
                    <h5>
                        Access Key &nbsp;
                        <button onClick={this.toggleAccessKeyInputVisibility}>
                            {this.state.isAccessKeyInputVisible ? '-' : '+'}
                        </button>
                    </h5>
                </label>
                <input 
                    type="password"
                    className="form-control" 
                    id="accessKey" 
                    placeholder="Enter Access Key" 
                    value={this.state.accessKey}
                    onChange={this.handleAccessKeyChange}
                    style={{ 
                        width: "300px",
                        display: this.state.isAccessKeyInputVisible ? 'block' : 'none',
                        // visibility: this.state.isAccessKeyInputVisible ? 'visible' : 'hidden'
                    }} 
                />
            </div>
        );        

        // let chooseAPI;
        // if (this.state.loading) {
        //     chooseAPI = (
        //         <select disabled>
        //             <option value="Production">Production</option>
        //             <option value="Development">Development</option>
        //             {/* <option value="Development - MNR">Development - MNR</option> */}
        //         </select>
        //     );
        // } else {
        //     chooseAPI = (
        //         <select value={this.state.select_API} onChange={this.onChooseAPI}>
        //                 <option value="Production">Production</option>
        //                 <option value="Development">Development</option>
        //                 {/* <option value="Development - MNR">Development - MNR</option> */}
        //         </select>
        //     );
        // }

        let searchButton;
        let chooseForMe;
        let chooseTaxonomy;
        let toggleFindBestMatch;
        if (this.state.loading) {
            searchButton = (
                <button type="button" className="btn btn-primary" disabled><i className="fa fa-search"></i></button>
            );
            chooseForMe = (
                <button type="button" className="btn btn-primary" disabled>Choose For Me</button>
            );
            chooseTaxonomy = (
                <div>
                    <input
                        type="radio"
                        id="all_taxonomies"
                        name="taxonomy"
                        value="all"
                        checked={this.state.selectedTaxonomy === "all"}
                        onChange={this.onChooseTaxonomy}
                        disabled
                    />
                    <label htmlFor="all_taxonomies">&nbsp; All Taxonomies</label>
                    <br />
                    <input
                        type="radio"
                        id="snomed"
                        name="taxonomy"
                        value="snomed"
                        checked={this.state.selectedTaxonomy === "snomed"}
                        onChange={this.onChooseTaxonomy}
                        disabled
                    />
                    <label htmlFor="snomed">&nbsp; SNOMED</label>
                    <br />
                    <input
                        type="radio"
                        id="umls"
                        name="taxonomy"
                        value="umls"
                        checked={this.state.selectedTaxonomy === "umls"}
                        onChange={this.onChooseTaxonomy}
                        disabled
                    />
                    <label htmlFor="umls">&nbsp; UMLS</label>
                </div>
            )
            toggleFindBestMatch = (
                <div>
                    <input
                        type="checkbox"
                        id="find_best_match"
                        checked={this.state.findBestMatch}
                        onChange={this.onToggleFindBestMatch}
                        disabled
                    />
                    <label htmlFor="find_best_match">&nbsp; Match Substring</label>
                </div>
            );
        } else {
            searchButton = (
                <button type="button" className="btn btn-primary" onClick={this.onSearch}><i className="fa fa-search"></i></button>
            );
            chooseForMe = (
                <button type="button" className="btn btn-primary" onClick={this.onChooseForMe}>Choose For Me</button>
            );
            chooseTaxonomy = (
                <div>
                    <input
                        type="radio"
                        id="all_taxonomies"
                        name="taxonomy"
                        value="all"
                        checked={this.state.selectedTaxonomy === "all"}
                        onChange={this.onChooseTaxonomy}
                    />
                    <label htmlFor="all_taxonomies">&nbsp; All Taxonomies</label>
                    <br />
                    <input
                        type="radio"
                        id="snomed"
                        name="taxonomy"
                        value="snomed"
                        checked={this.state.selectedTaxonomy === "snomed"}
                        onChange={this.onChooseTaxonomy}
                    />
                    <label htmlFor="snomed">&nbsp; SNOMED</label>
                    <br />
                    <input
                        type="radio"
                        id="umls"
                        name="taxonomy"
                        value="umls"
                        checked={this.state.selectedTaxonomy === "umls"}
                        onChange={this.onChooseTaxonomy}
                    />
                    <label htmlFor="umls">&nbsp; UMLS</label>
                </div>
            )
            // Checkbox that triggers "findBestMatch" boolean
            toggleFindBestMatch = (
                <div>
                    <input
                        type="checkbox"
                        id="find_best_match"
                        checked={this.state.findBestMatch}
                        onChange={this.onToggleFindBestMatch}
                    />
                    <label htmlFor="find_best_match">&nbsp; Match Substring</label>
                </div>
            );

        }

        let loadingWidget = (<span></span>);
        if (this.state.loading) {
            loadingWidget = (<i className="fa fa-spinner fa-spin"></i>);
        }

        let savingLoadingWidget = (<span></span>);
        if (this.state.saving_annotations_loading) {
            savingLoadingWidget = (<i className="fa fa-spinner fa-spin"></i>);
        }

        // let plot_status = "hidden";
        // if (this.state.show_plot) {
        //     plot_status = "visible";
        // }

        let ctrl = this;

        return (
            <div className="my-3">

                <div className="container">
                    <h1>Health Concept Extractor Tool</h1>
                    <hr/>

                    {alertInvalid}
                    {alertError}
                    {alertNoResults}

                    {/* ADDED BY SKIMAI: CHOOSE API SERVER */}
                    {/* <h3>Choose API</h3>
                    <div className="row" style={{paddingLeft: 15}}>
                        {chooseAPI}
                    </div>
                    <br/><br/> */}
                    <div className="row" style={{paddingLeft: 15}}>
                        {enterAccessKey}
                    </div>
                    <br/>

                    <h3 htmlFor="cpt_code_input" style={{marginBottom: 4}}>Clinical Narrative &nbsp; {loadingWidget}</h3>
                    <div className="row">
                        <div className="col-10">
                            <textarea id="cpt_code_input" style={{width: "100%"}} rows="10" ref={this.ref}/>
                        </div>
                        <div className="col-2">
                            {searchButton}
                            <br/><br/>
                            {chooseForMe}
                            <br/><br/>
                            <h5>Return:</h5>
                            {chooseTaxonomy}
                            {toggleFindBestMatch}
                        </div>
                        

                    </div>
                    <hr/>

                    <div>
                        <h3 style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            Interactive Text
                            <button 
                                className="btn btn-sm btn-outline-secondary" 
                                onClick={this.toggleInteractiveText}
                                style={{ padding: '0px 8px' }}
                            >
                                {this.state.isInteractiveTextExpanded ? '−' : '+'}
                            </button>
                        </h3>
                        <div style={{ 
                            display: this.state.isInteractiveTextExpanded ? 'block' : 'none',
                            whiteSpace: 'pre-wrap', 
                            border: '1px solid #ccc', 
                            padding: '10px', 
                            borderRadius: '5px' 
                        }}>
                            {this.createInteractiveTextDisplay()}
                        </div>

                        <div id="time_taken"></div>
                        <div id="entity_count"></div>
                    </div>

                    {/* <div className="text-center" style={{"visibility": plot_status}}>
                        <h5>{this.state.plot_title}</h5>
                        <div id="plot"></div>
                    </div> */}

                    <div style={{marginTop: 10}}>
                        <button
                            id="downloadBtn"
                            style={{display: this.state.showDownloadButton ? 'block' : 'none'}}
                            className="btn btn-info"
                            onClick={this.downloadCSV}>Export Synonyms to CSV</button>
                    </div>

                    <hr/>

                    <h3>Entity Phrases by Label</h3> 

                    <div className="row">
                        {(() => {
                            // Get unique entity types present in the current document
                            const entityTypes = new Set();
                            Object.values(this.state.interactive_index_map).forEach(value => {
                                if (value.label) {
                                    entityTypes.add(value.label.toLowerCase());
                                }
                            });

                            // Convert to array and sort alphabetically
                            const sortedEntityTypes = Array.from(entityTypes).sort();

                            // Split into three columns
                            const columns = [[], [], []];
                            sortedEntityTypes.forEach((entityType, index) => {
                                columns[index % 3].push(entityType);
                            });

                            return columns.map((columnTypes, colIndex) => (
                                <div className="col-4" key={`col-${colIndex}`}>
                                    {columnTypes.map(entityType => {
                                        const capitalizedType = entityType.charAt(0).toUpperCase() + entityType.slice(1);
                                        return (
                                            <div key={entityType}>
                                                <div style={{display: 'flex', alignItems: 'center', gap: '8px', marginTop: "10px"}}>
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.entityVisibility[entityType]}
                                                        onChange={() => this.toggleEntityVisibility(entityType)}
                                                    />
                                                    <h5 style={{textDecoration: "underline", fontWeight: "bold", margin: 0}}>{capitalizedType}</h5>
                                                </div>
                                                <hr/>
                                                <div className="label-table">
                                                    {(() => {
                                                        if (!this.state.entityVisibility[entityType]) return null;
                                                        const added = new Set();
                                                        return Object.entries(this.state.interactive_index_map).map(([key, value], index) => {
                                                            if (value.label.toLowerCase() !== entityType) {
                                                                return null;
                                                            }

                                                            let start = key;
                                                            let original_text = this.state.input_text.substring(start, value.end);
                                                            if (!this.state.dropdown_phrases.hasOwnProperty(original_text)) {
                                                                this.state.dropdown_phrases[original_text] = original_text;
                                                            }

                                                            if (!added.has(this.state.dropdown_phrases[original_text].toLowerCase())) {
                                                                added.add(this.state.dropdown_phrases[original_text].toLowerCase());
                                                                let color = "#212529"; // Default color
                                                                let phrase_codes = value.codes;

                                                                if (this.state.dropdown_colorMap.hasOwnProperty(original_text)) {
                                                                    color = this.state.dropdown_colorMap[original_text];
                                                                }

                                                                return (
                                                                    <div key={index + Math.random()}>
                                                                        <DropdownComponent
                                                                            text={this.state.dropdown_phrases[original_text]}
                                                                            codes={phrase_codes}
                                                                            color={color}
                                                                            index={parseInt(key)}
                                                                            negative={value.negative}
                                                                            callback={this.addEntryToTable}
                                                                            original_text={original_text}
                                                                            removeCallback={this.removeNerIndex}
                                                                            stat_pearls_url={value.stat_pearls_url}
                                                                            stat_pearls_name={value.stat_pearls_name}
                                                                        />
                                                                    </div>
                                                                );
                                                            }
                                                            return null;
                                                        });
                                                    })()}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ));
                        })()}
                    </div>
                    <hr/>

                    <h3>Selected Codes</h3>
                    <div className="table" id="table-sortable">
                        <SmartDataTable
                            name={this.state.table.title}
                            orderedHeaders={this.state.table.headers}
                            headers={this.state.table.headerMap}
                            data={this.state.table.data}
                            perPage={this.state.table.perPageItemCount}
                            sortable={true}
                        />
                    </div>
                    <button className="btn btn-info" onClick={this.saveAnnotations}>Save Annotations {savingLoadingWidget}</button>
                    <button className="btn btn-success" onClick={this.viewAnnotations} style={{marginLeft: 5}}>View Annotations</button>
                </div>
            </div>
        );
    }
}
