import React from "react";
import * as d3 from "d3";
import './react-smart-data-table.css';
import SmartDataTable from 'react-smart-data-table';
import $ from "jquery";
import icd_codes from "./json/icd_codes";
import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';


export class Cohort extends React.Component {

    constructor(props) {
        super(props);

        this.hist_data = null;
        this.selected_button_cost = "2008";
        this.selected_button_raf = "2008";
        let table;

        table = {
            title: "Table",
            headers: ["patient_id", "first_name", "last_name", "dob", "dod", "sex", "cost_2008", "cost_2009", "cost_2010", "raf_2008", "raf_2009", "raf_2010", "diagnoses"],
            headerMap: {
                'patient_id': {
                    text: "Medical Record Number",
                    transform: (value, index, row) => (<a href={"/dashboard?pid=" + value} target="_blank" rel="noopener noreferrer">{value}</a>)
                },
                'first_name': {text: "First Name"},
                'last_name': {text: "Last Name"},
                'dob': {text: "Date of Birth"},
                'dod': {text: "Date Death"},
                'sex': {text: "Sex"},
                'cost_2008': {text: "Cost 2008"},
                'cost_2009': {text: "Cost 2009"},
                'cost_2010': {text: "Cost 2010"},
                'raf_2008': {text: "RAF 2008"},
                'raf_2009': {text: "RAF 2009"},
                'raf_2010': {text: "RAF 2010"},
                'diagnoses': {
                    text: "Diagnoses",
                    transform: (value, index, row) => (<div style={{overflow: "auto", height: 50}}>{value}</div>)
                }
            },
            perPageItemCount: 10,
            pagination: true,
            data: []
        };

        this.ref = React.createRef();

        this.state = {
            selected: {},
            table: table
        };

        this.onCohortSearch = this.onCohortSearch.bind(this);
        this.onSpecificSearch = this.onSpecificSearch.bind(this);
        this.onRadioButtonChangeCost = this.onRadioButtonChangeCost.bind(this);
        this.onRadioButtonChangeRaf = this.onRadioButtonChangeRaf.bind(this);
        this.updateTable = this.updateTable.bind(this);
        this.drawHist = this.drawHist.bind(this);
        this.onDiagnosisAdd = this.onDiagnosisAdd.bind(this);

        window.removeElement = this.removeElement;
    }

    httpRequest(endpoint, params, callback) {

        var xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = function () {
            if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
                callback(JSON.parse(xmlHttp.response));
            } else if (xmlHttp.readyState === 4) {

                if (xmlHttp.status === 400) {
                    // something went wrong with the data
                    document.getElementById('alert-no-results').classList.remove('d-none');
                } else {
                    // something went wrong with the backend
                    document.getElementById('alert-error').classList.remove('d-none');
                    console.log(xmlHttp.response);
                }

                document.getElementById('loader').classList.add('d-none');
                document.getElementById('loader_cohort').classList.add('d-none');
            }
        };

        let url = new URL(endpoint);
        url.searchParams.append("params", JSON.stringify(params));

        xmlHttp.open("GET", url.toString(), true);
        xmlHttp.setRequestHeader("Authorization", "Bearer 4e11f4f5-cabb-45f6-b042-fafd7e464b99");
        xmlHttp.send(null);
    }

    httpAutocompleteRequest(endpoint, callback) {
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = function () {
            if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
                callback(JSON.parse(xmlHttp.response));
            } else if (xmlHttp.readyState === 4) {

                if (xmlHttp.status === 400) {
                    // something went wrong with the data
                    document.getElementById('alert-no-results').classList.remove('d-none');
                } else {
                    // something went wrong with the backend
                    document.getElementById('alert-error').classList.remove('d-none');
                }

                document.getElementById('loader').classList.add('d-none');
            }
        };

        xmlHttp.open("GET", endpoint, true);
        xmlHttp.send(null);
    }

    componentDidMount() {
        $(document).ready(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
    }

    onDiagnosisAdd() {
        let code = this.ref.current.getInput().value;

        if (code !== "") {
            let list = document.getElementById("diagnosis_list");
            let template =
                `<div class="row">
                    <div class="col-2">${code}</div>
                    <div class="col-8 text-right">
                        <label class="form-check-label" data-toggle="tooltip" title="Adjust this slider to change the
                        depth of the icd search. Slider on the left produces exact results, and the slider on the right produces more broad
                        results.">Exact <input type="range" min="1" max="3" value="1" class="slider" id="${code}_input"> Broad</label>
                    </div>
                    <div class="col-2"><button type="button" class="btn btn-danger" onclick="removeElement('${code}_li')">x</button></div>
                </div>`;
            let li = document.createElement("li");
            li.classList.add("my-2");
            li.id = code + "_li";
            li.innerHTML = template;
            list.appendChild(li);

        }

    }

    removeElement(id) {
        let el = document.getElementById(id);
        let list = document.getElementById("diagnosis_list");
        list.removeChild(el);
    }

    onSpecificSearch() {

        document.getElementById('loader').classList.remove('d-none');

        let params = {};
        let fname = document.getElementById("last_name").value;
        let lname = document.getElementById("first_name").value;
        let dob = document.getElementById("dob").value;
        let patientId = document.getElementById("record_number").value;

        if (fname) params["first_name"] = fname;
        if (lname) params["last_name"] = lname;
        if (dob) params["dob"] = dob;
        if (patientId) params["patientId"] = patientId;

        let ctrl = this;
        this.httpRequest("https://medic-prod-api.graphgrid.com/1.0/search/executeSearch/medic-prod-neo/medic-prod-index-policy/deidentifiedPatientSearch", params, function (response) {
            if (response.data) {
                let data = [];
                if (response.data.deidentifiedpatient) {
                    data = response.data.deidentifiedpatient.documentData;
                }
                ctrl.updateTable(data);

                document.getElementById("statistics").classList.add("invisible");
            }

            document.getElementById('loader').classList.add('d-none');
        });
    }

    onCohortSearch() {

        document.getElementById('loader_cohort').classList.remove('d-none');

        let params = {};
        let sex = document.getElementById("sex").value;
        let cyear = document.getElementById("calender_year").value;
        let orec = document.getElementById("orec").value;
        let ltimcaid = document.getElementById("ltimcaid").checked ? "1" : "0";
        let nemcaid = document.getElementById("nemcaid").checked ? "1" : "0";
        let npi = document.getElementById("npi").value;

        if (sex && sex !== "0") params["gender"] = sex;
        if (cyear && cyear !== "0") params["calender_year"] = cyear;
        if (orec && orec !== "4") params["orec"] = orec;
        params["ltimcaid"] = ltimcaid;
        params["nemcaid"] = nemcaid;
        if (npi) params["physicians"] = npi;
        params["size"] = 100;

        let diagnoses = document.getElementById("diagnosis_list").children;

        let diags = "";
        let sims = "";
        let broads = "";
        for (let i = 0; i < diagnoses.length; i++) {
            let code = diagnoses[i].id.split("_")[0];

            switch (document.getElementById(code + "_input").value) {
                case "2":
                    sims += code + " ";
                    break;
                case "3":
                    broads += code + " ";
                    break;
                default:
                    diags += code + " ";
            }
        }

        if (diags) params["diagnoses"] = diags.trim();
        if (sims) params["similarDiagnoses"] = sims.trim();
        if (broads) params["broadDiagnoses"] = broads.trim();

        let ctrl = this;
        this.httpRequest("https://medic-prod-api.graphgrid.com/1.0/search/executeSearch/medic-prod-neo/medic-prod-index-policy/deidentifiedPatientSearch", params, function (response) {

            if (response.data) {
                let data = [];
                if (response.data.deidentifiedpatient) {
                    data = response.data.deidentifiedpatient.documentData;
                }
                ctrl.updateTable(data);

                if (data.length !== 0) {
                    ctrl.hist_data = data;
                    ctrl.updateStatistics(data);
                    ctrl.drawHist(data, true, ctrl.selected_button_cost);
                    ctrl.drawHist(data, false, ctrl.selected_button_raf);
                } else {
                    document.getElementById("statistics").classList.add("invisible");
                }

            }

            document.getElementById('loader_cohort').classList.add('d-none');
        });
    }

    onRadioButtonChangeCost(year) {
        this.drawHist(this.hist_data, true, year);
        this.selected_button_cost = year;
    }

    onRadioButtonChangeRaf(year) {
        this.drawHist(this.hist_data, false, year);
        this.selected_button_raf = year;
    }

    updateTable(results) {

        let data = [];
        let csv = "first_name,last_name,date_of_birth,date_of_death,sex,patient_id,diagnoses\n";

        for (let i = 0; i < results.length; i++) {

            let fname = "TBD";
            let lname = "TBD";
            let dob = results[i].source.dateOfBirth.replace(/-/g, '/');
            let dod = results[i].source.dateOfDeath !== "null" ? results[i].source.dateOfDeath : "NA";
            let sex = results[i].source.gender;

            data.push({
                'first_name': fname,
                'last_name': lname,
                'dob': dob,
                'dod': dod,
                'sex': sex,
                'cost_2008': results[i].source.costPerYear["2008"] ? results[i].source.costPerYear["2008"] : "NA",
                'cost_2009': results[i].source.costPerYear["2009"] ? results[i].source.costPerYear["2009"] : "NA",
                'cost_2010': results[i].source.costPerYear["2010"] ? results[i].source.costPerYear["2010"] : "NA",
                'patient_id': results[i].source.patientId,
                'diagnoses': results[i].source.diagnoses.join(", "),
                'raf_2008': results[i].source.rafScores["2008"] ? results[i].source.rafScores["2008"] : "NA",
                'raf_2009': results[i].source.rafScores["2009"] ? results[i].source.rafScores["2009"] : "NA",
                'raf_2010': results[i].source.rafScores["2010"] ? results[i].source.rafScores["2010"] : "NA"
            });

            csv += [fname, lname, dob, dod, sex, results[i].source.patientId, results[i].source.diagnoses.join(" ")].join(",") + "\n";
        }

        let a = document.getElementById("download_results");
        a.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURI(csv));
        a.setAttribute("download", "cohort.csv");
        a.classList.remove("invisible");

        let table = this.state.table;
        table.data = data;
        this.setState({table: table});
    }

    updateStatistics(results) {

        let cost_2008 = 0, cost_2009 = 0, cost_2010 = 0;
        let count_2008 = 0, count_2009 = 0, count_2010 = 0;
        let raf_2008 = 0, raf_2009 = 0, raf_2010 = 0;

        for (let i = 0; i < results.length; i++) {
            let cost_obj = results[i].source.costPerYear;
            let raf_obj = results[i].source.rafScores;
            let years = results[i].source.years;

            for (let j = 0; j < years.length; j++) {

                if (years[j] === "2008" && cost_obj["2008"]) {
                    cost_2008 += cost_obj["2008"];
                    count_2008++;
                } else if (years[j] === "2009" && cost_obj["2009"]) {
                    cost_2009 += cost_obj["2009"];
                    count_2009++;
                } else if (years[j] === "2010" && cost_obj["2010"]) {
                    cost_2010 += cost_obj["2010"];
                    count_2010++;
                }

                if (years[j] === "2008" && raf_obj["2008"]) {
                    raf_2008 += parseFloat(raf_obj["2008"]);
                } else if (years[j] === "2009" && raf_obj["2009"]) {
                    raf_2009 += parseFloat(raf_obj["2009"]);
                } else if (years[j] === "2010" && raf_obj["2010"]) {
                    raf_2010 += parseFloat(raf_obj["2010"]);
                }
            }
        }

        let mean_2008 = cost_2008 / count_2008;
        let mean_2009 = cost_2009 / count_2009;
        let mean_2010 = cost_2010 / count_2010;
        let mean_total = (mean_2008 + mean_2009 + mean_2010) / 3;

        let mean_2008_raf = raf_2008 / count_2008;
        let mean_2009_raf = raf_2009 / count_2009;
        let mean_2010_raf = raf_2010 / count_2010;

        let std_2008 = 0, std_2009 = 0, std_2010 = 0;
        let std_2008_raf = 0, std_2009_raf = 0, std_2010_raf = 0;

        for (let i = 0; i < results.length; i++) {
            let cost_obj = results[i].source.costPerYear;
            let raf_obj = results[i].source.rafScores;
            let years = results[i].source.years;

            for (let j = 0; j < years.length; j++) {
                if (years[j] === "2008") {
                    std_2008 += Math.pow(cost_obj["2008"] - mean_2008, 2);
                    std_2008_raf += Math.pow(raf_obj["2008"] - mean_2008_raf, 2);
                } else if (years[j] === "2009") {
                    std_2009 += Math.pow(cost_obj["2009"] - mean_2009, 2);
                    std_2009_raf += Math.pow(raf_obj["2009"] - mean_2009_raf, 2);
                } else if (years[j] === "2010") {
                    std_2010 += Math.pow(cost_obj["2010"] - mean_2010, 2);
                    std_2010_raf += Math.pow(raf_obj["2010"] - mean_2010_raf, 2);
                }
            }
        }

        std_2008 = Math.sqrt(std_2008 / count_2008);
        std_2009 = Math.sqrt(std_2009 / count_2009);
        std_2010 = Math.sqrt(std_2010 / count_2010);
        let std_total = (std_2008 + std_2009 + std_2010) / 3;

        std_2008_raf = Math.sqrt(std_2008_raf / count_2008);
        std_2009_raf = Math.sqrt(std_2009_raf / count_2009);
        std_2010_raf = Math.sqrt(std_2010_raf / count_2010);

        document.getElementById("2008_mean").innerHTML = "Mean Cost: " + mean_2008.toFixed(2);
        document.getElementById("2009_mean").innerHTML = "Mean Cost: " + mean_2009.toFixed(2);
        document.getElementById("2010_mean").innerHTML = "Mean Cost: " + mean_2010.toFixed(2);
        document.getElementById("total_mean").innerHTML = "Mean Cost: " + mean_total.toFixed(2);

        document.getElementById("2008_stdev").innerHTML = "Stdev Cost: " + std_2008.toFixed(2);
        document.getElementById("2009_stdev").innerHTML = "Stdev Cost: " + std_2009.toFixed(2);
        document.getElementById("2010_stdev").innerHTML = "Stdev Cost: " + std_2010.toFixed(2);
        document.getElementById("total_stdev").innerHTML = "Stdev Cost: " + std_total.toFixed(2);

        document.getElementById("2008_mean_raf").innerHTML = "Mean RAF: " + mean_2008_raf.toFixed(2);
        document.getElementById("2009_mean_raf").innerHTML = "Mean RAF: " + mean_2009_raf.toFixed(2);
        document.getElementById("2010_mean_raf").innerHTML = "Mean RAF: " + mean_2010_raf.toFixed(2);

        document.getElementById("count_2008").innerHTML = "Count: " + count_2008;
        document.getElementById("count_2009").innerHTML = "Count: " + count_2009;
        document.getElementById("count_2010").innerHTML = "Count: " + count_2010;

        document.getElementById("2008_stdev_raf").innerHTML = "Stdev RAF: " + std_2008_raf.toFixed(2);
        document.getElementById("2009_stdev_raf").innerHTML = "Stdev RAF: " + std_2009_raf.toFixed(2);
        document.getElementById("2010_stdev_raf").innerHTML = "Stdev RAF: " + std_2010_raf.toFixed(2);

        document.getElementById("statistics").classList.remove("invisible");
    }

    drawHist(results, isCost, target_year) {

        let data = [];
        let xLabel = "";

        let max_width = 0;

        for (let i = 0; i < results.length; i++) {

            let cost_obj = results[i].source.costPerYear;

            if (!isCost) {
                cost_obj = results[i].source.rafScores;
            }

            if (cost_obj["2008"]) {
                if (cost_obj["2008"] > max_width) {
                    max_width = cost_obj["2008"];
                }

                if (target_year === "2008") {
                    data.push({
                        "value": cost_obj["2008"],
                        "year": "2008"
                    });
                }
            }

            if (cost_obj["2009"]) {
                if (cost_obj["2009"] > max_width) {
                    max_width = cost_obj["2009"];
                }

                if (target_year === "2009") {
                    data.push({
                        "value": cost_obj["2009"],
                        "year": "2009"
                    });
                }
            }

            if (cost_obj["2010"]) {
                if (cost_obj["2010"] > max_width) {
                    max_width = cost_obj["2010"];
                }

                if (target_year === "2010") {
                    data.push({
                        "value": cost_obj["2010"],
                        "year": "2010"
                    });
                }
            }

        }

        if (isCost) {
            xLabel = "Cost";
        } else {
            xLabel = "RAF Score";
        }

        // set the dimensions and margins of the graph
        var margin = {top: 10, right: 30, bottom: 40, left: 40},
            width = 460 - margin.left - margin.right,
            height = 400 - margin.top - margin.bottom;

        let id = "cost_hist";
        if (!isCost) {
            id = "raf_hist";
        }

        let el = document.getElementById(id);
        while (el.childElementCount > 0) {
            el.removeChild(el.childNodes[0]);
        }

        // append the svg object to the body of the page
        var svg = d3.select("#" + id)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

        // X axis: scale and draw:
        var x = d3.scaleLinear()
            .domain([0, max_width])
            .range([0, width]);
        svg.append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x));

        // set the parameters for the histogram
        var histogram = d3.histogram()
            .value(function (d) {
                return d.value;
            })   // I need to give the vector of value
            .domain(x.domain())  // then the domain of the graphic
            .thresholds(x.ticks(50)); // then the numbers of bins

        // And apply this function to data to get the bins
        let bins1 = histogram(data.filter(function (d) {
            return d.year === target_year
        }));
        //let bins2 = histogram(data.filter( function(d){return d.year == "2009"} ));
        //let bins3 = histogram(data.filter( function(d){return d.year == "2010"} ));

        // Y axis: scale and draw:
        var y = d3.scaleLinear()
            .range([height, 0]);
        y.domain([0, d3.max(bins1, function (d) {
            return d.length;
        })]);   // d3.hist has to be called before the Y axis obviously
        svg.append("g")
            .call(d3.axisLeft(y));

        let color;
        switch (target_year) {
            case "2008":
                color = "#ff0000";
                break;
            case "2009":
                color = "#00ff00";
                break;
            default:
                color = "#0000ff";
        }

        svg.append("text")
            .attr("text-anchor", "end")
            .attr("x", 200)
            .attr("y", height + 30)
            .text(xLabel);

        svg.append("text")
            .attr("text-anchor", "end")
            .attr("x", -height + 200)
            .attr("y", -25)
            .attr("transform", "rotate(-90)")
            .text("Count");

        // append the bar rectangles to the svg element
        svg.selectAll("rect")
            .data(bins1)
            .enter()
            .append("rect")
            .attr("x", 1)
            .attr("transform", function (d) {
                return "translate(" + x(d.x0) + "," + y(d.length) + ")";
            })
            .attr("width", function (d) {
                return x(d.x1) - x(d.x0) - 1;
            })
            .attr("height", function (d) {
                return height - y(d.length);
            })
            .style("fill", color)
            .style("opacity", 1);

        // Handmade legend
        svg.append("circle").attr("cx", 300).attr("cy", 30).attr("r", 6).style("fill", "#ff0000");
        svg.append("circle").attr("cx", 300).attr("cy", 60).attr("r", 6).style("fill", "#00ff00");
        svg.append("circle").attr("cx", 300).attr("cy", 90).attr("r", 6).style("fill", "#0000ff");

        svg.append("text").attr("x", 320).attr("y", 30).text("2008").style("font-size", "15px").attr("alignment-baseline", "middle");
        svg.append("text").attr("x", 320).attr("y", 60).text("2009").style("font-size", "15px").attr("alignment-baseline", "middle");
        svg.append("text").attr("x", 320).attr("y", 90).text("2010").style("font-size", "15px").attr("alignment-baseline", "middle");
    }

    render() {
        return (
            <div className="container my-3">

                <h1>Advanced Cohort Search</h1>

                <div id="alert-error" className="alert alert-danger alert-dismissible d-none">
                    <strong>Error!</strong> There was an internal server error. Please try again.
                    <button type="button" className="close" onClick={() => document.getElementById('alert-error').classList.add('d-none')}>&times;</button>
                </div>

                <div className="row">
                    <div className="col-6">
                        <h3>Search for a specific patient</h3>
                        <div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label htmlFor="first_name">First Name:</label>
                                    <input type="text" id="first_name" className="form-control" spellCheck="false"/>
                                </div>
                                <div className="col-sm-6">
                                    <label htmlFor="last_name">Last Name:</label>
                                    <input type="text" id="last_name" className="form-control" spellCheck="false"/>
                                </div>
                            </div>
                            <label htmlFor="dob">Date of Birth:</label>
                            <input type="date" id="dob" className="form-control" spellCheck="false"/>

                            <label htmlFor="record_number">Medical Record Number:</label>
                            <input type="text" id="record_number" className="form-control" spellCheck="false"/>

                            <div className="text-right">
                                <button type="button" className="btn btn-primary mt-4" id="submit" onClick={this.onSpecificSearch}><i
                                    className="fa fa-spinner fa-spin d-none" id="loader"></i> Search
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="col-6">
                        <h3>Search for a cohort of patients</h3>
                        <div>

                            <div className="row">
                                <div className="col-3">
                                    <label htmlFor="sex">Sex:</label><br/>
                                    <select name="sex" id="sex">
                                        <option value="0">Include All</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </div>
                                <div className="col-3">
                                    <label htmlFor="calender_year">Calender Year:</label><br/>
                                    <select name="calender_year" id="calender_year">
                                        <option value="0">Include All</option>
                                        <option value="2008">2008</option>
                                        <option value="2009">2009</option>
                                        <option value="2010">2010</option>
                                    </select>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="orec" data-toggle="tooltip" title="Medicare qualification (OREC) original reason for entitlement">Original reason for entitlement:</label><br/>
                                    <select name="orec" id="orec">
                                        <option value="4">Include All</option>
                                        <option value="0">Old Age</option>
                                        <option value="1">Disability</option>
                                        <option value="2">ESRD</option>
                                        <option value="3">Both DIB and ESRD</option>
                                    </select>
                                </div>
                            </div>
                            <label>Payer Class:</label>
                            <div>
                                <input type="checkbox" id="ltimcaid" name="ltimcaid"/>
                                <label htmlFor="ltimcaid" style={{marginBottom: 4}}> Medicaid if number of months in Medicaid in payment year > 0</label><br/>
                                <input type="checkbox" id="nemcaid" name="nemcaid"/>
                                <label htmlFor="nemcaid" style={{marginBottom: 4}}> New Dual if number of months in Medicaid in payment year > 0</label><br/>
                            </div>

                            <label htmlFor="npi" style={{marginBottom: 4}}>National Provider Identifier:</label>
                            <input type="text" id="npi" className="form-control" spellCheck="false"/>

                            <label htmlFor="diagnosis_code_input" style={{marginBottom: 1}}>Diagnoses Codes:</label>
                            <div className="row">
                                <div className="col-10">
                                    <Typeahead id="diagnosis_code_input"
                                               onChange={(selected) => {
                                                   this.setState({selected: selected})
                                               }}
                                               labelKey="code"
                                               ref={this.ref}
                                               options={icd_codes}
                                               filterBy={['name', 'code']}
                                               renderMenuItemChildren={(option, props, index) => {
                                                   return (<span>{option.code} {option.name}</span>)
                                               }}
                                    />
                                </div>
                                <div className="col-2">
                                    <button className="btn btn-primary" id="add_code" onClick={this.onDiagnosisAdd}>Add</button>
                                </div>
                            </div>
                            <ul id="diagnosis_list" style={{marginBottom: 0}}></ul>

                            <div className="text-right">
                                <button type="button" className="btn btn-primary mt-4" id="submit_cohort" onClick={this.onCohortSearch}><i
                                    className="fa fa-spinner fa-spin d-none" id="loader_cohort"></i> Search
                                </button>
                            </div>

                        </div>
                    </div>
                </div>

                <div id="statistics" className="invisible">
                    <h5>Statistics</h5>
                    <hr/>
                    <div className="row">
                        <div className="col-3">
                            <h6>2008</h6>
                            <p id="count_2008"></p>
                            <p id="2008_mean"></p>
                            <p id="2008_stdev"></p>
                            <p id="2008_mean_raf"></p>
                            <p id="2008_stdev_raf"></p>
                        </div>
                        <div className="col-3">
                            <h6>2009</h6>
                            <p id="count_2009"></p>
                            <p id="2009_mean"></p>
                            <p id="2009_stdev"></p>
                            <p id="2009_mean_raf"></p>
                            <p id="2009_stdev_raf"></p>
                        </div>
                        <div className="col-3">
                            <h6>2010</h6>
                            <p id="count_2010"></p>
                            <p id="2010_mean"></p>
                            <p id="2010_stdev"></p>
                            <p id="2010_mean_raf"></p>
                            <p id="2010_stdev_raf"></p>
                        </div>
                        <div className="col-3">
                            <h6>Total</h6>
                            <p id="total_mean"></p>
                            <p id="total_stdev"></p>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-6">
                            <div id="cost_hist"></div>
                            <div className="text-center">
                                <label><input type="radio" name="cost_radio" value="2008" onChange={() => this.onRadioButtonChangeCost('2008')} defaultChecked/> 2008</label>&nbsp;&nbsp;
                                <label><input type="radio" name="cost_radio" value="2009" onChange={() => this.onRadioButtonChangeCost('2009')}/> 2009</label>&nbsp;&nbsp;
                                <label><input type="radio" name="cost_radio" value="2010" onChange={() => this.onRadioButtonChangeCost('2010')}/> 2010</label>
                            </div>
                        </div>
                        <div className="col-6">
                            <div id="raf_hist"></div>
                            <div className="text-center">
                                <label><input type="radio" name="raf_radio" value="2008" onChange={() => this.onRadioButtonChangeRaf('2008')} defaultChecked/> 2008</label>&nbsp;&nbsp;
                                <label><input type="radio" name="raf_radio" value="2009" onChange={() => this.onRadioButtonChangeRaf('2009')}/> 2009</label>&nbsp;&nbsp;
                                <label><input type="radio" name="raf_radio" value="2010" onChange={() => this.onRadioButtonChangeRaf('2010')}/> 2010</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <h5>Results</h5>
                    <div className="table" id="table-sortable">
                        <SmartDataTable
                            name={this.state.table.title}
                            orderedHeaders={this.state.table.headers}
                            headers={this.state.table.headerMap}
                            data={this.state.table.data}
                            perPage={this.state.table.perPageItemCount}
                            sortable={true}
                        />
                    </div>
                </div>

                <div className="text-right my-4">
                    <a id="download_results" className="btn btn-info invisible" download>Download Results</a>
                </div>

                <div style={{height: 300}}></div>

            </div>
        );
    }

}