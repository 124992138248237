import React from 'react';

const SimilaritySlider = ({ score }) => {
  // Ensure the score is within the valid range
  const clampedScore = Math.max(0, Math.min(1, score));
  
  // Calculate the position of the slider (0-100%)
  const sliderPosition = (clampedScore * 100);

  // Create the gradient string
  const gradientStyle = {
    background: `linear-gradient(to right, 
      #FF0000 0%, 
      #D3D3D3 50%, 
      #008000 100%)`
  };

  return (
    <div className="similarity-slider-container">
      <div className="slider-labels">
        {/* <span className="label-dissimilar">Dissimilar</span> */}
        <span className="label-unrelated">Unrelated</span>
        <span className="label-identical">Identical</span>
      </div>
      <div className="similarity-slider" style={gradientStyle}>
        <div 
          className="slider-thumb"
          style={{ left: `${sliderPosition}%` }}
        ></div>
      </div>
      <div className="slider-value">Similarity Score: {clampedScore.toFixed(2)}</div>
    </div>
  );
};

export default SimilaritySlider;