import React from "react";
import * as d3 from "d3";
import './react-smart-data-table.css';
import SmartDataTable from 'react-smart-data-table';
import $ from "jquery";
import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import riak_codes from "./json/riak_codes";

export class Physician extends React.Component {
    constructor(props) {
        super(props);

        this.degree = 0;
        this.icd_obj = null;
        this.queue = [];
        this.isExecuting = false;
        this.selectedRiak = null;
        this.zipDistance = 100;
        this.selectedNode = null;
        this.selectedNpi = null;
        this.ref = React.createRef();

        this.state = {
            table: {
                title: "Table",
                headers: ["npi", "first_name", "last_name", "zip", "distance", "patients", "cost", "mean_cost", "view", "directions"],
                headerMap: {
                    'npi': {text: "National Provider Identifier"},
                    'first_name': {text: "First Name"},
                    'last_name': {text: "Last Name"},
                    'zip': {text: "Zip Code"},
                    'distance': {text: "Distance (KM)"},
                    'patients': {text: "Total Patients"},
                    'cost': {text: "Total Cost"},
                    'mean_cost': {text: 'Mean Cost Per Patient'},
                    'view': {
                        text: "View In Plot",
                        transform: (value, index, row) => (<button className="btn btn-info" onClick={() => this.onRowClick(value)}>View</button>)
                    },
                    'directions': {
                        text: "Google Map",
                        transform: (value, index, row) => (<a className="btn btn-info" href={value} target="_blank" rel="noopener noreferrer">Directions</a>)
                    }
                },
                perPageItemCount: 10,
                pagination: true,
                data: []
            }
        };

        this.httpRequest = this.httpRequest.bind(this);
        this.httpAutocompleteRequest = this.httpAutocompleteRequest.bind(this);
        this.updateIcdBox = this.updateIcdBox.bind(this);
        this.onRiakChange = this.onRiakChange.bind(this);
        this.nextTask = this.nextTask.bind(this);
        this.doTasks = this.doTasks.bind(this);
        this.onSlideChange = this.onSlideChange.bind(this);
        this.onZipSlideChange = this.onZipSlideChange.bind(this);
        this.onPhysicianSearch = this.onPhysicianSearch.bind(this);
        this.drawScatterPlot = this.drawScatterPlot.bind(this);
        this.loadLegend = this.loadLegend.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.updateTable = this.updateTable.bind(this);
    }

    httpRequest(endpoint, params, callback) {

        var xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = function () {
            if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
                callback(JSON.parse(xmlHttp.response));
            } else if (xmlHttp.readyState === 4) {

                if (xmlHttp.status === 400) {
                    // something went wrong with the data
                    $('#alert-no-results').removeClass('d-none');
                } else {
                    // something went wrong with the backend
                    $('#alert-error').removeClass('d-none');
                }

                $('#loader').addClass('d-none');
            }
        };

        let url = new URL(endpoint);
        url.searchParams.append("params", JSON.stringify(params));

        xmlHttp.open("GET", url.toString(), true);
        xmlHttp.setRequestHeader("Authorization", "Bearer 4e11f4f5-cabb-45f6-b042-fafd7e464b99");
        xmlHttp.send(null);
    }

    httpAutocompleteRequest(endpoint, callback) {
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = function () {
            if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
                callback(JSON.parse(xmlHttp.response));
            } else if (xmlHttp.readyState === 4) {

                if (xmlHttp.status === 400) {
                    // something went wrong with the data
                    $('#alert-no-results').removeClass('d-none');
                } else {
                    // something went wrong with the backend
                    $('#alert-error').removeClass('d-none');
                }

                $('#loader').addClass('d-none');
            }
        };

        xmlHttp.open("GET", endpoint, true);
        xmlHttp.send(null);
    }

    componentDidMount() {

        if (navigator.geolocation) {
            let ctrl = this;
            navigator.geolocation.getCurrentPosition(function (result) {
                let params = {
                    "lat": result.coords.latitude,
                    "lon": result.coords.longitude
                };
                ctrl.httpRequest("https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getLocationFromCoordinates", params, function (result) {
                    if (result.results.length > 0) {
                        let address = result.results[0].description;
                        const regex = /\d{5}/;
                        const found = address.match(regex);

                        if (found.length > 0) {
                            let zip = found[0];
                            document.getElementById("location").value = zip;
                        }
                    }
                    $('#loader_zip').addClass('d-none');
                })
            });
        } else {
            $('#loader_zip').addClass('d-none');
        }

        const urlParams = new URLSearchParams(window.location.search);
        let riak = urlParams.get("riak");
        if (riak) {
            this.ref.current.getInput().value = riak;
            this.onRiakChange();
        }

        $('[data-toggle="tooltip"]').tooltip();
        this.loadLegend();

    }

    updateIcdBox() {

        let codes = [];
        let count = 0;
        if (this.icd_obj) {

            switch (this.degree) {
                case 0:
                    codes = this.icd_obj.degree0.icds;
                    count = this.icd_obj.degree0.patientCount;
                    break;
                case 1:
                    codes = this.icd_obj.degree1.icds;
                    count = this.icd_obj.degree1.patientCount;
                    break;
                default:
                    codes = this.icd_obj.degree2.icds;
                    count = this.icd_obj.degree2.patientCount;
                    break;
            }
        }
        document.getElementById("icd_list").innerHTML = codes.join(", ");
        document.getElementById("patient_count").innerHTML = count;

        $('#loader_icd').addClass('d-none');
    }

    onRiakChange() {
        let params = {};
        params["riakId"] = this.ref.current.getInput().value;

        if (params["riakId"] === this.selectedRiak) {
            return;
        }

        this.selectedRiak = params["riakId"];

        $('#loader_icd').removeClass('d-none');
        this.riakChanged = true;

        let ctrl = this;
        let task = function () {
            ctrl.httpRequest("https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getIcdFromRiak", params, function (response) {
                if (response.results) {
                    ctrl.icd_obj = response.results[0];
                } else {
                    ctrl.icd_obj = null;
                }
                ctrl.updateIcdBox();
                ctrl.riakChanged = false;
                ctrl.nextTask();
            });
        };

        this.queue.unshift(task);

        if (!this.isExecuting) {
            this.isExecuting = true;
            this.nextTask();
        }

    }

    nextTask() {

        if (this.queue.length !== 0) {
            this.doTasks();
        } else {
            this.isExecuting = false;
        }
    }

    doTasks() {
        let task = this.queue.pop();
        task();
    }

    onSlideChange() {
        let slider = document.getElementById("myRange");
        this.degree = parseInt(slider.value);

        this.updateIcdBox();
    }

    onZipSlideChange() {
        let slider = document.getElementById("zipRange");
        this.zipDistance = parseInt(slider.value);

        document.getElementById("zipDistance").innerHTML = this.zipDistance;
    }

    onPhysicianSearch() {

        $('#loader').removeClass('d-none');

        let params = {};
        let zip = document.getElementById("location").value;
        let riak = this.ref.current.getInput().value;

        if (zip) {
            params["zipCode"] = zip;
        }
        //params["size"] = 10;
        params["riakId"] = riak;
        params["degree"] = this.degree;

        let ctrl = this;
        let task = function () {

            if (!ctrl.icd_obj) {
                alert("No ICD codes were found for the selected Riak.");
                $('#loader').addClass('d-none');
                ctrl.nextTask();
                return;
            }

            let icds = ctrl.icd_obj.degree0.icds;
            if (ctrl.degree === 1) {
                icds = ctrl.icd_obj.degree1.icds;
            }
            if (ctrl.degree === 2) {
                icds = ctrl.icd_obj.degree2.icds;
            }

            params["icds"] = icds;

            if (icds) {

                if (zip) {
                    ctrl.httpRequest("https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getPhysicianCoordinatesAndDistance", params, function (response) {

                        if (response.results && Object.keys(response.results).length !== 0) {
                            let data = response.results.splice(1);
                            ctrl.updateTable(data);
                            ctrl.drawScatterPlot(data);
                        }
                        $('#loader').addClass('d-none');
                        ctrl.nextTask();
                    });
                } else {
                    ctrl.httpRequest("https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getPhysicianCoordinatesv2", params, function (response) {

                        if (response.results && Object.keys(response.results).length !== 0) {
                            let data = response.results.splice(1);
                            ctrl.updateTable(data, true);
                            ctrl.drawScatterPlot(data, true);
                        }
                        $('#loader').addClass('d-none');
                        ctrl.nextTask();
                    });
                }
            } else {
                $('#loader').addClass('d-none');
                ctrl.nextTask();
            }
        };

        this.queue.unshift(task);

        if (!this.isExecuting) {
            this.isExecuting = true;
            this.nextTask();
        }

    }

    drawScatterPlot(data, noZip) {

        document.getElementById("legend").classList.remove("invisible");

        let margin = {top: 10, right: 50, bottom: 30, left: 50};
        const height = 500 - margin.top - margin.bottom;
        const width = height;

        let el = document.getElementById("plot");
        while (el.childElementCount > 0) {
            el.removeChild(el.childNodes[0]);
        }

        let max_patients = 0;
        let max_cost = 0;
        let total_patients = 0;
        let total_cost = 0;
        for (let i = 0; i < data.length; i++) {
            if (data[i].patients > max_patients) {
                max_patients = data[i].patients;
            }
            if (data[i].cost > max_cost) {
                max_cost = data[i].cost;
            }
            total_patients += data[i].patients;
            total_cost += data[i].cost;

            if (data[i].cost < 0) {
                data.splice(i, 1);
                i--;
            }
        }

        //max_patients += 10;
        //max_cost += 10;

        let mean_slope = total_cost / total_patients;
        let avg_cost = max_cost / data.length;

        let stdev = 0;

        for (let i = 0; i < data.length; i++) {
            stdev += Math.pow(data[i].cost - avg_cost, 2);
        }

        stdev = Math.sqrt(stdev / data.length);

        document.getElementById("mean_output").value = "Mean cost per patient: " + parseInt(mean_slope);
        document.getElementById("stdev_output").value = "Stdev cost: " + parseInt(stdev);

        // append the svg object to the body of the page
        var svg = d3.select("#plot")
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

        // Add X axis
        var x = d3.scaleLinear()
            .domain([0, max_patients])
            .range([0, width]);
        svg.append("g")
            .attr("transform", "translate(0," + (height - margin.bottom) + ")")
            .call(d3.axisBottom(x).ticks(1));

        svg.append("text")
            .attr("text-anchor", "end")
            .attr("x", 150)
            .attr("y", height - 15)
            .text("Patient Volume");

        // Add Y axis
        var y = d3.scaleLinear()
            .domain([0, max_cost])
            .range([height - margin.bottom, 0]);
        svg.append("g")
            .call(d3.axisLeft(y).ticks(1));
        svg.append("text")
            .attr("text-anchor", "end")
            .attr("x", -height + 215)
            .attr("y", -3)
            .attr("transform", "rotate(-90)")
            .text("Combined Patient Cost");

        var tooltip = d3.select("#plot")
            .append("div")
            .style("display", "none")
            .style("opacity", 1)
            .attr("class", "tooltip")
            .style("background-color", "white")
            .style("border", "solid")
            .style("border-width", "1px")
            .style("border-radius", "5px")
            .style("padding", "10px");


        // A function that change this tooltip when the user hover a point.
        // Its opacity is set to 1: we can now see it. Plus it set the text and position of tooltip depending on the datapoint (d)
        var mouseover = function (d) {
            tooltip.style("display", "block");

            if (d.physician.npi === this.selectedNpi) {
                d3.select(this).attr("r", 20);
            } else {
                d3.select(this).attr("r", 10);
            }

        };

        var mousemove = function (d) {

            let template = `(${d.patients}, ${d.cost})<br>
                            NPI: ${d.physician.npi}<br>
                            Name: ${d.physician.firstName} ${d.physician.lastName}<br>
                            Cost Per Patient: ${parseInt(d.cost / d.patients)}`;

            tooltip
                .html(template)
                .style("left", (d3.mouse(this)[0] + 90) + "px") // It is important to put the +90: other wise the tooltip is exactly where the point is an it creates a weird effect
                .style("top", (d3.mouse(this)[1]) + "px")
        };

        // A function that change this tooltip when the leaves a point: just need to set opacity to 0 again
        var mouseleave = function (d) {

            tooltip.style("display", "none");

            if (d.physician.npi === this.selectedNpi) {
                d3.select(this)
                    .transition()
                    .duration(200)
                    .attr("r", 15);
            } else {
                d3.select(this)
                    .transition()
                    .duration(200)
                    .attr("r", 5);
            }

        };

        function getColor() {

            return 'hsla(' + (Math.random() * 360) + ', 100%, 50%, 0.8)';
            //return "#E2AA53";
        }

        let data_subset = [];

        for (let i = 0; i < data.length; i++) {
            if (noZip) {
                data_subset.push(data[i]);
            } else if (data[i].physician.distanceKm && data[i].physician.distanceKm <= this.zipDistance) {
                data_subset.push(data[i]);
            }
        }

        // Add dots
        svg.append('g')
            .selectAll("dot")
            .data(data_subset)
            .enter()
            .append("circle")
            .attr("cx", function (d) {
                return x(d.patients);
            })
            .attr("cy", function (d) {
                return y(d.cost);
            })
            .attr("r", 5)
            .style("fill", getColor)
            .style("opacity", 1)
            .style("stroke-width", 1)
            .style("stroke", "#000000")
            .attr("id", function (d) {
                return d.physician.npi + "_node";
            })
            .on("mouseover", mouseover)
            .on("mousemove", mousemove)
            .on("mouseleave", mouseleave);

        svg.append('g')
            .append("line")
            .attr('x1', x(0))
            .attr('x2', x(max_patients))
            .attr('y1', y(0))
            .attr('y2', y(parseInt(mean_slope * max_patients)))
            .style("stroke", "#FF0000");

        let x_offset = stdev / mean_slope;

        svg.append('g')
            .append("line")
            .attr('x1', x(x_offset))
            .attr('x2', x(max_patients))
            .attr('y1', y(0))
            .attr('y2', y(parseInt(mean_slope * max_patients - stdev)))
            .style("stroke", "#FF0000")
            .style("stroke-dasharray", "10, 10");

        svg.append('g')
            .append("line")
            .attr('x1', x(0))
            .attr('x2', x(max_patients))
            .attr('y1', y(stdev))
            .attr('y2', y(parseInt(mean_slope * max_patients + stdev)))
            .style("stroke", "#FF0000")
            .style("stroke-dasharray", "10, 10");

    }

    loadLegend() {
        let el = document.getElementById('legend');
        const width = el.offsetWidth;
        const height = 30;

        let svg = d3.select("#legend")
            .append("svg")
            .attr("width", width)
            .attr("height", height);

        svg.append("line")
            .attr("x1", 50)
            .attr("x2", 70)
            .attr("y1", 17)
            .attr("y2", 17)
            .attr("stroke", "#FF0000");
        svg.append("text")
            .text("Mean physician cost per patient")
            .attr('font-size', 10)
            .attr('dx', 75)
            .attr('dy', 20)
            .attr('opacity', 1);

    }

    onRowClick(npi) {
        if (this.selectedNode) {
            this.selectedNode.setAttribute("r", 5);
        }

        this.selectedNode = document.getElementById(npi + "_node");
        this.selectedNode.setAttribute("r", 15);
        this.selectedNpi = npi;
    }

    updateTable(results, noZip) {

        let data = [];
        for (let i = 0; i < results.length; i++) {

            let link = "";

            if (!noZip) {
                if (!results[i].physician.distanceKm || results[i].physician.distanceKm > this.zipDistance) {
                    continue;
                }

                let url = new URL("https://www.google.com/maps/dir/?api=1");
                url.searchParams.append("origin", document.getElementById("location").value);
                url.searchParams.append("destination", results[i].physician.zipCode);
                link = url.toString();

            } else {
                link = `https://www.google.com/maps/dir/+${results[i].physician.zipCode}`;
            }

            let npi = results[i].physician.npi;

            data.push({
                'first_name': results[i].physician.firstName,
                'last_name': results[i].physician.lastName,
                'npi': npi,
                'view': npi,
                'patients': results[i].patients,
                'cost': results[i].cost,
                'mean_cost': parseInt(results[i].cost / results[i].patients),
                'zip': results[i].physician.zipCode ? results[i].physician.zipCode : "N/A",
                'distance': results[i].physician.distanceKm ? results[i].physician.distanceKm : "N/A",
                'directions': link
            });
        }

        document.getElementById("patient_count").innerHTML = data.length;

        let table = this.state.table;
        table.data = data;
        this.setState({table: table});
    }

    render() {
        return (
            <div className="container my-3">

                <h1>Advanced Physician Search</h1>

                <div className="row">
                    <div className="col-6">
                        <label htmlFor="location">Zip Code: <i className="fa fa-spinner fa-spin" id="loader_zip"></i></label>
                        <input type="text" id="location" className="form-control" spellCheck="false"/>

                        <label htmlFor="zipRange" className="form-check-label" data-toggle="tooltip" title="Adjust this slider to change the maximum
            distance to find a physician.">Distance:</label><br/>
                        <input type="range" min="0" max="1000" defaultValue="100" className="slider" id="zipRange" onInput={this.onZipSlideChange}/>
                        <output id="zipDistance">100</output>
                        <span>&nbsp;km</span>
                        <br/><br/>

                        <label htmlFor="diagnosis_code_input" style={{marginBottom: 4}}>Disease:</label>
                        <Typeahead id="diagnosis_code_input"
                                   onChange={(selected) => {
                                       this.onRiakChange();
                                   }}
                                   labelKey="code"
                                   ref={this.ref}
                                   options={riak_codes}
                                   filterBy={['name', 'code']}
                                   renderMenuItemChildren={(option, props, index) => {
                                       return (<span>{option.code} ({option.name})</span>)
                                   }}
                        />

                        <label htmlFor="myRange" className="form-check-label" data-toggle="tooltip" title="Adjust this slider to change the
            depth if the physician search. Smaller depth produces less results, and higher depth produces more
            results.">Search Depth:</label><br/>
                        <input type="range" min="0" max="2" defaultValue="0" className="slider" id="myRange" onInput={this.onSlideChange}/><br/><br/>

                        <label>ICD Code Search Criteria (Based on Riak and Depth) <i className="fa fa-spinner fa-spin d-none" id="loader_icd"></i></label>
                        <div className="border">
                            <p id="icd_list"></p>
                        </div>
                        <label>Patient Count: <span id="patient_count">0</span></label>

                        <div className="text-right">
                            <button type="button" className="btn btn-primary mt-4" id="submit_physician" onClick={this.onPhysicianSearch}><i
                                className="fa fa-spinner fa-spin d-none" id="loader"></i> Search
                            </button>
                        </div>
                    </div>
                    <div className="col-6">
                        <div id="plot"></div>
                        <div id="legend" className="invisible"></div>
                        <div>
                            <output id="mean_output" style={{marginLeft: 50}}></output>
                            <output id="stdev_output" style={{marginLeft: 50}}></output>
                        </div>
                    </div>
                </div>

                <div>
                    <h5>Results</h5>
                    <input className="form-control col-3" type="text" placeholder="Search in table..." id="searchField"/>
                    <div className="table" id="table-sortable">
                        <SmartDataTable
                            name={this.state.table.title}
                            orderedHeaders={this.state.table.headers}
                            headers={this.state.table.headerMap}
                            data={this.state.table.data}
                            perPage={this.state.table.perPageItemCount}
                            sortable={true}
                        />
                    </div>
                </div>

                <div style={{height: 380}}></div>

            </div>
        );
    }
}