import React from "react";
import $ from "jquery";
import packageJson from '../package.json';

export class Documentation extends React.Component {

    constructor(props) {
        super(props);

        window.expandAll = this.expandAll;
        window.collapseAll = this.collapseAll;
    }

    expandAll() {
        $(".collapse").collapse("show");
    }

    collapseAll() {
        $(".collapse").collapse("hide");
    }

    componentDidMount() {

        let api_doc_string = `
        <div class="col-3" style="background: #6EB1E1">
        <div class="sticky-top m-3">
            <h1>Documentation</h1>
            <hr>
            <ul id="documentation">
                <li><a href="#new-api1">Health Concept Extractor</a></li>
                <li><a href="#new-api2">Keyword Search</a></li>
                <li><a href="#new-api3">Random Narrative</a></li>
                
                <!--
                <li><a href="#api1">Deidentified Patient Search</a></li>
                <li><a href="#api2">Send message</a></li>
                <li><a href="#api3">Add Patient Encounter</a></li>
                <li><a href="#api4">Add Patient</a></li>
                <li><a href="#api5">Get Columns From Icd Ten</a></li>
                <li><a href="#api6">Get Physician Coordinates</a></li>
                <li><a href="#api7">Get Physician Coordinates and Distance</a></li>
                <li><a href="#api8">Get Location From Coordinates</a></li>
                <li><a href="#api9">Get Icd From Riak</a></li>
                <li><a href="#api10">Get Icd Parent Chain</a></li>
                <li><a href="#api11">Get Cpt Parent Chain</a></li>
                <li><a href="#api12">Get IcdTen Dependant Count</a></li>
                <li><a href="#api13">Get Cpt Dependant Count</a></li>
                <li><a href="#api14">Get Cpt Root</a></li>
                <li><a href="#api15">Get Icd Root</a></li>
                <li><a href="#api16">Get Cpt Children</a></li>
                <li><a href="#api17">Get Icd Children</a></li>
                <li><a href="#api18">Find Icd By Cpt</a></li>
                <li><a href="#api19">Get Icd By Name</a></li>
                <li><a href="#api20">Get Cpt By Name</a></li>
                <li><a href="#api21">Get User By Grn</a></li>
                <li><a href="#api22">Select Patient</a></li>
                <li><a href="#api23">Get Modifiers For Cpt</a></li>
                <li><a href="#api24">Accepted Eula</a></li>
                <li><a href="#api25">Agree to Eula</a></li>
                <li><a href="#api26">Global Period</a></li>
                -->
            </ul>
        </div>
    </div>
    <div class="col">
        <div class="row my-3">
            <div class="col-6"><h1>API</h1></div>
            <div class="col text-right">
                <button type="button" class="btn btn-primary" onclick="expandAll();">Expand All</button>
                <button type="button" class="btn btn-primary" onclick="collapseAll();">Collapse All</button>
            </div>
        </div>
        <hr>
        <div class="container my-3">
            
            <h2 id="new-api1">Health Concept Extractor</h2>
            <p>Predict entities with labels and suggest closest matching taxonomy phrases.</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">REPLACE_WITH_API1.0/journey/query/phrase</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapseNarrativeCapture"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapseNarrativeCapture" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>phrase</td>
                                <td>string</td>
                                <td>Clinical Narrative text in which to predict entities & taxonomy phrase matches</td>
                            </tr>
                            <tr>
                                <td>taxonomy</td>
                                <td>string</td>
                                <td>Taxonomy to search within. Allowed values: "all", "snomed", "umls"</td>
                            </tr>
                            <tr>
                                <td>chunk_phrase</td>
                                <td>boolean</td>
                                <td>Flag that must be set to "true" for the Health Concept Extractor endpoint</td>
                            </tr>
                            <tr>
                                <td>max_neighbors</td>
                                <td>integer</td>
                                <td>Maximum # of nearest neighbors to return for each phrase, accepts range 1-50</td>
                            </tr>
                            </tbody>
                        </table>

                        <table class="table table-striped table-bordered table-hover table-responsive">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td>
                                <pre>
                                POST
                                REPLACE_WITH_API1.0/journey/query/phrase

                                Access-Key: YOUR_ACCCESS_KEY

                                Body                    
                                {
                                    "phrase": "PREOPERATIVE DIAGNOSIS: Obstructive sleep apnea syndrome with...",
                                    "taxonomy": "all",
                                    "chunk_phrase": true
                                }</pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td>
                                    <pre>{
                                        "Obstructive sleep apnea_0": {
                                            "end": 23,
                                            "label": "DISORDER",
                                            "negative": false,
                                            "phrase": "Obstructive sleep apnea",
                                            "results": [{
                                                "aliases": [],
                                                "distance": 124.16719818115234,
                                                "id": "G47.33",
                                                "phrase": "Obstructive sleep apnea hypopnea",
                                                "semantic-idf": 124.16719818115234,
                                                "source": "Base KNN",
                                                "specific": "Y",
                                                "tf-idf": 0.28160322880806676,
                                                "vocab": "icd10cm"
                                            }, {
                                                "aliases": [],
                                                "distance": 157.62603759765625,
                                                "id": "G47.3",
                                                "phrase": "Sleep apnea",
                                                "semantic-idf": 157.62603759765625,
                                                "source": "Base KNN",
                                                "specific": "N",
                                                "tf-idf": 0.3921230627595711,
                                                "vocab": "icd10cm"
                                            }, {
                                                "aliases": [],
                                                "distance": 161.692626953125,
                                                "id": "G47.30",
                                                "phrase": "Sleep apnea, unspecified",
                                                "semantic-idf": 161.692626953125,
                                                "source": "Base KNN",
                                                "specific": "Y",
                                                "tf-idf": 0.6768015618048314,
                                                "vocab": "icd10cm"
                                            }, {
                                                "aliases": [],
                                                "distance": 178.5752410888672,
                                                "id": "G47.31",
                                                "phrase": "Primary central sleep apnea",
                                                "semantic-idf": 178.5752410888672,
                                                "source": "Base KNN",
                                                "specific": "Y",
                                                "tf-idf": 0.7533707202054126,
                                                "vocab": "icd10cm"
                                            }, {
                                                "aliases": [],
                                                "distance": 191.63204956054688,
                                                "id": "G47.39",
                                                "phrase": "Other sleep apnea",
                                                "semantic-idf": 191.63204956054688,
                                                "source": "Base KNN",
                                                "specific": "Y",
                                                "tf-idf": 0.6768015618048314,
                                                "vocab": "icd10cm"
                                            }],
                                            "shared_filters": {},
                                            "start": 0,
                                            "stat_pearls": {
                                                "aliases": [],
                                                "distance": 79.55917358398438,
                                                "id": "https://www.ncbi.nlm.nih.gov/books/n/statpearls/article-26084/",
                                                "phrase": "Obstructive Sleep Apnea",
                                                "semantic-idf": 79.55917358398438,
                                                "source": "Base KNN",
                                                "tf-idf": 0,
                                                "vocab": "stat pearls"
                                            }
                                        }
}</pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>




            <h2 id="new-api2">Semantic Search</h2>
            <p>Search taxonomies by keyword.</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">REPLACE_WITH_API1.0/journey/query/phrase</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapseKeywordSearch"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapseKeywordSearch" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>phrase</td>
                                <td>string</td>
                                <td>Text keyword(s) to search for</td>
                            </tr>
                            <tr>
                                <td>max_neighbors</td>
                                <td>integer</td>
                                <td>Maximum # of nearest neighbors to return for each phrase, accepts range 1-140</td>
                            </tr>
                            <tr>
                                <td>filters</td>
                                <td>object</td>
                                <td>Object containing boolean flags for each taxonomy filter</td>
                            </tr>
                            <tr>
                                <td colspan="3"><strong>Filter Parameters:</strong></td>
                            </tr>
                            <tr>
                                <td>cpt</td>
                                <td>boolean</td>
                                <td>Include Current Procedural Terminology (CPT) in search</td>
                            </tr>
                            <tr>
                                <td>icd10cm</td>
                                <td>boolean</td>
                                <td>Include International Classification of Diseases, 10th Revision, Clinical Modification (ICD-10-CM) in search</td>
                            </tr>
                            <tr>
                                <td>icd10pcs</td>
                                <td>boolean</td>
                                <td>Include International Classification of Diseases, 10th Revision, Procedure Coding System (ICD-10-PCS) in search</td>
                            </tr>
                            <tr>
                                <td>snomed</td>
                                <td>boolean</td>
                                <td>Include Systematized Nomenclature of Medicine (SNOMED) in search</td>
                            </tr>
                            <tr>
                                <td>rxnorm</td>
                                <td>boolean</td>
                                <td>Include RxNorm standardized medication nomenclature in search</td>
                            </tr>
                            <tr>
                                <td>nucc</td>
                                <td>boolean</td>
                                <td>Include National Uniform Claim Committee (NUCC) codes in search</td>
                            </tr>
                            <tr>
                                <td>umls</td>
                                <td>boolean</td>
                                <td>Include Unified Medical Language System (UMLS) in search</td>
                            </tr>
                            </tbody>
                        </table>

                        <table class="table table-striped table-bordered table-hover table-responsive">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td>
                                <pre>
                                POST
                                REPLACE_WITH_API1.0/journey/query/phrase

                                Access-Key: YOUR_ACCCESS_KEY

                                Body                    
                                {
                                    "phrase": "ocd",
                                    "filters": {
                                        "cpt": true,
                                        "icd10cm": true,
                                        "icd10pcs": false,
                                        "snomed": true,
                                        "rxnorm": true,
                                        "nucc": false,
                                        "umls": false
                                    }
                                }</pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td>
                                    <pre>{
                            "ocd": {
                                "results": [
                                    {
                                        "aliases": [],
                                        "distance": 142.52320861816406,
                                        "id": "191736004",
                                        "phrase": "Obsessive-compulsive disorder (disorder)",
                                        "semantic-idf": 142.52320861816406,
                                        "source": "Base KNN",
                                        "specific": "Y",
                                        "tf-idf": 1.0,
                                        "vocab": "snomed"
                                    },
                                    {
                                        "aliases": [],
                                        "distance": 146.30337524414062,
                                        "id": "191738003",
                                        "phrase": "Obsessional neurosis (disorder)",
                                        "semantic-idf": 146.30337524414062,
                                        "source": "Base KNN",
                                        "specific": "Y",
                                        "tf-idf": 1.0,
                                        "vocab": "snomed"
                                    },
                                    ...
                                ]
                            }
}</pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>


            <h2 id="new-api3">Random Narrative</h2>
            <p>Retrieve a random medical narrative.</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">REPLACE_WITH_API1.0/random_narrative</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapseRandomNarrative"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapseRandomNarrative" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td>
                                <pre>
                                GET
                                REPLACE_WITH_API1.0/random_narrative

                                Access-Key: YOUR_ACCESS_KEY</pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td>
                                    <pre>{
                "narrative": "EXAM: Transesophageal echocardiogram..."
}</pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

        

            <!-- 
            <h2 id="api1">Deidentified Patient Search</h2>
            <p>Used in the Journey site under the cohort selection.</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/search/executeSearch/medic-prod-neo/medic-prod-index-policy/deidentifiedPatientSearch</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapseOne"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapseOne" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Parameter</th>
                                    <th>Data Type</th>
                                    <th>Meaning</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>size</td>
                                    <td>integer</td>
                                    <td>Number of patient results to display</td>
                                </tr>
                                <tr>
                                    <td>orec</td>
                                    <td>
                                        string
                                        <p>available values:</p>
                                        <ul>
                                            <li><code>"0"</code></li>
                                            <li><code>"1"</code></li>
                                        </ul>
                                    </td>
                                    <td>Specify patient’s orec value</td>
                                </tr>
                                <tr>
                                    <td>ltimcaid</td>
                                    <td>
                                        string
                                        <p>available values:</p>
                                        <ul>
                                            <li><code>"0"</code></li>
                                            <li><code>"1"</code></li>
                                        </ul>
                                    </td>
                                    <td>Specify patient’s ltimcaid value</td>
                                </tr>
                                <tr>
                                    <td>nemcaid</td>
                                    <td>
                                        string
                                        <p>available values:</p>
                                        <ul>
                                            <li><code>"0"</code></li>
                                            <li><code>"1"</code></li>
                                        </ul>
                                    </td>
                                    <td>Specify patient’s nemcaid value</td>
                                </tr>
                                <tr>
                                    <td>year</td>
                                    <td>string</td>
                                    <td>Calendar year that we want to filter results on</td>
                                </tr>
                                <tr>
                                    <td>patientId</td>
                                    <td>string</td>
                                    <td>Patient’s Id value</td>
                                </tr>
                                <tr>
                                    <td>dob</td>
                                    <td>
                                        string
                                        <p>format:</p>
                                        <ul>
                                            <li><code>"MM-dd-YYYY"</code></li>
                                        </ul>
                                    </td>
                                    <td>Patient’s date of birth</td>
                                </tr>
                                <tr>
                                    <td>gender</td>
                                    <td>
                                        string
                                        <p>format:</p>
                                        <ul>
                                            <li><code>"Male"</code></li>
                                            <li><code>"Female"</code></li>
                                        </ul>
                                    </td>
                                    <td>Specify patient's sex</td>
                                </tr>
                                <tr>
                                    <td>diagnoses</td>
                                    <td>string</td>
                                    <td>List of ICD diagnoses, separated by " "</td>
                                </tr>
                                <tr>
                                    <td>similarDiagnoses</td>
                                    <td>string</td>
                                    <td>List of ICD diagnoses, separated by " ". This returns a greater set of patients than diagnoses</td>
                                </tr>
                                <tr>
                                    <td>broadDiagnoses</td>
                                    <td>string</td>
                                    <td>List of ICD diagnoses, separated by " ".  This returns a greater set of patients than similarDiagnoses</td>
                                </tr>
                                <tr>
                                    <td>physicians</td>
                                    <td>string</td>
                                    <td>List of physicians that have treated the patient, separated by " "</td>
                                </tr>
                                <tr>
                                    <td>partnerPhysicians</td>
                                    <td>string</td>
                                    <td>List of physicians that have treated the patient, separated by " ". This returns patients that have been treated by the entered physicians or treated by the entered physician’s partners.</td>
                                </tr>
                                <tr>
                                    <td>sortProp</td>
                                    <td>
                                        string
                                        <p>available values:</p>
                                        <ul>
                                            <li><code>gender</code></li>
                                            <li><code>patientId</code></li>
                                            <li><code>dateOfBirth</code></li>
                                            <li><code>dateOfDeath</code></li>
                                            <li><code>expectedCostPerYear.2008</code></li>
                                            <li><code>expectedCostPerYear.2010</code></li>
                                            <li><code>costPerYear.2008</code></li>
                                            <li><code>costPerYear.2009</code></li>
                                            <li><code>costPerYear.2010</code></li>
                                            <li><code>oePerYear.2008</code></li>
                                            <li><code>oePerYear.2009</code></li>
                                            <li><code>oePerYear.2010</code></li>
                                            <li><code>rafScores.2008</code></li>
                                            <li><code>rafScores.2009</code></li>
                                            <li><code>rafScores.2010</code></li>
                                        </ul>
                                    </td>
                                    <td>Specify the value that we will sort by</td>
                                </tr>
                                <tr>
                                    <td>sortDirection</td>
                                    <td>
                                        string
                                        <p>format:</p>
                                        <ul>
                                            <li><code>ASC</code></li>
                                            <li><code>DESC</code></li>
                                        </ul>
                                    </td>
                                    <td>Specify the order the sort should be</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover table-responsive">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>GET
                                    https://medic-prod-api.graphgrid.com/1.0/search/executeSearch/medic-prod-neo/medic-prod-index-policy/deidentifiedPatientSearch?params=%7B%0A%20%20%20%20%20%20%20%22orec%22%3A%20%220%22%2C%0A%20%20%20%20%20%20%20%22ltimcaid%22%3A%20%221%22%2C%0A%20%20%20%20%20%20%20%22nemcaid%22%3A%20%220%22%2C%0A%20%20%20%20%20%20%20%22year%22%3A%20%222008%22%2C%0A%20%20%20%20%20%20%20%22patientId%22%3A%20%220065A917BBAEC579%22%2C%0A%20%20%20%20%20%20%20%22dob%22%3A%20%2212-01-1994%22%2C%0A%20%20%20%20%20%20%20%22gender%22%3A%20%22Male%22%2C%0A%20%20%20%20%20%20%20%22diagnoses%22%3A%20%22I10%20D64.0%22%2C%0A%20%20%20%20%20%20%20%22physicians%22%3A%20%22128374%22%2C%0A%20%20%20%20%20%20%20%22size%22%3A%2010%2C%0A%20%20%20%20%20%20%20%22sortProp%22%3A%20%22patientId.keyword%22%2C%0A%20%20%20%20%20%20%20%22sortOrder%22%3A%20%22ASC%22%2C%0A%20%20%20%7D%0A

                                    Non-encoded params
                                    {
                                    "orec": "0",
                                    "ltimcaid": "1",
                                    "nemcaid": "0",
                                    "year": "2008",
                                    "patientId": "0065A917BBAEC579",
                                    "dob": "12-01-1994",
                                    "gender": "Male",
                                    "diagnoses": "I10 D64.0",
                                    "physicians": "128374",
                                    "size": 10,
                                    "sortProp": "patientId.keyword",
                                    "sortOrder": "ASC",
                                    }</pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre>{
                                    "report": {
                                        "summary": {
                                            "totalCount": 10,
                                            "countDistribution": {
                                                "deidentifiedpatient": 10
                                            }
                                        }
                                    },
                                    "data": {
                                        "deidentifiedpatient": {
                                            "documentCount": 10,
                                            "maxScore": "NaN",
                                            "documentData": [
                                                {
                                                    "id": "grn:gg:deidentifiedpatient:GyDSuyzJ1tfg5ecx9eDxVLMDuLXgivC4VrWvfXbGgcZz",
                                                    "score": "NaN",
                                                    "caption": "",
                                                    "source": {
                                                        "similarDiagnoses": [
                                                            "Z48.1"
                                                        ],
                                                        "partnerPhysicians": [
                                                            "4824842417"
                                                        ],
                                                        "gender": "Male",
                                                        "patientId": "00013D2EFD8E45D1",
                                                        "dateOfDeath": "null",
                                                        "costPerYear": {
                                                            "2008": 50.0
                                                        },
                                                        "expectedCostPerYear": {
                                                            "2009": 3528.0,
                                                            "2008": 3528.0
                                                        },
                                                        "ltimcaid": "0",
                                                        "rafScores": {
                                                            "2009": 0.441,
                                                            "2008": 0.441
                                                        },
                                                        "dateOfBirth": "05-01-1923",
                                                        "diagnoses": [
                                                            "Z48.1"
                                                        ],
                                                        "years": [
                                                            "2008"
                                                        ],
                                                        "createdAt": null,
                                                        "physicians": [
                                                            "4824842417"
                                                        ],
                                                        "lastSearchIndexedAt": "2020-11-16T13:09:46+00:00",
                                                        "orec": "0",
                                                        "broadDiagnoses": [
                                                            "Z48.1"
                                                        ],
                                                        "nemcaid": "1",
                                                        "oePerYear": {
                                                            "2009": 0.0,
                                                            "2008": 0.02834467120181406
                                                        },
                                                        "updatedAt": "2020-11-12T13:39:54+00:00"
                                                    }
                                                }
                                            ]
                                        }
                                    },
                                    "suggest": {},
                                    "collapse": {},
                                    "searchParams": {}
                                }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api2">Send message</h2>
            <p>Used to generate and send the HL7 message to NextGen when an appointment is submitted.</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>POST</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/ehr/graph/sendmessage</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapseTwo"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapseTwo" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>patientGrn</td>
                                <td>string</td>
                                <td>Grn of the patient</td>
                            </tr>
                            <tr>
                                <td>appointmentGrn</td>
                                <td>string</td>
                                <td>Grn of the appointment</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    POST
                                    https://medic-prod-api.graphgrid.com/1.0/ehr/graph/sendmessage

                                    Body
                                    {
                                     "patientGrn": "grn:gg:patient:12345",
                                     "appointmentGrn": "grn:gg:appointment:67890"
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre>
                                    {
                                     "patientGrn": "grn:gg:patient:12345",
                                     "appointmentGrn": "grn:gg:appointment:67890"
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api3">Add Patient Encounter</h2>
            <p>Used to create the necessary nodes for appointments/diagnoses etc. when an appointment is submitted.</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>POST</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/addPatientEncounter</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapseThree"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapseThree" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>patientGrn</td>
                                <td>string</td>
                                <td>Grn of the patient</td>
                            </tr>
                            <tr>
                                <td>appointmentGrn</td>
                                <td>string</td>
                                <td>Grn of the appointment</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    POST
                                    https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/addPatientEncounter

                                    Body
                                    {
                                     "patientGrn": "grn:gg:patient:12345",
                                     "appointmentGrn": "grn:gg:appointment:67890"
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre>
                                    {
                                     "patientGrn": "grn:gg:patient:12345",
                                     "appointmentGrn": "grn:gg:appointment:67890"
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api4">Add Patient</h2>
            <p>This is used when creating a new patient in the app.</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>POST</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/addPatientv2</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapseFour"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapseFour" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>firstName</td>
                                <td>string</td>
                                <td>First name of the patient</td>
                            </tr>
                            <tr>
                                <td>lastName</td>
                                <td>string</td>
                                <td>Last name of the patient</td>
                            </tr>
                            <tr>
                                <td>physician</td>
                                <td>string</td>
                                <td>Grn of the entering physician</td>
                            </tr>
                            <tr>
                                <td>dateOfBirth</td>
                                <td>string</td>
                                <td>Patient's date of birth</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    POST
                                    https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/addPatientv2

                                    Body
                                    {
                                       "firstName": "Steve",
                                       "lastName": "Jobs",
                                       "physician": "grn:gg:physician:123456",
                                       "dateOfBirth": "1945-09-04"
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre>
                                    {
                                       "results": [
                                           {
                                               "appointment": {
                                                   "labels": [
                                                       "Appointment"
                                                   ],
                                                   "id": 5637724,
                                                   "properties": {
                                                       "grn": "grn:gg:appointment:f50e01a1-91e4-4616-a320-04fc63c0c935",
                                                       "captureType": "chargeCapture",
                                                       "appointmentId": "f50e01a1-91e4-4616-a320-04fc63c0c935"
                                                   }
                                               },
                                               "patient": {
                                                   "labels": [
                                                       "Patient"
                                                   ],
                                                   "id": 19029600,
                                                   "properties": {
                                                       "lastName": "Jobs",
                                                       "firstName": "Steve",
                                                       "grn": "grn:gg:patient:61707bb0-cb5a-4e23-b9e5-3305786e12b9",
                                                       "lastSearchIndexedAt": "2020-08-28T12:11:57+00:00",
                                                       "dob": "19450904",
                                                       "name": "jobs_steve",
                                                       "dateOfBirth": "19450904",
                                                       "updatedAt": "2019-10-25T12:48:32+00:00"
                                                   }
                                               },
                                               "physician": {
                                                   "labels": [
                                                       "Physician",
                                                       "GraphGridResource"
                                                   ],
                                                   "id": 13835270,
                                                   "properties": {
                                                       "lastName": "Smith",
                                                       "practiceZip": "44691",
                                                       "practiceAddress": "111 S buckeye St",
                                                       "grn": "grn:gg:physician:123456",
                                                       "gender": "M",
                                                       "practiceFax": "3303303300",
                                                       "prefix": "MRS.",
                                                       "practicePhone": "3303303381",
                                                       "npi": "23456",
                                                       "taxonomy1": "bgdye6",
                                                       "suffix": "",
                                                       "taxonomy2": "jirjr",
                                                       "firstName": "John",
                                                       "practiceState": "OH",
                                                       "credential": "RN",
                                                       "phone": "3303728193",
                                                       "lastSearchIndexedAt": "2019-08-30T22:09:57+00:00",
                                                       "practiceCity": "Wooster",
                                                       "name": "MRS. John Smith RN",
                                                       "middleName": "DIANE",
                                                       "id": "123456",
                                                       "fax": "3303303030",
                                                       "updatedAt": "2018-07-27T13:22:07+0000"
                                                   }
                                               }
                                           }
                                       ],
                                       "bookmark": "neo4j:bookmark:v1:tx20198896",
                                       "errors": []
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api5">Get Columns From IcdTen</h2>
            <p>Populate the three-column view from the ICD screen.</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getColumnsFromIcdTen</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapseFive"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapseFive" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>name</td>
                                <td>string</td>
                                <td>ICD code</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover table-responsive">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    GET
                                    https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getColumnsFromIcdTen?params=%7B%22name%22%3A%22Z48.817%22%7D
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre></pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api6">Get Physician Coordinates</h2>
            <p>Populate physician number/cost coordinates.</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getPhysicianCoordinatesv2</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapseSix"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapseSix" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>icds</td>
                                <td>string</td>
                                <td>List of strings of ICD codes separated by " "</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover table-responsive">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    GET
                                    https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getPhysicianCoordinatesv2?params=%7B%22icds%22%3A%20%5B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%22N32.89%22%0A%20%20%20%20%20%20%20%20%20%20%20%20%5D%7D
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre></pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api7">Get Physician Coordinates and Distance</h2>
            <p>Populate physician number/cost coordinates, including distance from given Zip code.</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getPhysicianCoordinatesAndDistance</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapseSeven"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapseSeven" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>icds</td>
                                <td>string</td>
                                <td>List of strings of ICD codes separated by " "</td>
                            </tr>
                            <tr>
                                <td>zipCode</td>
                                <td>string</td>
                                <td>Enter zip code here</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover table-responsive">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    GET
                                    https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getPhysicianCoordinatesAndDistance?params=%7B%22zipCode%22%3A%2244643%22%2C%22icds%22%3A%5B%22S82.101A%22%2C%22I10%22%5D%7D
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre></pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api8">Get Location From Coordinates</h2>
            <p>Get a full location given the lat/lon coordinates. (street address, city, state, country, zip)</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getLocationFromCoordinates</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapseEight"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapseEight" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>lat</td>
                                <td>float</td>
                                <td>Latitude</td>
                            </tr>
                            <tr>
                                <td>lon</td>
                                <td>float</td>
                                <td>Longitude</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover table-responsive">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    GET
                                    https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getLocationFromCoordinates?params=%7B%22lat%22%3A40%2C%22lon%22%3A-80%7D

                                    Non-Url-Encoded Parameters
                                    {
                                       "lat": 40.0,
                                       "lon": -80.0
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre>
                                    {
                                       "results": [
                                           {
                                               "description": "Mulberry Street, Fredericktown Hill, East Bethlehem Township, Washington County, Pennsylvania, 15333, United States of America"
                                           }
                                       ],
                                       "bookmark": "neo4j:bookmark:v1:tx20201229",
                                       "errors": []
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api9">Get ICD From Riak</h2>
            <p>Get first, second and third degree ICD nodes contained under a given Riak.</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getIcdFromRiak</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapseNine"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapseNine" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>riakId</td>
                                <td>string</td>
                                <td>Riak ID</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover table-responsive">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    GET
                                    https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getIcdFromRiak?params=%7B%22riakId%22%3A%22Riak.Blepharospasm%22%7D

                                    Non-Url-Encoded Parameters
                                    {
                                       "riakId": “Riak.Blepharospasm”
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre>
                                    {
                                        "results": [
                                            {
                                                "degree0": {
                                                    "icds": [
                                                        "G24.5"
                                                    ],
                                                    "patientCount": 14
                                                },
                                                "degree1": {
                                                    "icds": [
                                                        "G24.5",
                                                        "H01.009",
                                                        "H01.003",
                                                        "H01.006"
                                                    ],
                                                    "patientCount": 37348
                                                },
                                                "degree2": {
                                                    "icds": [
                                                        "G24.5",
                                                        "H01.009",
                                                        "H01.003",
                                                        "H01.006",
                                                        "B30.9",
                                                        "B00.53",
                                                        "B30.0",
                                                        "B02.33"                ],
                                                    "patientCount": 37348
                                                }
                                            }
                                        ],
                                        "bookmark": "neo4j:bookmark:v1:tx20201441",
                                        "errors": []
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api10">Get ICD Parent Chain</h2>
            <p>This call allows us to display the parents in the tree when cutting to a lower branch on the ICD tree.</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getIcdTenParentChain</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapseTen"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapseTen" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>icdTenId</td>
                                <td>string</td>
                                <td>String containing the preIcd id, which is retrieved from the previous call</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover table-responsive">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    GET
                                    https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getIcdTenParentChain?params=%7B%22icdTenId%22%3A%2222%22%7D

                                    Non-Url-Encoded Parameters
                                    {
                                       "icdTenId": "22"
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre></pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api11">Get Cpt Parent Chain</h2>
            <p>This call allows us to display the parents in the tree when cutting to a lower branch on the CPT tree.</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getCptParentChain</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapseEleven"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapseEleven" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>cptId</td>
                                <td>string</td>
                                <td>String containing the preCptId, which is retrieved from the previous call</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover table-responsive">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    GET
                                    https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getCptParentChain?params=%7B%22cptId%22%3A%2222%22%7D

                                    Non-Url-Encoded Parameters
                                    {
                                       "cptId": "22"
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre></pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api12">Get IcdTen Dependant Count</h2>
            <p>This call shows us how many parent nodes a PreIcd node has</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getIcdTenDependantCount</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapseTwelve"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapseTwelve" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>icdTenId</td>
                                <td>string</td>
                                <td>String containing the preIcdId, which is retrieved from the previous call</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover table-responsive">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    GET
                                    https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getIcdTenDependantCount?params=%7B%22icdTenId%22%3A%2222%22%7D

                                    Non-Url-Encoded Parameters
                                    {
                                       "icdTenId": "22"
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre>
                                    {
                                   "results": [
                                       {
                                           "dependentCount": 1
                                       }
                                   ],
                                   "bookmark": "neo4j:bookmark:v1:tx20203302",
                                   "errors": []
                                }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <h2 id="api13">Get Cpt Dependant Count</h2>
            <p>This call shows us how many parent nodes a PreCpt node has</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getCptDependantCount</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapseThirteen"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapseThirteen" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>cptId</td>
                                <td>string</td>
                                <td>String containing the preCptId, which is retrieved from the previous call</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover table-responsive">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    GET
                                    https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getCptDependantCount?params=%7B%22cptId%22%3A%2222%22%7D

                                    Non-Url-Encoded Parameters
                                    {
                                       "cptId": "22"
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre>
                                    {
                                       "results": [
                                           {
                                               "dependentCount": 1
                                           }
                                       ],
                                       "bookmark": "neo4j:bookmark:v1:tx20203302",
                                       "errors": []
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api14">Get Cpt Root</h2>
            <p>This call shows us how many parent nodes a PreCpt node has</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getCptRoot</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapseFourteen"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapseFourteen" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>cptId</td>
                                <td>string</td>
                                <td>String containing the preCptId, which is retrieved from the previous call</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    GET
                                    https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getCptRoot
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre>
                                    {
                                       "results": [
                                           {
                                               "parent": {
                                                   "id": 17538397,
                                                   "properties": {
                                                       "grn": "grn:gg:precpt:dcfe58c3-b111-46b3-a761-037f6c0c01ff",
                                                       "depth": 2,
                                                       "lastSearchIndexedAt": "2020-06-01T21:41:03+00:00",
                                                       "cptId": "2",
                                                       "leafCount": 66,
                                                       "name": "Evaluation and Management",
                                                       "leaf": false,
                                                       "wRvu": null,
                                                       "updatedAt": "2019-03-05T15:40:01+00:00"
                                                   },
                                                   "labels": [
                                                       "Cpt"
                                                   ]
                                               },
                                               "leafCount": 53690,
                                               "favorite": null,
                                               "favoriteCptId": null
                                           },
                                           {
                                               "parent": {
                                                   "id": 17538555,
                                                   "properties": {
                                                       "grn": "grn:gg:precpt:60500fd4-75c7-4744-8c5a-bdb3deac7921",
                                                       "depth": 2,
                                                       "lastSearchIndexedAt": "2020-06-01T21:41:03+00:00",
                                                       "cptId": "160",
                                                       "leafCount": 280,
                                                       "name": "Sedation Coding",
                                                       "leaf": false,
                                                       "wRvu": null,
                                                       "updatedAt": "2019-03-05T15:40:01+00:00"
                                                   },
                                                   "labels": [
                                                       "Cpt"
                                                   ]
                                               },
                                               "leafCount": 53690,
                                               "favorite": null,
                                               "favoriteCptId": null
                                           },
                                           {
                                               "parent": {
                                                   "id": 17539163,
                                                   "properties": {
                                                       "grn": "grn:gg:precpt:dfd51039-4166-4635-a78d-568b28afca0a",
                                                       "depth": 2,
                                                       "lastSearchIndexedAt": "2020-06-01T21:41:03+00:00",
                                                       "cptId": "768",
                                                       "leafCount": 43,
                                                       "name": "Testing",
                                                       "leaf": false,
                                                       "wRvu": "7.96",
                                                       "updatedAt": "2019-03-05T15:40:02+00:00"
                                                   },
                                                   "labels": [
                                                       "Cpt"
                                                   ]
                                               },
                                               "leafCount": 53690,
                                               "favorite": null,
                                               "favoriteCptId": null
                                           },
                                           {
                                               "parent": {
                                                   "id": 17539272,
                                                   "properties": {
                                                       "grn": "grn:gg:precpt:0ff006bd-2d9f-40fd-97cc-035698bf2bbf",
                                                       "depth": 2,
                                                       "lastSearchIndexedAt": "2020-06-01T21:41:03+00:00",
                                                       "cptId": "877",
                                                       "leafCount": 1217,
                                                       "name": "Bedside codes",
                                                       "leaf": false,
                                                       "wRvu": "25.9",
                                                       "updatedAt": "2019-03-05T15:40:02+00:00"
                                                   },
                                                   "labels": [
                                                       "Cpt"
                                                   ]
                                               },
                                               "leafCount": 53690,
                                               "favorite": null,
                                               "favoriteCptId": null
                                           },
                                           {
                                               "parent": {
                                                   "id": 17542361,
                                                   "properties": {
                                                       "grn": "grn:gg:precpt:a8f102e4-abf0-43d4-8c77-8635061dc698",
                                                       "depth": 2,
                                                       "lastSearchIndexedAt": "2020-06-01T21:40:47+00:00",
                                                       "cptId": "3966",
                                                       "leafCount": 52084,
                                                       "name": "Operative procedures",
                                                       "leaf": false,
                                                       "wRvu": "1.9",
                                                       "updatedAt": "2019-03-05T15:40:05+00:00"
                                                   },
                                                   "labels": [
                                                       "Cpt"
                                                   ]
                                               },
                                               "leafCount": 53690,
                                               "favorite": null,
                                               "favoriteCptId": null
                                           }
                                       ],
                                       "bookmark": "neo4j:bookmark:v1:tx20275602",
                                       "errors": []
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api15">Get Icd Root</h2>
            <p>This call gets us the roots of the PreIcd tree.</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getIcdTenRoot</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapse15"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapse15" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    GET
                                    https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getIcdTenRoot
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre>
                                    {
                                       "results": [
                                           {
                                               "parent": {
                                                   "id": 17663046,
                                                   "properties": {
                                                       "grn": "grn:gg:preicd:2d2853be-3953-428e-b0a0-6812a52d2361",
                                                       "depth": 2,
                                                       "lastSearchIndexedAt": "2020-11-15T20:40:27+00:00",
                                                       "leafCount": 538,
                                                       "icdTenId": "2",
                                                       "name": "Evaluation Code",
                                                       "hcc": null,
                                                       "leaf": false,
                                                       "updatedAt": "2019-03-05T15:45:59+00:00"
                                                   },
                                                   "labels": [
                                                       "IcdTen"
                                                   ]
                                               },
                                               "leafCount": 118153,
                                               "favorite": null,
                                               "favoriteIcdId": null
                                           },
                                           {
                                               "parent": {
                                                   "id": 17664321,
                                                   "properties": {
                                                       "grn": "grn:gg:preicd:c68b2c47-ada6-44a0-8263-cf30d099335f",
                                                       "depth": 2,
                                                       "lastSearchIndexedAt": "2020-11-15T20:40:45+00:00",
                                                       "leafCount": 56,
                                                       "icdTenId": "1315",
                                                       "name": "FAMILY HISTORY",
                                                       "hcc": null,
                                                       "leaf": false,
                                                       "updatedAt": "2019-03-05T15:46:00+00:00"
                                                   },
                                                   "labels": [
                                                       "IcdTen"
                                                   ]
                                               },
                                               "leafCount": 118153,
                                               "favorite": null,
                                               "favoriteIcdId": null
                                           },
                                           {
                                               "parent": {
                                                   "id": 17664469,
                                                   "properties": {
                                                       "grn": "grn:gg:preicd:f5b0d71c-bb70-4775-9165-489ba1eecba3",
                                                       "depth": 2,
                                                       "lastSearchIndexedAt": "2020-11-15T20:40:45+00:00",
                                                       "leafCount": 356,
                                                       "icdTenId": "1452",
                                                       "name": "PERSONAL HISTORY",
                                                       "hcc": null,
                                                       "leaf": false,
                                                       "updatedAt": "2019-03-05T15:46:00+00:00"
                                                   },
                                                   "labels": [
                                                       "IcdTen"
                                                   ]
                                               },
                                               "leafCount": 118153,
                                               "favorite": null,
                                               "favoriteIcdId": null
                                           },
                                           {
                                               "parent": {
                                                   "id": 17665325,
                                                   "properties": {
                                                       "grn": "grn:gg:preicd:23bc2901-56f4-4fcf-bf53-5b2222a9802d",
                                                       "depth": 2,
                                                       "lastSearchIndexedAt": "2020-11-15T20:41:03+00:00",
                                                       "leafCount": 931,
                                                       "icdTenId": "2308",
                                                       "name": "RISK FACTOR",
                                                       "hcc": null,
                                                       "leaf": false,
                                                       "updatedAt": "2019-03-05T15:46:00+00:00"
                                                   },
                                                   "labels": [
                                                       "IcdTen"
                                                   ]
                                               },
                                               "leafCount": 118153,
                                               "favorite": null,
                                               "favoriteIcdId": null
                                           },
                                           {
                                               "parent": {
                                                   "id": 17667575,
                                                   "properties": {
                                                       "grn": "grn:gg:preicd:4e4f3387-1a27-448e-ba57-b2c86d8a1db4",
                                                       "depth": 2,
                                                       "lastSearchIndexedAt": "2020-11-15T20:42:07+00:00",
                                                       "leafCount": 2314,
                                                       "icdTenId": "4569",
                                                       "name": "SYMPTOM",
                                                       "hcc": null,
                                                       "leaf": false,
                                                       "updatedAt": "2019-03-05T15:46:00+00:00"
                                                   },
                                                   "labels": [
                                                       "IcdTen"
                                                   ]
                                               },
                                               "leafCount": 118153,
                                               "favorite": null,
                                               "favoriteIcdId": null
                                           },
                                           {
                                               "parent": {
                                                   "id": 17673095,
                                                   "properties": {
                                                       "grn": "grn:gg:preicd:9d36ab9e-1d78-42a8-99cf-27085adc4c69",
                                                       "depth": 2,
                                                       "lastSearchIndexedAt": "2020-11-15T20:45:20+00:00",
                                                       "leafCount": 1519,
                                                       "icdTenId": "10089",
                                                       "name": "Physical Finding",
                                                       "hcc": null,
                                                       "leaf": false,
                                                       "updatedAt": "2019-03-05T15:46:02+00:00"
                                                   },
                                                   "labels": [
                                                       "IcdTen"
                                                   ]
                                               },
                                               "leafCount": 118153,
                                               "favorite": null,
                                               "favoriteIcdId": null
                                           },
                                           {
                                               "parent": {
                                                   "id": 17676729,
                                                   "properties": {
                                                       "grn": "grn:gg:preicd:aaa370fc-8ede-4168-bd3c-989824737c35",
                                                       "depth": 2,
                                                       "lastSearchIndexedAt": "2020-11-15T20:48:14+00:00",
                                                       "leafCount": 60590,
                                                       "icdTenId": "13712",
                                                       "name": "DISORDER",
                                                       "hcc": null,
                                                       "leaf": false,
                                                       "updatedAt": "2019-03-05T15:46:03+00:00"
                                                   },
                                                   "labels": [
                                                       "IcdTen"
                                                   ]
                                               },
                                               "leafCount": 118153,
                                               "favorite": null,
                                               "favoriteIcdId": null
                                           },
                                           {
                                               "parent": {
                                                   "id": 17821702,
                                                   "properties": {
                                                       "grn": "grn:gg:preicd:34be1e82-3d9a-41e3-8e2c-634d3dfd6182",
                                                       "depth": 2,
                                                       "lastSearchIndexedAt": "2020-11-15T21:53:20+00:00",
                                                       "leafCount": 41785,
                                                       "icdTenId": "158685",
                                                       "name": "TRAUMATIC INJURY",
                                                       "hcc": null,
                                                       "leaf": false,
                                                       "updatedAt": "2019-03-05T15:46:47+00:00"
                                                   },
                                                   "labels": [
                                                       "IcdTen"
                                                   ]
                                               },
                                               "leafCount": 118153,
                                               "favorite": null,
                                               "favoriteIcdId": null
                                           },
                                           {
                                               "parent": {
                                                   "id": 17925687,
                                                   "properties": {
                                                       "grn": "grn:gg:preicd:1c6809bb-a316-4013-afdf-68b98f6d08f2",
                                                       "depth": 2,
                                                       "lastSearchIndexedAt": "2020-11-15T20:46:30+00:00",
                                                       "leafCount": 106,
                                                       "icdTenId": "262681",
                                                       "name": "COMPLICATION",
                                                       "hcc": null,
                                                       "leaf": false,
                                                       "updatedAt": "2019-03-05T15:47:16+00:00"
                                                   },
                                                   "labels": [
                                                       "IcdTen"
                                                   ]
                                               },
                                               "leafCount": 118153,
                                               "favorite": null,
                                               "favoriteIcdId": null
                                           },
                                           {
                                               "parent": {
                                                   "id": 17925941,
                                                   "properties": {
                                                       "grn": "grn:gg:preicd:51e0144b-bfe6-473a-b2f3-a1d7e1896aad",
                                                       "depth": 2,
                                                       "lastSearchIndexedAt": "2020-11-15T20:46:30+00:00",
                                                       "leafCount": 9697,
                                                       "icdTenId": "262935",
                                                       "name": "CONSEQUENCE",
                                                       "hcc": null,
                                                       "leaf": false,
                                                       "updatedAt": "2019-03-05T15:47:16+00:00"
                                                   },
                                                   "labels": [
                                                       "IcdTen"
                                                   ]
                                               },
                                               "leafCount": 118153,
                                               "favorite": null,
                                               "favoriteIcdId": null
                                           },
                                           {
                                               "parent": {
                                                   "id": 17948996,
                                                   "properties": {
                                                       "grn": "grn:gg:preicd:69ecc28e-f3fb-4306-b225-0fd0ecf70dcf",
                                                       "depth": 2,
                                                       "lastSearchIndexedAt": "2020-11-15T21:02:33+00:00",
                                                       "leafCount": 261,
                                                       "icdTenId": "285990",
                                                       "name": "ABNORMAL TEST RESULT",
                                                       "hcc": null,
                                                       "leaf": false,
                                                       "updatedAt": "2019-03-05T15:47:22+00:00"
                                                   },
                                                   "labels": [
                                                       "IcdTen"
                                                   ]
                                               },
                                               "leafCount": 118153,
                                               "favorite": null,
                                               "favoriteIcdId": null
                                           },
                                           {
                                               "parent": {
                                                   "id": 17987152,
                                                   "properties": {
                                                       "grn": "grn:gg:preicd:15d8a876-cd6f-4937-a2fa-f7411f394c2d",
                                                       "depth": 2,
                                                       "lastSearchIndexedAt": "2020-11-15T21:18:23+00:00",
                                                       "leafCount": 143922,
                                                       "icdTenId": "324146",
                                                       "name": "Consequences",
                                                       "hcc": null,
                                                       "leaf": false,
                                                       "updatedAt": "2019-03-05T15:47:31+00:00"
                                                   },
                                                   "labels": [
                                                       "IcdTen"
                                                   ]
                                               },
                                               "leafCount": 118153,
                                               "favorite": null,
                                               "favoriteIcdId": null
                                           },
                                           {
                                               "parent": {
                                                   "id": 18010007,
                                                   "properties": {
                                                       "grn": "grn:gg:preicd:8d6559d2-2a13-49e1-8cdf-0d81c5242a29",
                                                       "depth": 2,
                                                       "lastSearchIndexedAt": "2020-11-15T21:31:30+00:00",
                                                       "leafCount": 143922,
                                                       "icdTenId": "347201",
                                                       "name": "Abnormal test result",
                                                       "hcc": null,
                                                       "leaf": false,
                                                       "updatedAt": "2019-03-05T15:47:37+00:00"
                                                   },
                                                   "labels": [
                                                       "IcdTen"
                                                   ]
                                               },
                                               "leafCount": 118153,
                                               "favorite": null,
                                               "favoriteIcdId": null
                                           }
                                       ],
                                       "bookmark": "neo4j:bookmark:v1:tx20275622",
                                       "errors": []
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api16">Get Cpt Children</h2>
            <p>This call gets the next level down in the PreCpt tree</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getCptChildren</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapse16"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapse16" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>cptId</td>
                                <td>string</td>
                                <td>String containing the preCptId, which is retrieved from the previous call</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    GET
                                    https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getCptChildren?params=%7B%22cptId%22%3A%222%22%7D

                                    Non-Url-Encoded Parameters
                                    {
                                       "cptId": "2"
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre>
                                    {
                                       "results": [
                                           {
                                               "children": {
                                                   "id": 17538398,
                                                   "properties": {
                                                       "grn": "grn:gg:precpt:29de1958-61f5-4270-9575-24d0b6b5f648",
                                                       "mod": null,
                                                       "code": "Hospital/facility",
                                                       "cptId": "3",
                                                       "description": null,
                                                       "global": null,
                                                       "leaf": false,
                                                       "wRvu": null,
                                                       "depth": 3,
                                                       "lastSearchIndexedAt": "2020-06-01T21:41:03+00:00",
                                                       "leafCount": 43,
                                                       "name": "Hospital/facility",
                                                       "updatedAt": "2019-03-05T15:40:01+00:00"
                                                   },
                                                   "labels": [
                                                       "Cpt"
                                                   ]
                                               }
                                           },
                                           {
                                               "children": {
                                                   "id": 17538502,
                                                   "properties": {
                                                       "grn": "grn:gg:precpt:1ec4e84b-b96f-4bfd-b628-69b1085835ea",
                                                       "mod": null,
                                                       "code": "Office/outpatient",
                                                       "cptId": "107",
                                                       "description": null,
                                                       "global": null,
                                                       "leaf": false,
                                                       "wRvu": null,
                                                       "depth": 3,
                                                       "lastSearchIndexedAt": "2020-06-01T21:41:03+00:00",
                                                       "leafCount": 23,
                                                       "name": "Office/outpatient",
                                                       "updatedAt": "2019-03-05T15:40:01+00:00"
                                                   },
                                                   "labels": [
                                                       "Cpt"
                                                   ]
                                               }
                                           }
                                       ],
                                       "bookmark": "neo4j:bookmark:v1:tx20276102",
                                       "errors": []
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api17">Get Icd Children</h2>
            <p>This call gets the next level down in the PreIcd tree</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getIcdTenChildren</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapse17"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapse17" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>icdTenId</td>
                                <td>string</td>
                                <td>String containing the preIcdId, which is retrieved from the previous call.</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    GET
                                    https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getIcdTenChildren?params=%7B%22icdTenId%22%3A%222%22%7D

                                    Non-Url-Encoded Parameters
                                    {
                                       "icdTenId": "2"
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre>
                                    {
                                       "results": [
                                           {
                                               "children": {
                                                   "id": 17663047,
                                                   "properties": {
                                                       "grn": "grn:gg:preicd:88fedc9d-42ab-4efc-bc4d-fb9931fd142e",
                                                       "depth": 3,
                                                       "lastSearchIndexedAt": "2020-11-15T20:40:27+00:00",
                                                       "leafCount": 199,
                                                       "icdTenId": "3",
                                                       "name": "Examination visit",
                                                       "hcc": null,
                                                       "leaf": false,
                                                       "updatedAt": "2019-03-05T15:45:59+00:00"
                                                   },
                                                   "labels": [
                                                       "IcdTen"
                                                   ]
                                               }
                                           },
                                           {
                                               "children": {
                                                   "id": 17663499,
                                                   "properties": {
                                                       "grn": "grn:gg:preicd:4a86e7ae-0288-4d36-b677-bcf27a5ec60c",
                                                       "depth": 3,
                                                       "lastSearchIndexedAt": "2020-11-15T20:40:27+00:00",
                                                       "leafCount": 49,
                                                       "icdTenId": "493",
                                                       "name": "Screening",
                                                       "hcc": null,
                                                       "leaf": false,
                                                       "updatedAt": "2019-03-05T15:45:59+00:00"
                                                   },
                                                   "labels": [
                                                       "IcdTen"
                                                   ]
                                               }
                                           },
                                           {
                                               "children": {
                                                   "id": 17663412,
                                                   "properties": {
                                                       "grn": "grn:gg:preicd:e4af999a-7479-4a0b-b112-6bb93d80d18a",
                                                       "depth": 3,
                                                       "lastSearchIndexedAt": "2020-11-15T20:40:27+00:00",
                                                       "leafCount": 69,
                                                       "icdTenId": "606",
                                                       "name": "Observation",
                                                       "hcc": null,
                                                       "leaf": false,
                                                       "updatedAt": "2019-03-05T15:45:59+00:00"
                                                   },
                                                   "labels": [
                                                       "IcdTen"
                                                   ]
                                               }
                                           },
                                           {
                                               "children": {
                                                   "id": 17663786,
                                                   "properties": {
                                                       "grn": "grn:gg:preicd:f74cee89-99e0-4df3-a557-9c2aee904099",
                                                       "depth": 3,
                                                       "lastSearchIndexedAt": "2020-11-15T20:40:27+00:00",
                                                       "leafCount": 171,
                                                       "icdTenId": "780",
                                                       "name": "Aftercare",
                                                       "hcc": null,
                                                       "leaf": false,
                                                       "updatedAt": "2019-03-05T15:45:59+00:00"
                                                   },
                                                   "labels": [
                                                       "IcdTen"
                                                   ]
                                               }
                                           },
                                           {
                                               "children": {
                                                   "id": 17664215,
                                                   "properties": {
                                                       "grn": "grn:gg:preicd:b08c816a-fff0-43af-9141-a0765596da60",
                                                       "depth": 3,
                                                       "lastSearchIndexedAt": "2020-11-15T20:40:45+00:00",
                                                       "leafCount": 50,
                                                       "icdTenId": "1198",
                                                       "name": "Counseling visit",
                                                       "hcc": null,
                                                       "leaf": false,
                                                       "updatedAt": "2019-03-05T15:45:59+00:00"
                                                   },
                                                   "labels": [
                                                       "IcdTen"
                                                   ]
                                               }
                                           }
                                       ],
                                       "bookmark": "neo4j:bookmark:v1:tx20276102",
                                       "errors": []
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api18">Find Icd By Cpt</h2>
            <p>This call takes a pre cpt node and predicts the best starting point on the Icd tree</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/findIcdByCpt</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapse18"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapse18" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>cptId</td>
                                <td>string</td>
                                <td>String containing the preCptId</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    GET
                                    https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/findIcdByCpt?params=%7B%22cptId%22%3A%2249324%22%7D

                                    Non-Url-Encoded Parameters
                                    {
                                       "cptId": "49324"
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre>
                                    {
                                       "results": [
                                           {
                                               "preIcdId": "94636"
                                           }
                                       ],
                                       "bookmark": "neo4j:bookmark:v1:tx20276379",
                                       "errors": []
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api19">Get Icd By Name</h2>
            <p>This call takes an ICD code and returns all of the data connected to that code</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getIcdTenByName</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapse19"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapse19" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>name</td>
                                <td>string</td>
                                <td>String containing the ICD code</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    GET
                                    https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getIcdTenByName?params=%7B%22name%22%3A%22I65.21%22%7D

                                    Non-Url-Encoded Parameters
                                    {
                                       "name": "I65.21"
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre>
                                    {
                                       "results": [
                                           {
                                               "children": {
                                                   "id": 5806156,
                                                   "properties": {
                                                       "grn": "grn:gg:icdten:39dfd73a-fbd1-4c17-9b33-ec865518c141",
                                                       "description": "Occlusion and stenosis of right carotid artery",
                                                       "leaf": true,
                                                       "depth": 11,
                                                       "riak": false,
                                                       "lastSearchIndexedAt": "2018-08-02T18:33:16+0000",
                                                       "hccValue": "-",
                                                       "raf": null,
                                                       "icdTenId": "5711",
                                                       "name": "I65.21",
                                                       "hccCode": "none",
                                                       "updatedAt": "2018-07-25T14:00:46+0000"
                                                   },
                                                   "labels": [
                                                       "IcdTen"
                                                   ]
                                               }
                                           }
                                       ],
                                       "bookmark": "neo4j:bookmark:v1:tx20276741",
                                       "errors": []
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api20">Get Cpt By Name</h2>
            <p>This call takes an CPT code and returns all of the data connected to that code</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getCptByNamev2</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapse20"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapse20" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>name</td>
                                <td>string</td>
                                <td>String containing the CPT code</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    GET
                                    https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getCptByNamev2?params=%7B%22name%22%3A%2215271%20%2F%2097605-51%20%2F%2050327-JC%20x%2044%22%7D

                                    Non-Url-Encoded Parameters
                                    {
                                       "name": "15271 / 97605-51 / 50327-JC x 44"
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre>
                                    {
                                       "results": [
                                           {
                                               "children": [
                                                   {
                                                       "id": 5536225,
                                                       "properties": {
                                                           "grn": "grn:gg:cpt:8d3be3c6-2aed-4064-b126-3b73e5fbcb10",
                                                           "mod": null,
                                                           "code": "15271",
                                                           "cptId": "15271",
                                                           "description": "Skin sub graft trnk/arm/leg",
                                                           "global": "0",
                                                           "leaf": true,
                                                           "wRvu": "1.5",
                                                           "depth": null,
                                                           "lastSearchIndexedAt": "2018-07-27T18:21:45+0000",
                                                           "name": "15271",
                                                           "updatedAt": "2018-07-25T14:58:30+0000"
                                                       },
                                                       "labels": [
                                                           "Cpt"
                                                       ]
                                                   },
                                                   {
                                                       "id": 5518559,
                                                       "properties": {
                                                           "grn": "grn:gg:cpt:c2e913a7-ecb6-4827-833f-7bcc8c7c8e9a",
                                                           "mod": "51",
                                                           "code": "97605",
                                                           "cptId": "97605",
                                                           "description": "Neg press wound tx <=50 cm",
                                                                        "global": "XXX",
                                                                        "leaf": null,
                                                                        "wRvu": "0.55",
                                                                        "depth": null,
                                                                        "lastSearchIndexedAt": "2018-07-27T18:21:01+0000",
                                                                        "name": "97605",
                                                                        "updatedAt": "2018-07-25T14:58:41+0000"
                                                                        },
                                                                        "labels": [
                                                                        "Cpt"
                                                                        ]
                                                                        },
                                                                        {
                                                                        "id": 5471277,
                                                                        "properties": {
                                                                        "grn": "grn:gg:cpt:81acd3b9-2a79-4965-ac3b-0995e403772f",
                                                                        "mod": "JC",
                                                                        "code": "50327",
                                                                        "cptId": "50327",
                                                                        "description": "Prep renal graft/venous",
                                                                        "global": "XXX",
                                                                        "leaf": true,
                                                                        "wRvu": "4",
                                                                        "depth": null,
                                                                        "lastSearchIndexedAt": "2018-07-27T18:18:53+0000",
                                                                        "name": "50327",
                                                                        "updatedAt": "2018-07-25T14:58:35+0000"
                                                                        },
                                                                        "labels": [
                                                                        "Cpt"
                                                                        ]
                                                                        }
                                                                        ],
                                                                        "totalRVU": 177.78
                                                                        }
                                                                        ],
                                                                        "bookmark": "neo4j:bookmark:v1:tx20277117",
                                                                        "errors": []
                                                                        }
                                    </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api21">Get User By Grn</h2>
            <p>This call takes a user grn and returns the user’s data.</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getUserByGrn</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapse21"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapse21" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>grn</td>
                                <td>string</td>
                                <td>String containing the user’s grn</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    GET
                                    https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getUserByGrn?params=%7B%22grn%22%3A%22grn%3Agg%3Auser%3ALLege0O76pXaOmVUlW0ELj8DnG3SDcb8Q0hkB6GQySWK%22%7D

                                    Non-Url-Encoded Parameters
                                    {
                                       "grn": "grn:gg:user:LLege0O76pXaOmVUlW0ELj8DnG3SDcbBQ0hkX6GQySWK"
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre>
                                    {
                                       "results": [
                                           {
                                               "user": {
                                                   "id": 19013246,
                                                   "properties": {
                                                       "grn": "grn:gg:user:LLege0O76pXaOmVUlW0ELj8DnG3SDcb8Q0hkX6GQySWK",
                                                       "cpt": "CPT CODES -> Operative procedures -> Cardiovascular",
                                                       "physicianGrn": "grn:gg:physician:1463492504",
                                                       "eula": "accepted",
                                                       "icd": "ICD -> DISORDER -> Cardiovascular -> Acquired -> Periperheral -> Artery",
                                                       "physicianNpi": "1463492504",
                                                       "version": 1,
                                                       "displayEmail": "email@email.com",
                                                       "firstName": "First ",
                                                       "createdAt": "2019-09-28T12:10:24+00:00",
                                                       "phoneNumber": "",
                                                       "validationToken": "678028",
                                                       "lastSearchIndexedAt": "0",
                                                       "displayUsername": "emailATemailDOTcom",
                                                       "name": "First Last",
                                                       "locations": {
                                                           "hospital": [
                                                               {
                                                                   "properties": {
                                                                       "name": null,
                                                                       "grn": null,
                                                                       "orgName1": null,
                                                                       "npi": null
                                                                   }
                                                               }
                                                           ],
                                                           "location": [
                                                               {
                                                                   "properties": {
                                                                       "fullAddress": "Location 1  ",
                                                                       "grn": "grn:gg:location:af35cf09-d21a-4913-8558-071550b80c8f"
                                                                   }
                                                               },
                                                               {
                                                                   "properties": {
                                                                       "fullAddress": "Location name  ",
                                                                       "grn": "grn:gg:location:afa3033b-fbbd-4e00-91cb-649249781a60"
                                                                   }
                                                               }
                                                           ]
                                                       },
                                                       "email": "email@email.com",
                                                       "updatedAt": "2019-09-28T12:10:24+00:00",
                                                       "username": "emailatemaildotcom"
                                                   },
                                                   "labels": [
                                                       "User",
                                                       "Resource"
                                                   ]
                                               }
                                           }
                                       ],
                                       "bookmark": "neo4j:bookmark:v1:tx20277350",
                                       "errors": []
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api22">Select Patient</h2>
            <p>This call takes a physician NPI and a date and populates the physician’s schedule for the day</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/selectPatient</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapse22"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapse22" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>grn</td>
                                <td>string</td>
                                <td>String containing the user’s grn</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    GET
                                    https://medic-prod-api.graphgrid.com/1.0/showme/selectPatient?params=%7B%22physicianNpi%22%3A%22123456%22%2C%22date%22%3A%222020-11-18%22%7D

                                    Non-Url-Encoded Parameters
                                    {
                                       "physicianNpi": "123456",
                                       "npi": "2020-11-18",
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre>
                                    {
                                       "results": [
                                           {
                                               "location": {
                                                   "id": 19069986,
                                                   "properties": {
                                                       "grn": "grn:gg:location:d07a8543-dbcc-4e90-a907-2060e291eab6",
                                                       "locationName": "Wooster Procedure Room"
                                                   },
                                                   "labels": [
                                                       "Location"
                                                   ]
                                               },
                                               "patient": {
                                                   "id": 18983894,
                                                   "properties": {
                                                       "lastName": "First",
                                                       "firstName": "Last",
                                                       "grn": "grn:gg:patient:12345",
                                                       "dateOfBirth": "1920-02-30"
                                                   },
                                                   "labels": [
                                                       "Patient"
                                                   ]
                                               },
                                               "encounter": [
                                                   {
                                                       "properties": {
                                                           "encounterCapture": false,
                                                           "date": "2020-11-18",
                                                           "grn": "grn:gg:appointment:123456",
                                                           "patientNotSeen": false,
                                                           "timing": "202011180800___202011181000",
                                                           "chargeCapture": false,
                                                           "startTime": "08:00",
                                                           "eventReason": "reason for event",
                                                           "status": "KEPT"
                                                       },
                                                       "labels": [
                                                           "Encounter"
                                                       ]
                                                   }
                                               ],
                                               "appointment": [
                                                   {
                                                       "properties": {
                                                           "encounterCapture": false,
                                                           "date": "2020-11-18",
                                                           "grn": "grn:gg:appointment:123456",
                                                           "patientNotSeen": false,
                                                           "timing": "202011180800___202011181000",
                                                           "chargeCapture": false,
                                                           "startTime": "08:00",
                                                           "eventReason": "reason for event",
                                                           "status": "KEPT"
                                                       },
                                                       "labels": [
                                                           "Encounter"
                                                       ]
                                                   }
                                               ]
                                           }
                                       ],
                                       "bookmark": "neo4j:bookmark:v1:tx20277684",
                                       "errors": []
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api23">Get Modifiers For Cpt</h2>
            <p>This call takes a CPT code and returns the list of modifiers associated with it</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/getEmFromCpt</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapse23"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapse23" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>cptId</td>
                                <td>string</td>
                                <td>String containing the cpt code</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    GET
                                    https://medic-prod-api.graphgrid.com/1.0/showme/getEmFromCpt?params=%7B%22cptId%22%3A%2299202%22%7D

                                    Non-Url-Encoded Parameters
                                    {
                                       "cptId": "99202"
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre>
                                    {
                                       "results": [
                                           {
                                               "em": [
                                                   {
                                                       "description": "21 - Unusual E+M Service",
                                                       "id": "21",
                                                       "reimbursementImpact": 1.2
                                                   },
                                                   {
                                                       "description": "24 - unrelated E&M service by the same physician  during gobal period",
                                                       "id": "24",
                                                       "reimbursementImpact": 1.0
                                                   },
                                                   {
                                                       "description": "25 - significant, separately identifiable E&M service by  same physician, same day of the procedure",
                                                       "id": "25",
                                                       "reimbursementImpact": 1.0
                                                   },
                                                   {
                                                       "description": "52 - Reduced Services",
                                                       "id": "52",
                                                       "reimbursementImpact": 0.8
                                                   },
                                                   {
                                                       "description": "53 - Discontinued Procedure (Revised January 2015)",
                                                       "id": "53",
                                                       "reimbursementImpact": 0.5
                                                   },
                                                   {
                                                       "description": "58 - Procedure during the postop period:  planned prospectively, or more extensive than anticipated",
                                                       "id": "58",
                                                       "reimbursementImpact": 1.0
                                                   },
                                                   {
                                                       "description": "59 - Distinct Procedural Services",
                                                       "id": "59",
                                                       "reimbursementImpact": 0.5
                                                   },
                                                   {
                                                       "description": "66 - Team of surgeons",
                                                       "id": "66",
                                                       "reimbursementImpact": 1.0
                                                   },
                                                   {
                                                       "description": "73 - Discontinued Procedure Prior to the Administration of Anesthesia",
                                                       "id": "73",
                                                       "reimbursementImpact": 0.5
                                                   },
                                                   {
                                                       "description": "74 - Discontinued after anesthesia",
                                                       "id": "74",
                                                       "reimbursementImpact": 0.7
                                                   },
                                                   {
                                                       "description": "77 - Repeat Procedure by Another Physician",
                                                       "id": "77",
                                                       "reimbursementImpact": 1.0
                                                   },
                                                   {
                                                       "description": "79 - unrelated procedure or service by the same physician during the post-operative period.",
                                                       "id": "79",
                                                       "reimbursementImpact": 1.0
                                                   }
                                               ]
                                           }
                                       ],
                                       "bookmark": "neo4j:bookmark:v1:tx20278083",
                                       "errors": []
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api24">Accepted Eula</h2>
            <p>This call takes a user grn and displays whether the user has accepted the EULA agreement or not.</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/acceptedEula</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapse24"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapse24" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>userId</td>
                                <td>string</td>
                                <td>String containing the user’s grn</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    GET
                                    https://medic-prod-api.graphgrid.com/1.0/showme/acceptedEula?params=%7B%22userId%22%3A%22xtYHFDJBtciaY66oGCggGrvOduS6UB9Y6l9AeFPEHK3F%22%7D

                                    Non-Url-Encoded Parameters
                                    {
                                       "userId": "xtYHFDJBtciaY66oGCggGrvOduS6UB9Y6l9AeFPEHK3F"
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre>
                                    {
                                       "results": [
                                           {
                                               "response": "accepted"
                                           }
                                       ],
                                       "bookmark": "neo4j:bookmark:v1:tx20278251",
                                       "errors": []
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api25">Agree to Eula</h2>
            <p>This call takes a user grn and marks that the user has agreed with the EULA.</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>GET</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/agreeToEula</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapse25"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapse25" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>userId</td>
                                <td>string</td>
                                <td>String containing the user’s grn</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    GET
                                    https://medic-prod-api.graphgrid.com/1.0/showme/agreeToEula?params=%7B%22userId%22%3A%22xtYHFDJBtciaY66oGCggGrvOduS6UB9Y6l9AeFPEHK3F%22%7D

                                    Non-Url-Encoded Parameters
                                    {
                                       "userId": "xtYHFDJBtciaY66oGCggGrvOduS6UB9Y6l9AeFPEHK3F"
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre>
                                    {
                                       "results": [
                                           {
                                               "response": "accepted"
                                           }
                                       ],
                                       "bookmark": "neo4j:bookmark:v1:tx20278324",
                                       "errors": []
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>

            <h2 id="api26">Global Period</h2>
            <p>This call takes patient and physician grns to determine if any CPTs fall within the global period.</p>
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-1"><code>POST</code></div>
                        <div class="col">https://medic-prod-api.graphgrid.com/1.0/showme/executeShowMe/globalPeriod</div>
                        <div class="col-1 text-right"><a class="card-link" data-toggle="collapse" href="#collapse26"><i class="fa fa-chevron-down"></i></a></div>
                    </div>
                </div>
                <div id="collapse26" class="collapse">
                    <div class="card-body">
                        <table class="table table-striped table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Data Type</th>
                                <th>Meaning</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>patientGrn</td>
                                <td>string</td>
                                <td>String containing the patient’s grn</td>
                            </tr>
                            <tr>
                                <td>physicianGrn</td>
                                <td>string</td>
                                <td>String containing the physician’s grn</td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Request</th>
                                <td><pre>
                                    GET
                                    https://medic-prod-api.graphgrid.com/1.0/showme/globalPeriod

                                    Body
                                    {
                                       "patientGrn": "grn:gg:patient:FOsoC6z2YqHWr1XH1qN2w2CPIX7DAGMPn148qE4mnW9w",
                                       "physicianGrn": "grn:gg:physician:123456"
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-striped table-bordered table-hover">
                            <tbody>
                            <tr>
                                <th class="orange">Example Response</th>
                                <td><pre>
                                    {
                                       "results": [
                                           {
                                               "cpt": "36821",
                                               "procedureDate": "03/19/17"
                                           }
                                       ],
                                       "bookmark": "neo4j:bookmark:v1:tx20278421",
                                       "errors": []
                                    }
                                </pre>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr>
            -->

        </div>

    </div>
        `;
        
        // const currentDomain = window.location.origin;
        api_doc_string = api_doc_string.replace(/REPLACE_WITH_API/g, packageJson.pythonEnv.HOST_API);
        document.getElementById("content").innerHTML = api_doc_string;
    }

    render() {
        return (<div className="row" style={{height: "100%", overflowY: "scroll"}} id="content"></div>)
    }
}