import React from "react";

export class Footer extends React.Component {
    render() {
        return (<footer className="footer bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 h-100 text-center text-lg-left my-auto">
                            {/* <ul className="list-inline mb-2">
                                <li className="list-inline-item">
                                    <a href="/">About</a>
                                </li>
                                <li className="list-inline-item">&sdot;</li>
                                <li className="list-inline-item">
                                    <a href="/">Contact</a>
                                </li>
                                <li className="list-inline-item">&sdot;</li>
                                <li className="list-inline-item">
                                    <a href="/">Terms of Use</a>
                                </li>
                                <li className="list-inline-item">&sdot;</li>
                                <li className="list-inline-item">
                                    <a href="/">Privacy Policy</a>
                                </li>
                            </ul> */}
                            <p className="text-muted small mb-4 mb-lg-0">&copy; Archimedes Medical {new Date().getFullYear()}. All Rights Reserved.</p>
                            {/* <small>Icons made by <a href="https://www.flaticon.com/authors/prosymbols" title="Prosymbols">Prosymbols</a> from <a href="https://www.flaticon.com/"
                                                                                                                                                 title="Flaticon"> www.flaticon.com</a></small> */}
                        </div>
                        <div className="col-lg-6 h-100 text-center text-lg-right my-auto">
                            {/* <ul className="list-inline mb-0">
                                <li className="list-inline-item mr-3">
                                    <a href="/">
                                        <i className="fa fa-facebook fa-2x fa-fw"></i>
                                    </a>
                                </li>
                                <li className="list-inline-item mr-3">
                                    <a href="/">
                                        <i className="fa fa-twitter-square fa-2x fa-fw"></i>
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="/">
                                        <i className="fa fa-instagram fa-2x fa-fw"></i>
                                    </a>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}