import React from "react";
import NarrativePane from "./NarrativePane";
import { Spinner, Button } from 'react-bootstrap';
import packageJson from '../package.json';
import './digital-twin.css';

export class DigitalTwin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            accessKey: localStorage.getItem('accessKey') || '',
            isAccessKeyInputVisible: false,
            results: null,
            error: null,
            isLoading: false,
            numNeighbors: 10,
            collectionName: "mtsamples",
            entityWeights: {
                DISORDER: 1,
                DRUG: 1,
                FINDING: 1,
                PROCEDURE: 1,
                SITUATION: 1,
                TEST: 1
            },
            normalizeEntities: false,
            // normalizeDocVec: true,
            currentResultIndex: 0,
        };

        this.handleAccessKeyChange = this.handleAccessKeyChange.bind(this);
        this.toggleAccessKeyInputVisibility = this.toggleAccessKeyInputVisibility.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.narrativePaneRef = React.createRef();
        this.handlePrevResult = this.handlePrevResult.bind(this);
        this.handleNextResult = this.handleNextResult.bind(this);
        this.resultPaneRef = React.createRef();
    }

    handleAccessKeyChange(event) {
        const accessKey = event.target.value;
        this.setState({ accessKey });
        localStorage.setItem('accessKey', accessKey);
    }

    toggleAccessKeyInputVisibility() {
        this.setState(prevState => ({ isAccessKeyInputVisible: !prevState.isAccessKeyInputVisible }));
    }

    extractEntitiesAndLabels(response) {
        if (!response) return { entities: [], labels: [], negatives: [] };

        const entities = [];
        const labels = [];
        const negatives = [];

        Object.values(response).forEach(item => {
            // Use the phrase from the first result if available, otherwise use item.phrase
            const entityPhrase = item.results && item.results.length > 0 
                ? item.results[0].phrase 
                : item.phrase;

            // Check that the entity is not already in the list
            if (!entities.includes(entityPhrase)) {
                entities.push(entityPhrase);
                labels.push(item.label);
                negatives.push(item.negative || false);
            }
        });

        return {
            entities,
            labels,
            negatives
        };
    }

    async handleSearch() {
        this.setState({ 
            results: null, 
            error: null, 
            isLoading: true,
            currentResultIndex: 0 
        });

        try {
            const paneText = this.narrativePaneRef.current && this.narrativePaneRef.current.state.input_text;

            // First get the entities from the narrative pane
            const phraseResponse = await this.narrativePaneRef.current.onPhraseChange();
            // const docData = this.extractEntitiesAndLabels(phraseResponse);
            const docData = phraseResponse ? this.extractEntitiesAndLabels(phraseResponse) : { entities: [paneText], labels: [], negatives: [false] };

            // Prepare payload for DigitalTwin endpoint
            const payload = {
                doc: {
                    entities: docData.entities,
                    labels: docData.labels,
                    negatives: docData.negatives
                },
                entityWeights: this.state.entityWeights,
                normalizeEntities: this.state.normalizeEntities,
                numNeighbors: this.state.numNeighbors,
                collectionName: this.state.collectionName
            };

            // Call the DigitalTwin endpoint
            const response = await fetch(
                `${packageJson.pythonEnv.HOST_API}/1.0/journey/query/digitalTwin`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Key': this.state.accessKey
                    },
                    body: JSON.stringify(payload)
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            this.setState({ results: data, isLoading: false });

        } catch (error) {
            console.error("Error finding nearest neighbors:", error);
            this.setState({ 
                error: "Failed to find nearest neighbors. Please try again.", 
                isLoading: false 
            });
        }
    }

    handlePrevResult() {
        this.setState(prevState => ({
            currentResultIndex: Math.max(0, prevState.currentResultIndex - 1)
        }));
    }

    handleNextResult() {
        this.setState(prevState => ({
            currentResultIndex: Math.min(
                prevState.results.neighbors.length - 1, 
                prevState.currentResultIndex + 1
            )
        }));
    }

    render() {
        const currentResult = this.state.results && this.state.results.neighbors ? 
            this.state.results.neighbors[this.state.currentResultIndex] : null;
        
        const scoreDisplay = currentResult && currentResult.score ? 
            Math.max(0, Math.min(1, currentResult.score)).toFixed(4) : '0.0000';
        
        return (
            <div className="digital-twin-container">
                <div className="container">
                    <h1>Digital Twin</h1>
                    <hr />

                    {/* Access Key Section */}
                    <div className="access-key-section">
                        <label htmlFor="accessKey">
                            <h5>
                                Access Key &nbsp;
                                <button onClick={this.toggleAccessKeyInputVisibility}>
                                    {this.state.isAccessKeyInputVisible ? '-' : '+'}
                                </button>
                            </h5>
                        </label>
                        <input
                            type="password"
                            className="form-control"
                            id="accessKey"
                            placeholder="Enter Access Key"
                            value={this.state.accessKey}
                            onChange={this.handleAccessKeyChange}
                            style={{
                                width: "300px",
                                display: this.state.isAccessKeyInputVisible ? 'block' : 'none',
                            }}
                        />
                    </div>
                    <br />

                    {/* Search Button */}
                    <div className="search-button-section text-center">
                        <button type="button" className="btn btn-primary" onClick={this.handleSearch}>
                            <i className="fa fa-search"></i> Find Digital Twin
                        </button>
                        <br/>
                        {this.state.isLoading && (
                            <Spinner animation="border" role="status" className="mt-2">
                                <span className="visually-hidden"></span>
                            </Spinner>
                        )}
                        {this.state.error && (
                            <div className="alert alert-danger mt-2" role="alert">
                                {this.state.error}
                            </div>
                        )}
                    </div>
                    <br />

                    {/* Narrative Pane */}
                    <div className="row">
                        <div className="col-md-6">
                            <NarrativePane
                                ref={this.narrativePaneRef}
                                accessKey={this.state.accessKey}
                                select_API_host={packageJson.pythonEnv.HOST_API}
                                paneId="main"
                            />
                        </div>
                        <div className="col-md-6">
                            {this.state.results && (
                                <>
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                        <Button 
                                            variant="outline-primary"
                                            onClick={this.handlePrevResult}
                                            disabled={this.state.currentResultIndex === 0}
                                        >
                                            &lt;&lt;
                                        </Button>
                                        <h4>
                                            Result {this.state.currentResultIndex + 1} &nbsp;
                                            Score: {scoreDisplay}
                                        </h4>
                                        <Button 
                                            variant="outline-primary"
                                            onClick={this.handleNextResult}
                                            disabled={this.state.currentResultIndex === this.state.results.neighbors.length - 1}
                                        >
                                            &gt;&gt;
                                        </Button>
                                    </div>
                                    <NarrativePane
                                        ref={this.resultPaneRef}
                                        accessKey={this.state.accessKey}
                                        select_API_host={packageJson.pythonEnv.HOST_API}
                                        paneId="result"
                                        initialText={currentResult ? currentResult.text : ''}
                                        initialEntities={currentResult ? currentResult.entities : null}
                                        readOnly={true}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DigitalTwin; 