import React from "react";
import * as d3 from "d3";
import './react-smart-data-table.css';
import  SmartDataTable from 'react-smart-data-table';
import packageJson from '../package.json';

export class Dashboard extends React.Component {

    constructor(props) {
        super(props);

        this.panels = ["dashboard", "history", "cost_analysis", "naive_bayes", "mb_analysis", "nearest_neighbors"];
        this.selected_panel = "dashboard";
        this.panel_to_patient_dict = {};

        this.state = {
            table: {
                title: "Table",
                headers: ["icd_name", "icd_code", "riaks", "date_assigned"],
                data: [],
                headerMap: {
                    "icd_name": {text: "ICD Name"},
                    "icd_code": {
                        text: "ICD Code",
                        transform: (value, index, row) => (<a href={"https://www.icd10data.com/search?s=" + value} target="_blank" rel="noopener noreferrer">{value}</a>)
                    },
                    "riaks": {text: "Riak Nodes"},
                    "date_assigned": {text: "Date Assigned"}
                },
                perPageItemCount: 10
            },
            pred_table: {
                title: "Prediction Table",
                headers: ["riak_associations", "riak_consequence", "probability"],
                headerMap: {
                    "riak_associations": {text: "Current Conditions"},
                    "riak_consequence": {text: "Predicted Condition"},
                    "probability": {text: "Conditional Probability"}
                },
                data: [],
                perPageItemCount: 10
            },
            nn_table: {
                title: "Prediction Table",
                headers: ["name", "probability"],
                headerMap: {
                  "name": {text: "Predicted Condition"},
                  "probability": {text: "Probability"}
                },
                data: [],
                perPageItemCount: 10
            },
            nb_table: {
                title: "Prediction Table",
                headers: ["name", "rank"],
                headerMap: {
                    "name": {text: "Predicted Condition"},
                    "rank": {text: "Predicted Rank"}
                },
                data: [],
                columns: "name.rank",
                perPageItemCount: 10
            },
            nn_table_2: {
                title: "Prediction Table",
                headers: ["distance", "patient_id"],
                headerMap: {
                    "distance": {text: "Distance"},
                    "patient_id": {
                        text: "Patient ID",
                        transform: (value, index, row) => (<a href={"/dashboard?pid=" + value} target="_blank" rel="noopener noreferrer">{value}</a>)
                    }
                },
                data: [],
                perPageItemCount: 10
            }
        };

        this.riak_dict = {};
        this.demoIndex = 0;

        this.node_label_opacity = 1;
        this.edge_label_opacity = 0;
        this.pred_visibility = "hidden";
        this.pr_threshold = 10;

        this.textElements = null;
        this.linkLabels = null;
        this.nodeElements = null;
        this.linkElements = null;

        this.selectedNode = null;
        this.selectedNodeName = null;

        this.itemPanelClicked = this.itemPanelClicked.bind(this);
        this.httpRequest = this.httpRequest.bind(this);
        this.onSlideChange = this.onSlideChange.bind(this);
        this.onDemoClick = this.onDemoClick.bind(this);
        this.loadLegend = this.loadLegend.bind(this);
        this.showNodeLabels = this.showNodeLabels.bind(this);
        this.showEdgeLabels = this.showEdgeLabels.bind(this);
        this.updateGraphDisplay = this.updateGraphDisplay.bind(this);
        this.showPredictionNodes = this.showPredictionNodes.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.getNodeColor = this.getNodeColor.bind(this);
        this.getNodeStrokeColor = this.getNodeStrokeColor.bind(this);
        this.getNodeStrokeWidth = this.getNodeStrokeWidth.bind(this);
        this.drawGraph = this.drawGraph.bind(this);
        this.renderNodeInformation = this.renderNodeInformation.bind(this);
        this.updateTable = this.updateTable.bind(this);
        this.updateTablePrediction = this.updateTablePrediction.bind(this);
        this.updateTablePredictionNN = this.updateTablePredictionNN.bind(this);
        this.updateTablePredictionNB = this.updateTablePredictionNB.bind(this);
        this.updateTableNearestNeighbors = this.updateTableNearestNeighbors.bind(this);
        this.drawBarChart = this.drawBarChart.bind(this);
        this.drawBarChartNN = this.drawBarChartNN.bind(this);
        this.drawScatterPlot = this.drawScatterPlot.bind(this);
        this.httpRequestRafScore = this.httpRequestRafScore.bind(this);
    }

    itemPanelClicked(item) {
        for (let i = 0; i < this.panels.length; i++) {
            if (this.panels[i] === item) {
                document.getElementById(this.panels[i]).classList.remove("d-none");
                this.selected_panel = this.panels[i];

                let pid = document.getElementById("patient_node").value;
                if (this.panel_to_patient_dict[this.panels[i]] !== pid) {
                    this.panel_to_patient_dict[this.panels[i]] = pid;
                    this.onSearch();
                }

            } else {
                document.getElementById(this.panels[i]).classList.add("d-none");
            }
        }
    }

    httpRequest(url, params, callback) {
        var xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = function () {
            if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
                callback(JSON.parse(xmlHttp.response));
            } else if (xmlHttp.readyState === 4) {

                if (xmlHttp.status === 400) {
                    // something went wrong with the data
                    document.getElementById('alert-no-results').classList.remove('d-none');
                } else {
                    // something went wrong with the backend
                    document.getElementById('alert-error').classList.remove('d-none');
                }

                document.getElementById('loader').classList.add('d-none');
            }
        };
        xmlHttp.open("POST", url, true);
        xmlHttp.setRequestHeader("Content-Type", "application/json");
        xmlHttp.send(JSON.stringify(params));
    }

    componentDidMount() {

        for (let i = 0; i < this.panels.length; i++) {
            this.panel_to_patient_dict[this.panels[i]] = "";
        }

        this.loadLegend();

        const urlParams = new URLSearchParams(window.location.search);
        let pid = urlParams.get("pid");
        if (pid) {
            document.getElementById("patient_node").value = pid;
        }
        //$('[data-toggle="tooltip"]').tooltip();
    }

    onSlideChange() {
        let slider = document.getElementById("myRange");
        this.pr_threshold = slider.value;
        this.updateGraphDisplay();
    }

    onDemoClick() {
        let test_examples = [
            "0067BBCE45146AF6",
            "0065A917BBAEC579",
            "00680BFF8DD56CB6"
        ];

        let pid = document.getElementById("patient_node").value = test_examples[this.demoIndex];

        this.panel_to_patient_dict[this.selected_panel] = pid;
        this.onSearch();
        this.demoIndex = (this.demoIndex + 1) % test_examples.length;
    }

    loadLegend() {
        let el = document.getElementById('legend');
        const width = el.offsetWidth;
        const height = 50;

        let svg = d3.select("#legend")
            .append("svg")
            .attr("width", width)
            .attr("height", height);

        //let nodes = ["Riak", "IcdTen", "Prediction"];
        let nodes = ["Riak", "Prediction"];
        let colors = ["#FFCD00", "#3399ff"];
        let chunk_size = 70;

        for (let i = 0; i < nodes.length; i++) {
            let x = i * chunk_size;
            let y = 10;
            svg.append("circle")
                .attr("r", 10)
                .attr("fill", colors[i])
                .attr("opacity", 0.8)
                .attr("cx", x + 10)
                .attr("cy", y);
            svg.append("text")
                .text(nodes[i])
                .attr('font-size', 10)
                .attr('dx', x + 25)
                .attr('dy', y + 5)
                .attr('fill', this.getNodeColor(nodes[i]))
                .attr('opacity', 1);
        }

    }

    showNodeLabels() {
        if (document.getElementById("toggle_node_labels").checked) {
            this.node_label_opacity = 1;
        } else {
            this.node_label_opacity = 0;
        }

        if (this.textElements) {
            this.textElements.attr("opacity", this.node_label_opacity);
        }
    }

    showEdgeLabels() {
        if (document.getElementById("toggle_edge_labels").checked) {
            this.edge_label_opacity = 1;
        } else {
            this.edge_label_opacity = 0;
        }

        if (this.linkLabels) {
            this.linkLabels.attr("opacity", this.edge_label_opacity);
        }
    }

    updateGraphDisplay() {
        if (this.nodeElements) {
            this.nodeElements.attr("visibility", function (node) {
                let value = "visible";
                if (node.type === "Prediction") {
                    value = this.pred_visibility;
                }

                if (node.weight < this.pr_threshold) {
                    value = "hidden";
                }

                return value;
            });
            this.textElements.attr("visibility", function (node) {
                let value = "visible";
                if (node.type === "Prediction") {
                    value = this.pred_visibility;
                }

                if (node.weight < this.pr_threshold) {
                    value = "hidden";
                }

                return value;
            });
            this.linkElements.attr("visibility", function (link) {
                let value = "visible";
                if (link.name === "PREDICTS") {
                    value = this.pred_visibility;
                }

                if (link.source.weight < this.pr_threshold || link.target.weight < this.pr_threshold) {
                    value = "hidden";
                }

                return value;
            });
            this.linkLabels.attr("visibility", function (link) {
                let value = "visible";
                if (link.name === "PREDICTS") {
                    value = this.pred_visibility;
                }

                if (link.source.weight < this.pr_threshold || link.target.weight < this.pr_threshold) {
                    value = "hidden";
                }

                return value;
            });
        }
    }

    showPredictionNodes() {

        if (this.pred_visibility === "visible") {
            this.pred_visibility = "hidden";
        } else {
            this.pred_visibility = "visible";
        }

        this.updateGraphDisplay();
    }

    onSearch() {

        document.getElementById('loader').classList.remove('d-none');

        let patient = document.getElementById("patient_node").value;
        //let mode = document.getElementById("mode").value;

        if (patient === null || patient === "") {
            document.getElementById('loader').classList.add('d-none');
            document.getElementById('alert-invalid').classList.remove('d-none');
            return;
        }

        let params = {
            "patient_id": patient
        };

        let ctrl = this;
        switch (this.selected_panel) {

            case "dashboard": {
                this.renderNodeInformation(null);
                this.httpRequest(packageJson.pythonEnv.HOST_API1 + "/1.0/journey/query/graph", params, function (data) {
                    ctrl.drawGraph(data.graph);
                    ctrl.riak_dict = data.table;

                    document.getElementById("risk_score").innerHTML = data.risk_score.toFixed(2);
                    document.getElementById('loader').classList.add('d-none');
                });
                break;
            }
            case "history": {
                this.httpRequest(packageJson.pythonEnv.HOST_API1 + "/1.0/journey/query/history", params, function (data) {
                    ctrl.updateTable(data.table);
                    document.getElementById('loader').classList.add('d-none');
                });
                break;
            }
            case "cost_analysis": {

                params = {};
                params["patientId"] = patient;

                this.httpRequestRafScore("https://medic-prod-api.graphgrid.com/1.0/search/executeSearch/medic-prod-neo/medic-prod-index-policy/deidentifiedPatientSearch", params, function (response) {
                    if (response.data) {
                        let data = [];
                        if (response.data.deidentifiedpatient) {
                            data = response.data.deidentifiedpatient.documentData;
                        }
                        ctrl.drawScatterPlot(data);
                    }
                    document.getElementById('loader').classList.add('d-none');
                });
                break;

            }
            case "naive_bayes": {
                this.httpRequest(packageJson.pythonEnv.HOST_API1 + "/1.0/journey/query/nbayes", params, function (data) {
                    ctrl.updateTablePredictionNB(data.result);
                    document.getElementById('loader').classList.add('d-none');
                });
                break;
            }
            case "mb_analysis": {
                this.httpRequest(packageJson.pythonEnv.HOST_API1 + "/1.0/journey/query/stats", params, function (data) {
                    ctrl.updateTablePrediction(data.table);
                    ctrl.drawBarChart(data.table);
                    document.getElementById('loader').classList.add('d-none');
                });
                break;
            }
            case "nearest_neighbors": {
                this.httpRequest(packageJson.pythonEnv.HOST_API1 + "/1.0/journey/query/knn", params, function (data) {
                    ctrl.updateTablePredictionNN(data["nn_table"]);
                    ctrl.updateTableNearestNeighbors(data["nn_table_cohort"]);
                    ctrl.drawBarChartNN(data["nn_table"]);
                    document.getElementById('loader').classList.add('d-none');
                });
                break;
            }
            default:
                break;

        }

    }

    getNodeColor(node) {

        if (node.type === "Riak") {
            switch (node.color) {
                case 6:
                    return "#ffff80";
                case 5:
                    return "#ffe680";
                case 4:
                    return "#ffdf80";
                case 3:
                    return "#ffbf80";
                case 2:
                    return "#ff9f80";
                case 1:
                    return "#ff8080";
                case 0:
                    return "#ff0000";
                default:
                    return "#80ff80";
            }

        } else if (node.type === "Prediction") {
            return '#3399ff';
            /*switch (node.color) {
                case 6: return "#00cccc";
                case 5: return "#0099cc";
                case 4: return "#0066cc";
                case 3: return "#9900cc";
                case 2: return "#cc00cc";
                case 1: return "#cc0066";
                case 0: return "#ff0000";
                default: return "#00cc99";
            }*/
        }

        /*
        if (node.type == "Riak") {
            switch (node.color) {
                case 0: return "#FFCD00";
                case 1: return "#ffcc00";
                case 2: return "#ffe680";
                case 3: return "#ffd633";
                case 4: return "#ffe066";
                case 5: return "#ff9900";
                case 6: return "#ffad33";
                case 7: return "#ffc266";
                case 8: return "#ffa31a";
                case 9: return "#ffcc80";
                default: return "#ffe6cc";
            }

        } else if (node.type == "Prediction") {
            switch (node.color) {
                case 0: return "#3399ff";
                case 1: return "#66b3ff";
                case 2: return "#99ccff";
                case 3: return "#0080ff";
                case 4: return "#1ad1ff";
                case 5: return "#33ccff";
                case 6: return "#66d9ff";
                case 7: return "#99e6ff";
                case 8: return "#00bfff";
                case 9: return "#0099cc";
                default: return "#00ace6";
            }
        }*/

    }

    getNodeStrokeColor(node) {
        if (node.type === "Riak") {
            return "#ff0000";
        } else if (node.type === "Prediction") {
            return "#00ffff";
        }
    }

    getNodeStrokeWidth(node) {
        return 0;
        /*if (node.type == "Riak") {
            return 0;
        } else if (node.type == "Prediction") {
            return 2;
        }*/
    }

    drawGraph(data) {

        let ctrl = this;
        let el = document.getElementById("network_viz");

        const width = el.offsetWidth;
        const height = 700;

        while (el.childElementCount > 0) {
            el.removeChild(el.childNodes[0]);
        }

        const svg = d3.select('#network_viz')
            .append('svg')
            .attr('width', width)
            .attr('height', height);

        function getSize(node) {
            switch (node.type) {
                case "Riak":
                    return Math.min(node.weight, 75);
                case "Prediction":
                    return Math.min(node.weight, 75);
                case "IcdTen":
                    return 15;
                default:
                    return 15;
            }

        }

        svg.append("defs").append("marker")
            .attr("id", "arrow")
            .attr("viewBox", "0 -5 10 10")
            .attr("refX", 10)
            .attr("refY", 0)
            .attr("markerWidth", 20)
            .attr("markerHeight", 20)
            .attr("orient", "auto")
            .append("svg:path")
            .attr("d", "M0,-5L10,0L0,5")
            .attr('fill', '#AEB1DD');

        this.linkElements = svg.append('g')
            .selectAll('line')
            .data(data.edges)
            .enter().append('line')
            .attr('stroke-width', 1)
            .attr('stroke', '#AEB1DD')
            .attr('opacity', 0.5)
            .attr("marker-end", "url(#arrow)");

        this.linkLabels = svg.append('g')
            .selectAll('text')
            .data(data.edges)
            .enter().append('text')
            .text(link => link.name)
            .attr('font-size', 10)
            .attr('dx', 0)
            .attr('dy', 0)
            .attr('fill', "#71738F")
            .attr('opacity', this.edge_label_opacity);

        this.nodeElements = svg.append('g')
            .selectAll('circle')
            .data(data.nodes)
            .enter().append('circle')
            .attr('r', getSize)
            .attr('fill', this.getNodeColor)
            .attr('opacity', 1)
            .attr('stroke', this.getNodeStrokeColor)
            .attr('stroke-width', this.getNodeStrokeWidth)
            .on("mouseover", function (node) {
                d3.select(this).attr('stroke-width', 3);
            })
            .on("mouseleave", function (node) {
                if (node.name !== ctrl.selectedNodeName) {
                    d3.select(this).attr('stroke-width', ctrl.getNodeStrokeWidth(node));
                }
            })
            .on("click", function (node) {
                if (node.type === "Riak") {
                    if (ctrl.selectedNode) {
                        ctrl.selectedNode.attr('stroke-width', 0);
                    }
                    d3.select(this).attr('stroke-width', 3);
                    ctrl.selectedNode = d3.select(this);
                    ctrl.selectedNodeName = node.name;
                    ctrl.renderNodeInformation(node);
                }
                //window.open("https://codecatcher.co?search=" + node.name, "_blank");
            });

        this.textElements = svg.append('g')
            .selectAll('text')
            .data(data.nodes)
            .enter().append('text')
            .text(node => node.name)
            .attr('font-size', 30)
            .attr('dx', node => -node.name.length * 6)
            .attr('dy', node => -getSize(node) - 5)
            .attr('fill', "#8092B0")
            .attr('opacity', this.node_label_opacity);

        const simulation = d3.forceSimulation(data.nodes)
            .force('charge', d3.forceManyBody().strength(-400))
            .force('center', d3.forceCenter(width / 2, height / 2))
            .force('link', d3.forceLink()
                .id(node => node.name)
                .strength(edge => edge.weight)
                .links(data.edges));

        simulation.nodes(data.nodes).on('tick', () => {
            this.nodeElements
                .attr('cx', node => node.x)
                .attr('cy', node => node.y);
            this.textElements
                .attr('x', node => node.x)
                .attr('y', node => node.y);
            this.linkLabels
                .attr('x', link => (link.source.x + link.target.x) / 2)
                .attr('y', link => (link.source.y + link.target.y) / 2);
            this.linkElements
                .attr('x1', link => link.source.x)
                .attr('y1', link => link.source.y)
                .attr('x2', function (link) {
                    let diffX = link.target.x - link.source.x;
                    let diffY = link.target.y - link.source.y;

                    // Length of path from center of source node to center of target node
                    let pathLength = Math.sqrt((diffX * diffX) + (diffY * diffY));

                    // x and y distances from center to outside edge of target node
                    let offsetX = (diffX * getSize(link.target)) / pathLength;
                    return link.target.x - offsetX;
                })
                .attr('y2', function (link) {
                    let diffX = link.target.x - link.source.x;
                    let diffY = link.target.y - link.source.y;

                    // Length of path from center of source node to center of target node
                    let pathLength = Math.sqrt((diffX * diffX) + (diffY * diffY));

                    // x and y distances from center to outside edge of target node
                    let offsetY = (diffY * getSize(link.target)) / pathLength;
                    return link.target.y - offsetY;
                })
        });

        const dragDrop = d3.drag()
            .on('start', node => {
                node.fx = node.x;
                node.fy = node.y;
            })
            .on('drag', node => {
                simulation.alphaTarget(0.7).restart();
                node.fx = d3.event.x;
                node.fy = d3.event.y;
            })
            .on('end', node => {
                if (!d3.event.active) {
                    simulation.alphaTarget(0);
                }
                node.fx = null;
                node.fy = null;
            });

        function zoomed() {
            ctrl.nodeElements.attr("transform", d3.event.transform);
            ctrl.textElements.attr("transform", d3.event.transform);
            ctrl.linkLabels.attr("transform", d3.event.transform);
            ctrl.linkElements.attr("transform", d3.event.transform);
        }

        let zoom = d3.zoom()
            .extent([[0, 0], [width, height]])
            .scaleExtent([0.1, 8])
            .on("zoom", zoomed);

        // initial zoom out
        svg.call(zoom).call(zoom.transform, d3.zoomIdentity.translate(250, 250).scale(0.3));

        this.nodeElements.call(dragDrop);

        this.updateGraphDisplay();
    }

    renderNodeInformation(node) {

        let el = document.getElementById("node_information");

        if (!node) {
            el.innerHTML = "";
            return;
        }

        if (node.type === "Riak") {

            let icds = this.riak_dict[node.name].icd_codes;
            let names = this.riak_dict[node.name].icd_names;
            let code_el = ``;
            for (let i = 0; i < icds.length; i++) {
                code_el = code_el + `<a href="https://www.icd10data.com/search?s=${icds[i]}" data-toggle="tooltip" title="${names[i]}" target="_blank">${icds[i]}</a><br>`;
            }

            let risks = this.riak_dict[node.name].risks;
            if (risks) {
                if (risks.length === 0) {
                    risks = "None that are likely"
                } else {
                    risks = risks.join(`<br>`);
                }
            } else {
                risks = "Not Known";
            }

            let consequences = this.riak_dict[node.name].consequences;
            if (consequences) {
                if (consequences.length === 0) {
                    consequences = "None that are likely"
                } else {
                    consequences = consequences.join(`<br>`);
                }
            } else {
                consequences = "Not Known";
            }

            let death_path = this.riak_dict[node.name].death_path;
            if (death_path) {
                if (death_path.length === 0) {
                    death_path = "No path to death"
                } else {
                    death_path = death_path.join(`<br>`);
                }
            } else {
                death_path = "Not Known";
            }

            //<span class="font-weight-bold">Type: </span> ${node.type} <br>
            let template =
                `<span class="font-weight-bold">Selected: </span><a href="https://codecatcher.co?riakId=${node.id}" target="_blank">${node.name}</a><br><br>
                <h6>Average Cost</h6><a href="/physician?riak=${node.id}" target="_blank">${node.name}</a><br><br>
                <h6>ICD Codes</h6>` + code_el + `<br>
                <h6>Risks</h6>
                <p>${risks}</p>
                <h6>Potential Consequences</h6>
                <p>${consequences}</p>
                <h6>Path to Death</h6>
                <p>${death_path}</p>
                `;

            el.innerHTML = template;
            //$('[data-toggle="tooltip"]').tooltip();

        }
    }

    updateTable(results) {

        let data = [];

        for (let i = 0; i < results.length; i++) {
            let code = results[i]["icd_id"];
            data.push({
                'icd_name': results[i]["icd_name"],
                'icd_code': code,
                'date_assigned': results[i]["date"].substring(0, 10),
                'riaks': results[i]["riaks"].join("\n")
            });
        }

        let table = this.state.table;
        table.data = data;

        this.setState({
            table: table
        });
    }

    updateTablePrediction(results) {
        let table = this.state.pred_table;
        table.data = results;

        this.setState({
            pred_table: table
        });
    }

    updateTablePredictionNN(results) {
        let table = this.state.nn_table;
        table.data = results;

        this.setState({
            nn_table: table
        });
    }

    updateTablePredictionNB(results) {
        let table = this.state.nb_table;
        table.data = results;

        this.setState({
            nb_table: table
        });
    }

    updateTableNearestNeighbors(results) {
        // initialize this.table
        let headers = ["Distance", "Patient ID"];
        let keys = Object.keys(results[0]);
        for (let i = 0; i < keys.length; i++) {
            if (keys[i] === "distance" || keys[i] === "patient_id") {
                continue;
            }
            headers.push(keys[i]);
        }

        let data = [];

        function prependZero(number) {
            number = parseInt(number);
            /*if (number < 10)
                return "0" + number;
            else
                return String(number);*/
            if (number === 0)
                number = 1;
            return number;
        }

        for (let i = 0; i < results.length; i++) {
            let result = results[i];
            result["distance"] = prependZero(results[i]["distance"]);
            data.push(result);
        }

        let table = this.state.nn_table_2;
        table.data = data;

        this.setState({
            nn_table_2: table
        });
    }

    drawBarChart(data) {

        // set the dimensions and margins of the graph
        var margin = {top: 30, right: 30, bottom: 150, left: 60};

        let el = document.getElementById("bar_chart");

        const width = el.offsetWidth - margin.left - margin.right;
        const height = 700 - margin.top - margin.bottom;

        while (el.childElementCount > 0) {
            el.removeChild(el.childNodes[0]);
        }

        // append the svg object to the body of the page
        var svg = d3.select("#bar_chart")
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

        // sort data
        data = data.sort(function (b, a) {
            return a.probability - b.probability;
        }).slice(0, 20);

        // X axis
        let x = d3.scaleBand()
            .range([0, width])
            .domain(data.map(function (d) {
                return d.riak_consequence;
            }))
            .padding(0.2);
        svg.append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x))
            .selectAll("text")
            .attr("transform", "translate(-10,0)rotate(-45)")
            .style("text-anchor", "end");

        // Add Y axis
        let y = d3.scaleLinear()
            .domain([0, 1])
            .range([height, 0]);
        svg.append("g")
            .call(d3.axisLeft(y));

        // Bars
        svg.selectAll("mybar")
            .data(data)
            .enter()
            .append("rect")
            .attr("x", function (d) {
                return x(d.riak_consequence);
            })
            .attr("y", function (d) {
                return y(d.probability);
            })
            .attr("width", x.bandwidth())
            .attr("height", function (d) {
                return height - y(d.probability);
            })
            .attr("fill", "#69b3a2");
    }

    drawBarChartNN(data) {

        // set the dimensions and margins of the graph
        var margin = {top: 30, right: 30, bottom: 150, left: 60};

        let el = document.getElementById("bar_chart_nn");

        const width = el.offsetWidth - margin.left - margin.right;
        const height = 700 - margin.top - margin.bottom;

        while (el.childElementCount > 0) {
            el.removeChild(el.childNodes[0]);
        }

        // append the svg object to the body of the page
        var svg = d3.select("#bar_chart_nn")
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

        // sort data
        data = data.sort(function (b, a) {
            return a.probability - b.probability;
        }).slice(0, 20);

        // X axis
        let x = d3.scaleBand()
            .range([0, width])
            .domain(data.map(function (d) {
                return d.name;
            }))
            .padding(0.2);
        svg.append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x))
            .selectAll("text")
            .attr("transform", "translate(-10,0)rotate(-45)")
            .style("text-anchor", "end");

        // Add Y axis
        let y = d3.scaleLinear()
            .domain([0, 1])
            .range([height, 0]);
        svg.append("g")
            .call(d3.axisLeft(y));

        // Bars
        svg.selectAll("mybar")
            .data(data)
            .enter()
            .append("rect")
            .attr("x", function (d) {
                return x(d.name);
            })
            .attr("y", function (d) {
                return y(d.probability);
            })
            .attr("width", x.bandwidth())
            .attr("height", function (d) {
                return height - y(d.probability);
            })
            .attr("fill", "#69b3a2");
    }

    drawScatterPlot(results) {

        let margin = {top: 10, right: 50, bottom: 30, left: 50};
        const height = 500 - margin.top - margin.bottom;
        const width = height;

        let el = document.getElementById("raf_plot");
        while (el.childElementCount > 0) {
            el.removeChild(el.childNodes[0]);
        }

        if (!results[0]) {
            return;
        }

        let data = [];
        for (let i = 0; i < results[0].source.years.length; i++) {
            let year = results[0].source.years[i];

            data.push({
                "year": year,
                "x": parseInt(year) - 2007,
                "y": results[0].source.costPerYear[year] / results[0].source.expectedCostPerYear[year],
                "observed": results[0].source.costPerYear[year],
                "expected": results[0].source.expectedCostPerYear[year]
            });
        }

        // append the svg object to the body of the page
        var svg = d3.select("#raf_plot")
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

        // Add X axis
        var x = d3.scaleLinear()
            .domain([0, 4])
            .range([0, width]);
        svg.append("g")
            .attr("transform", "translate(0," + (height - margin.bottom) + ")")
            .call(d3.axisBottom(x).ticks(1));

        svg.append("text")
            .attr("text-anchor", "end")
            .attr("x", 50)
            .attr("y", height - 15)
            .text("Year");

        // Add Y axis
        var y = d3.scaleLinear()
            .domain([0, Math.max(d3.max(data, d => d.y), 2)])
            .range([height - margin.bottom, 0]);
        svg.append("g")
            .call(d3.axisLeft(y).ticks(1));
        svg.append("text")
            .attr("text-anchor", "end")
            .attr("x", -height + 270)
            .attr("y", -3)
            .attr("transform", "rotate(-90)")
            .text("Observed / Expected Cost Ratio");

        var tooltip = d3.select("#raf_plot")
            .append("div")
            .style("display", "none")
            .style("opacity", 1)
            .attr("class", "tooltip")
            .style("background-color", "white")
            .style("border", "solid")
            .style("border-width", "1px")
            .style("border-radius", "5px")
            .style("padding", "10px");


        // A function that change this tooltip when the user hover a point.
        // Its opacity is set to 1: we can now see it. Plus it set the text and position of tooltip depending on the datapoint (d)
        var mouseover = function (d) {
            tooltip.style("display", "block");
            d3.select(this).attr("r", 10);
        };

        var mousemove = function (d) {

            let template = `(${d.x}, ${d.y})<br>
                            Year: ${d.year}<br>
                            Observed: ${d.observed}<br>
                            Expected: ${d.expected}`;

            tooltip
                .html(template)
                .style("left", (d3.mouse(this)[0] + 90) + "px") // It is important to put the +90: other wise the tooltip is exactly where the point is an it creates a weird effect
                .style("top", (d3.mouse(this)[1]) + "px")
        };

        // A function that change this tooltip when the leaves a point: just need to set opacity to 0 again
        var mouseleave = function (d) {
            tooltip.style("display", "none");
            d3.select(this)
                .transition()
                .duration(200)
                .attr("r", 5);
        };

        function getColor(d) {
            switch (d.year) {
                case "2008":
                    return "#FF0000";
                case "2009":
                    return "#00FF00";
                default:
                    return "#0000FF";
            }
        }

        // Add dots
        this.nodeElements = svg.append('g')
            .selectAll("dot")
            .data(data)
            .enter()
            .append("circle")
            .attr("cx", function (d) {
                return x(d.x);
            })
            .attr("cy", function (d) {
                return y(d.y);
            })
            .attr("r", 5)
            .style("fill", getColor)
            .style("opacity", 1)
            .style("stroke-width", 1)
            .style("stroke", "#000000")
            .on("mouseover", mouseover)
            .on("mousemove", mousemove)
            .on("mouseleave", mouseleave);

        svg.append('g')
            .append("line")
            .attr('x1', x(0))
            .attr('x2', x(4))
            .attr('y1', y(1))
            .attr('y2', y(1))
            .style("stroke", "#FF0000");

        // Handmade legend
        svg.append("circle").attr("cx", 300).attr("cy", 30).attr("r", 6).style("fill", "#ff0000");
        svg.append("circle").attr("cx", 300).attr("cy", 60).attr("r", 6).style("fill", "#00ff00");
        svg.append("circle").attr("cx", 300).attr("cy", 90).attr("r", 6).style("fill", "#0000ff");

        svg.append("text").attr("x", 320).attr("y", 30).text("2008").style("font-size", "15px").attr("alignment-baseline", "middle");
        svg.append("text").attr("x", 320).attr("y", 60).text("2009").style("font-size", "15px").attr("alignment-baseline", "middle");
        svg.append("text").attr("x", 320).attr("y", 90).text("2010").style("font-size", "15px").attr("alignment-baseline", "middle");

    }

    httpRequestRafScore(endpoint, params, callback) {

        var xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = function () {
            if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
                callback(JSON.parse(xmlHttp.response));
            } else if (xmlHttp.readyState === 4) {

                if (xmlHttp.status === 400) {
                    // something went wrong with the data
                    document.getElementById('alert-no-results').classList.remove('d-none');
                } else {
                    // something went wrong with the backend
                    document.getElementById('alert-error').classList.remove('d-none');
                }

                document.getElementById('loader').classList.add('d-none');
            }
        }

        let url = new URL(endpoint);
        url.searchParams.append("params", JSON.stringify(params));

        xmlHttp.open("GET", url.toString(), true);
        xmlHttp.setRequestHeader("Authorization", "Bearer 4e11f4f5-cabb-45f6-b042-fafd7e464b99");
        xmlHttp.send(null);
    }


    render() {
        return (
            <div className="container-fluid my-3">
                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-1">
                        <div className="item-panel" onClick={() => this.itemPanelClicked('dashboard')}><i className="fa fa-desktop"></i>Dashboard</div>
                        <div className="item-panel" onClick={() => this.itemPanelClicked('history')}><i className="fa fa-clipboard"></i>History</div>
                        <div className="item-panel" onClick={() => this.itemPanelClicked('cost_analysis')}><i className="fa fa-area-chart"></i>Cost</div>
                        <div className="item-panel" onClick={() => this.itemPanelClicked('naive_bayes')}><i className="fa fa-cogs"></i>Naive Bayes</div>
                        <div className="item-panel" onClick={() => this.itemPanelClicked('mb_analysis')}><i className="fa fa-shopping-cart"></i>MB Analysis</div>
                        <div className="item-panel" onClick={() => this.itemPanelClicked('nearest_neighbors')}><i className="fa fa-users"></i>KNN</div>
                    </div>
                    <div className="col">

                        <div id="alert-invalid" className="alert alert-warning alert-dismissible d-none">
                            <strong>Warning!</strong> Patient ID is invalid! Please check again.
                            <button type="button" className="close" onClick={() => document.getElementById('alert-invalid').classList.add('d-none')}>&times;</button>
                        </div>
                        <div id="alert-error" className="alert alert-danger alert-dismissible d-none">
                            <strong>Error!</strong> There was an internal server error. Please try again.
                            <button type="button" className="close" onClick={() => document.getElementById('alert-error').classList.add('d-none')}>&times;</button>
                        </div>
                        <div id="alert-no-results" className="alert alert-warning alert-dismissible d-none">
                            <strong>Warning!</strong> There are no diagnostic codes found for this patient.
                            <button type="button" className="close" onClick={() => document.getElementById('alert-no-results').classList.add('d-none')}>&times;</button>
                        </div>

                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <label htmlFor="patient_node">Patient ID:</label>
                                <input type="text" id="patient_node" placeholder="Enter deidentified patient id here..." className="form-control"
                                       spellCheck="false"/>
                                <small className="form-text text-muted">Please enter a patient ID here, or click "Choose for me" to select one at random.</small>
                            </div>
                            <div className="col-sm-3">
                                <button type="button" className="btn btn-primary mt-4" id="submit" onClick={this.onSearch}><i
                                    className="fa fa-spinner fa-spin d-none" id="loader"></i> Search
                                </button>
                                <input type="button" className="btn btn-primary mt-4" id="demo" value="Choose for me" onClick={this.onDemoClick}/>
                                <small className="form-text text-muted d-none" id="mode-2-warning">Note: It may take up to a minute to display any data.</small>
                            </div>
                        </div>

                        <div id="dashboard">
                            <h3>Legend</h3>
                            <div className="row">

                                <div className="col-2">
                                    <div id="legend" className="my-3"></div>
                                </div>

                                <div className="col row pt-3" id="filters">
                                    <div className="col-2">
                                        <input className="form-check-input" type="checkbox" id="toggle_node_labels" onClick={this.showNodeLabels} defaultChecked/>
                                        <label htmlFor="toggle_node_labels" className="form-check-label">Node Labels</label>
                                    </div>
                                    <div className="col-2">
                                        <input className="form-check-input" type="checkbox" id="toggle_edge_labels" onClick={this.showEdgeLabels}/>
                                        <label htmlFor="toggle_edge_labels" className="form-check-label">Edge Labels</label>
                                    </div>
                                    <div className="col-2">
                                        <input className="form-check-input" type="checkbox" id="toggle_prediction_nodes" onClick={this.showPredictionNodes}/>
                                        <label htmlFor="toggle_prediction_nodes" className="form-check-label">Predictions</label>
                                    </div>
                                    <div className="col-4 slidecontainer">
                                        <label htmlFor="myRange" className="form-check-label" data-toggle="tooltip" title="Adjust this slider to change
                the number of nodes rendered on the canvas based on the node size. Smaller values will show nodes with less
                consequences, and larger values will show nodes with more consequences.">Root Cause</label>
                                        <input type="range" min="1" max="75" value="10" className="slider" id="myRange" onInput={this.onSlideChange}/>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-9">
                                    <div className="border" id="network_viz"></div>
                                    <div className="text-center my-2">
                                        <h2 className="d-inline" data-toggle="tooltip" title="The Risk Assessment score is calculated
                by summing all disease states by a score proportional to their proximity to death. Lower scores are healthy,
                and higher scores are more at risk of death. The average risk score among patients that have died is 3.375.">Risk Assessment Score: </h2>
                                        <h2 className="d-inline" id="risk_score">-</h2>
                                    </div>
                                </div>
                                <div className="col-3 text-center">
                                    <h4>Node Information</h4>
                                    <output id="node_information"></output>
                                </div>
                            </div>
                        </div>

                        <div id="history" className="d-none">
                            <h3>Medical History</h3>
                            <input className="form-control col-3" type="text" placeholder="Search in table..." id="searchField"/>
                            <div className="table" id="table-sortable">
                                <SmartDataTable
                                    name={this.state.table.title}
                                    orderedHeaders={this.state.table.headers}
                                    headers={this.state.table.headerMap}
                                    data={this.state.table.data}
                                    perPage={this.state.table.perPageItemCount}
                                    sortable={true}
                                />
                            </div>
                        </div>

                        <div id="cost_analysis" className="d-none">
                            <h3>Cost Analysis</h3>
                            <h5 className="ml-3">Observed Cost vs Expected Cost</h5>
                            <div id="raf_plot"></div>
                        </div>

                        <div id="naive_bayes" className="d-none">
                            <h3>Disease Prediction (Naive Bayes)</h3>
                            <div className="table" id="table-sortable-naive-bayes">
                                <SmartDataTable
                                    name={this.state.nb_table.title}
                                    orderedHeaders={this.state.nb_table.headers}
                                    headers={this.state.nb_table.headerMap}
                                    data={this.state.nb_table.data}
                                    perPage={this.state.nb_table.perPageItemCount}
                                    sortable={true}
                                />
                            </div>
                        </div>

                        <div id="mb_analysis" className="d-none">
                            <h3>Disease Prediction (Market Basket)</h3>
                            <p>Predictions are based on correlations by co-occurrence of the predicted condition with any two current conditions independently.
                                The displayed probability is computed as follows: Max [(a, b) in Current Conditions] P(Predicted Condition | a, b ).</p>
                            <div className="border" id="bar_chart"></div>
                            <input className="form-control col-3" type="text" placeholder="Search in this.table..." id="searchFieldPrediction"/>
                            <div className="table" id="table-sortable-prediction">
                                <SmartDataTable
                                    name={this.state.pred_table.title}
                                    orderedHeaders={this.state.pred_table.headers}
                                    headers={this.state.pred_table.headerMap}
                                    data={this.state.pred_table.data}
                                    perPage={this.state.pred_table.perPageItemCount}
                                    sortable={true}
                                />
                            </div>
                        </div>

                        <div id="nearest_neighbors" className="d-none">
                            <h3>Disease Prediction (Nearest Neighbors)</h3>
                            <p>Predictions are based on matching cohorts with similar profiles of riak nodes.
                                Riak nodes present in such cohort, but not present in this patient, are shown below, with percentages computed based on number of patients in the cohort.</p>
                            <div className="border" id="bar_chart_nn"></div>
                            <input className="form-control col-3" type="text" placeholder="Search in this.table..." id="searchFieldPredictionNN"/>
                            <div className="table" id="table-sortable-prediction-nn">
                                <SmartDataTable
                                    name={this.state.nn_table.title}
                                    orderedHeaders={this.state.nn_table.headers}
                                    headers={this.state.nn_table.headerMap}
                                    data={this.state.nn_table.data}
                                    perPage={this.state.nn_table.perPageItemCount}
                                    sortable={true}
                                />
                            </div>

                            <h1>Nearest Neighbors Table<a href="#top" className="btn btn-primary mx-4">Scroll to Top</a></h1>

                            <input className="form-control col-3" type="text" placeholder="Search in this.table..." id="searchFieldNearestNeighbors"/>
                            <div className="table" id="table-sortable-nearest-neighbors">
                                <SmartDataTable
                                    name={this.state.nn_table_2.title}
                                    orderedHeaders={this.state.nn_table_2.headers}
                                    headers={this.state.nn_table_2.headerMap}
                                    data={this.state.nn_table_2.data}
                                    perPage={this.state.nn_table_2.perPageItemCount}
                                    dynamic={true}
                                    sortable={true}
                                />
                            </div>
                        </div>

                    </div>
                    <div className="col-1"></div>
                </div>
            </div>
        );
    }

}